import {
  PageField,
  createPageContext,
  createPageField,
} from "../../../components/page";
import { Pagination } from "../../../hooks/user/user-hook.types";
import { Asset } from "../../assets-menu/assets/context";
import { Genre } from "../../settings/genres/context";
import { Location } from "../../settings/users/context";

export interface Reminder {
  id?: string;
  descriptionReminder?: string;
  observation?: string;
  email?: string;
  startDate?: Date;
  recurrence?: string;
  selectedAssetsIds?: Asset[];
}
export interface Data {
  reminder?: Reminder;
  assets?: Asset[];
  locationFilter?: Location[];
  genreFilter?: Genre[];
  count?: number;
  pagination?: Pagination;
  searchQueryParams?: Asset;
  allCheckedStatus?: "unchecked" | "indeterminate" | "checked";
  isLoading?: boolean;
  hasChange?: boolean;
}

export interface Values {
  descriptionReminder?: PageField;
  observation?: PageField;
  email?: PageField;
  startDate?: PageField;
  recurrence?: PageField;
  selectedAssetsIds?: PageField;
}

const { PageContextProvider, usePageContext } = createPageContext<Data, Values>(
  {
    allCheckedStatus: "unchecked",
    pagination: {
      page: 0,
      rowsPerPage: 10,
      count: 1,
    },
    searchQueryParams: {
      acquireAmount: {
        hasAcquireAmount: false,
        max: undefined,
        min: undefined,
      },
    },
  },
  {
    descriptionReminder: createPageField("", { required: true }),
    observation: createPageField("", { required: true }),
    email: createPageField("", { required: true }),
    startDate: createPageField("", { required: true }),
    recurrence: createPageField("", { required: true }),
    selectedAssetsIds: createPageField([], { required: true }),
  }
);

export { PageContextProvider, usePageContext };
