import { People } from "@mui/icons-material";
import MuiPaper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import Grid from "../../../components/grid";
import { ListItemButton } from "../../../components/list";
import Typography from "../../../components/typography";

type SettingsMenuItemProps = {
  label: string;
  icon: React.ReactNode;
  subtitle: string;
  url: string;
  hidden: boolean;
};

export default function SettingsMenuItem(props: SettingsMenuItemProps) {
  const navigate = useNavigate();

  return (
    <Grid
      item
      xs={12}
      sm={6}
      lg={4}
      sx={{
        display: props.hidden ? "none" : null,
      }}
    >
      <MuiPaper
        sx={{
          borderRadius: 2,
        }}
      >
        <ListItemButton
          sx={{
            display: "flex",
            py: 2,
            gap: 2,
            alignContent: "center",
          }}
          onClick={() => navigate(props.url)}
        >
          <Grid container spacing={2}>
            <>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", gap: 2, alignItems: "center" }}
              >
                <>
                  {props.icon} {props.label}
                </>
              </Grid>
              <Grid item xs={12}>
                <Typography component={"h2"} variant={"caption"}>
                  <>{props.subtitle}</>
                </Typography>
              </Grid>
            </>
          </Grid>
        </ListItemButton>
      </MuiPaper>
    </Grid>
  );
}
