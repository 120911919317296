import {
  InventoryOutlined as InventoryOutlinedIcon,
  PendingActions as PendingActionsIcon,
} from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import Page from "../../components/page";
import Typography from "../../components/typography";
import { useDashboard } from "../../hooks/dashboard/dashboard-hook";
import { useSession } from "../../hooks/session";
import BarChartComponent from "./charts/bar-chart/bar-chart";
import DashChartCard from "./charts/dash-card/dash-card";
import InfoCard from "./charts/info-card/info-card";
import PieChartComponent from "./charts/pie-chart/pie-chart";

function HomePage() {
  const session = useSession();
  const dashData = useDashboard();
  const [infoCardsData, setInfoCardsData] = useState({});

  useEffect(() => {
    if (session.token) getData();
  }, [session.token]);

  const getData = async () => {
    const cardsData = {};
    cardsData.assetsCount = await dashData.getInfoCardAssetCount(session);
    cardsData.assetsPendingCount = await dashData.getInfoCardPendingAssetCount(
      session
    );
    cardsData.assetByGenre = await dashData.getPieChartAssetByGenreCount(
      session
    );
    cardsData.assetsByLocation = await dashData.getBarChartAssetByLocationCount(
      session
    );
    setInfoCardsData(cardsData);
  };

  return (
    <Page showAppBar showSideBar requiresAuthentication>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item container xs={12}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              component={"h1"}
              variant="h4"
              sx={{ fontWeight: "bold", flex: 1 }}
            >
              Home
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid item>
              Bem vindo de volta,{" "}
              <Typography component={"b"} variant={"h6"}>
                {session.account.user.name}
              </Typography>
              !
            </Grid>
          </Grid>
        </Grid>

        <InfoCard
          accessId={"dashAssetsCount"}
          value={infoCardsData.assetsCount ?? 0}
          label={"Ativos cadastrados"}
          cardColor="primary"
          Icon={InventoryOutlinedIcon}
        />
        <InfoCard
          accessId={"dashAssetsPendingCount"}
          value={infoCardsData.assetsPendingCount ?? 0}
          label={"Ativos pendentes"}
          cardColor="warning"
          Icon={PendingActionsIcon}
        />
      </Grid>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item container xs={6}>
          <DashChartCard
            label="Ativos por Local"
            Chart={BarChartComponent}
            data={infoCardsData.assetsByLocation ?? []}
            accessId={"dashAssetsLocation"}
          />
        </Grid>
        <Grid item container xs={6}>
          <DashChartCard
            label="Ativos por Gênero"
            Chart={PieChartComponent}
            data={infoCardsData.assetByGenre ?? []}
            accessId={"dashAssetsGenre"}
          />
        </Grid>
      </Grid>
    </Page>
  );
}

export default HomePage;
