import { Mail as MailIcon } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import Autocomplete from "../../../../components/auto-complete/auto-complete";
import Button from "../../../../components/button/button";
import Grid from "../../../../components/grid/grid";
import TextField from "../../../../components/text-field";
import { useInvite } from "../../../../hooks/invite";
import { useLocation } from "../../../../hooks/location";
import { useRole } from "../../../../hooks/role";
import { useSession } from "../../../../hooks/session";
import { useUser } from "../../../../hooks/user";
import { usePageContext } from "../context";

function InviteUserPage() {
  const session = useSession();
  const page = usePageContext();
  const hooks = {
    role: useRole(),
    invite: useInvite(),
    local: useLocation(),
    user: useUser(),
  };

  useEffect(() => {
    if (!page.data.roles) roleList();
    if (!page.data.locations) locationList();
  }, []);

  const roleList = async () => {
    const roles = await hooks.role.fetchRoles(session);
    page.setData({
      roles: roles.map((i) => ({
        id: i.id,
        name: i.name,
      })),
    });
  };

  const locationList = async () => {
    const locals = await hooks.local.fetchLocations(session);
    page.setData({
      locations: locals.map((i) => ({
        id: i.id,
        name: i.description,
      })),
    });
  };

  const sendInvite = async () => {
    const email = page.values.email.value;
    const allUsers = await hooks.user.fetchUsers(
      {
        page: 0,
        rowsPerPage: 10,
      },
      session
    );

    const emailExist = allUsers.user.some((user) => {
      return user.email.trim().toLowerCase() === email.trim().toLowerCase();
    });

    if (emailExist)
      return page.notify("error", "E-mail já cadastrado nessa empresa");

    await hooks.invite
      .fetchInvite(
        {
          email: page.values.email.value,
          roles: page.values.roles.value,
          locations: page.values.locations.value,
        },
        session
      )
      .then((_) => page.notify("success", "Convite enviado com sucesso"))
      .catch((e) => page.notify("error", "Falha ao enviar convite: " + e));
  };

  function handleClose() {
    page.setValues({
      openDialog: { value: false },
    });
  }

  const clearForm = async () => {
    page.setValues({
      email: {
        value: "",
      },
      roles: {
        value: [],
      },
      locations: {
        value: [],
      },
    });
  };

  if (!page.data.locations && !page.data.roles) {
    return <p>Carregando...</p>;
  } else {
    return (
      <form
        onSubmit={async (e) => {
          page.setValues({
            isLoading: { value: true },
          });
          e.preventDefault();
          await sendInvite()
            .then(() => {
              clearForm();
              handleClose();
            })
            .finally(() =>
              page.setValues({
                isLoading: { value: false },
              })
            );
        }}
      >
        <Grid container spacing={2} columns={2}>
          <Grid item xs={2}>
            <TextField
              id="email"
              name="email"
              label="E-mail"
              fullWidth
              sx={{
                margin: 0,
              }}
              field={page.values.email}
              margin="normal"
              onChange={(e) => {
                page.setValues({
                  email: {
                    value: e.target.value,
                  },
                });
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              multiple
              id="role"
              getOptionLabel={(option) => option.name}
              field={page.values.roles}
              onChange={(event, val) =>
                page.setValues({
                  roles: {
                    value: val,
                  },
                })
              }
              options={page.data.roles ?? []}
              label="Perfil"
            />
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              multiple
              id="role"
              getOptionLabel={(option) => option.name}
              field={page.values.locations}
              onChange={(event, val) =>
                page.setValues({
                  locations: {
                    value: val,
                  },
                })
              }
              options={page.data.locations ?? []}
              label="Local"
            />
          </Grid>
          <Grid item xs={1}>
            <Button
              fullWidth
              variant="text"
              color="warning"
              onClick={handleClose}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Button
              type="submit"
              startIcon={
                page.values.isLoading.value ? (
                  <CircularProgress size={24} />
                ) : (
                  <MailIcon />
                )
              }
              fullWidth
              variant="contained"
              color="primary"
              disabled={page.values.isLoading.value}
            >
              {page.values.isLoading.value ? "Enviando..." : "Enviar"}
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default InviteUserPage;
