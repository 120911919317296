import { useCallback } from "react";
import { Session } from "../../hooks/session/session-hook.types";
import { useFetch } from "../fetch/fetch-hook";
import { Pagination } from "../user/user-hook.types";

const useCompany = () => {
  const { doFetch } = useFetch();

  const fetchCompanies = async (session?: Session) => {
    const data = doFetch({
      session,
      fetchConfig: {
        method: "GET",
        path: `v2/company?companyUser=true&acceptInvitation=true`,
      },
    });
    return data;
  };

  const fetchAddressData = useCallback(async (cep: string) => {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    try {
      const res = await fetch(`https://viacep.com.br/ws/${cep}/json/`, {
        method: "GET",
        headers: requestHeaders,
      });
      const data = await res.json();

      if (data.erro) return { hasError: true, message: "CEP não encontrado" };

      return data;
    } catch (error: any) {
      if (error instanceof TypeError) {
        return { hasError: true, message: "CEP inválido" };
      }
      return { hasError: true, message: error.message };
    }
  }, []);

  const inviteNewCompany = async (
    name: string,
    email: string,
    federalDocument: string,
    session?: Session
  ) => {
    const data = await doFetch({
      session,
      fetchConfig: {
        body: JSON.stringify({
          name,
          email,
          federalDocument,
        }),
        method: "POST",
        path: `v2/company/invite-new-company`,
      },
    });
    return data;
  };

  const createCompany = async (
    address: string,
    city: string,
    neighborhood: string,
    phone: string,
    state: string,
    zipCode: string,
    accountingExport: string,
    token: string
  ) => {
    const data = await doFetch({
      session: { token, isAuthenticated: false, access: [] },
      fetchConfig: {
        body: JSON.stringify({
          address,
          city,
          neighborhood,
          phone,
          state,
          zipCode,
          accountingExport,
        }),
        method: "POST",
        path: `v2/company`,
      },
    });
    return data;
  };

  const fetchCompaniesWithPaginationAndFilter = async (
    filter: string,
    pagination: Pagination,
    session?: Session
  ) => {
    const data = doFetch({
      session,
      fetchConfig: {
        method: "GET",
        pagination,
        path: `v2/companies`,
        query: `filter=${filter}`,
      },
    });
    return data;
  };

  return {
    fetchCompanies,
    fetchAddressData,
    fetchCompaniesWithPaginationAndFilter,
    inviteNewCompany,
    createCompany,
  };
};

export { useCompany };
