import { useCallback } from "react";
import { FetchTypes } from "./fetch-hook.types";
import config from "../../config";
import { useSession } from "../session";
import { useNavigate } from "react-router-dom";

const useFetch = () => {
  const { signOut } = useSession();
  const navigate = useNavigate();
  const doFetch = useCallback(async (fetchProps: FetchTypes) => {
    const { session, fetchConfig } = fetchProps;
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", `Bearer ${session?.token || ""}`);

    let path = fetchConfig?.query
      ? `${fetchConfig?.path}?${fetchConfig?.query}`
      : `${fetchConfig?.path}?`;

    if (fetchConfig?.pagination)
      path = `${path}&page=${fetchConfig.pagination.page}&rowsPerPage=${fetchConfig.pagination.rowsPerPage}`;
    const res = await fetch(`${config.api.url}/${path}`, {
      method: fetchConfig?.method,
      headers: requestHeaders,
      body: fetchConfig?.body,
    });

    if (res.status === 401) {
      return await refreshAccessToken();
    }

    //TODO res.status === 500

    const data = await res.json();
    return data;
  }, []);

  const refreshAccessToken = async () => {
    await signOut();
    navigate("/unauthorized?isTokenExpired=true");
  };

  return {
    doFetch,
  };
};

export { useFetch };
