import { CircularProgress, Divider, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "../../../components/avatar";
import Button from "../../../components/button";
import Grid from "../../../components/grid";
import Page from "../../../components/page";
import TextField from "../../../components/text-field";
import Typography from "../../../components/typography";
import { useSession } from "../../../hooks/session";
import { useUser } from "../../../hooks/user";
import { usePageContext } from "./context";

export default function ProfilePage() {
  const page = usePageContext();
  const session = useSession();
  const theme = useTheme();
  const hooks = { user: useUser() };
  const navigate = useNavigate();
  const email = session.account.user.email;

  useEffect(() => {
    (async () => await loadData())();
  }, []);

  const loadData = async () => {
    await hooks.user.fetchProfile(session).then((res) => {
      page.setValues({
        name: { value: res.user.name },
        email: { value: email },
      });
      page.setData({ user: { name: res.user.name } });
    });
  };

  if (!page.data.user) {
    return <CircularProgress />;
  }

  return (
    <Page requiresAuthentication showAppBar showSideBar context={page.context}>
      <Paper sx={{ mt: 5, marginX: "auto", maxWidth: 500, p: 3 }}>
        <Grid
          container
          spacing={3}
          columns={1}
          direction={"column"}
          textAlign="center"
        >
          <Grid item>
            <Typography component={"h1"} variant={"h4"}>
              Minha Conta
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              size={100}
              sx={{
                fontSize: 70,
                marginX: "auto",
                bgcolor: theme.palette.primary.light,
              }}
            >
              {page.data.user.name[0].toUpperCase()}
            </Avatar>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              type="button"
              onClick={() =>
                navigate(
                  `/change-password?passwordtoken=${session.token}&rbu=1`
                )
              }
            >
              Alterar minha senha
            </Button>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item container spacing={2}>
            <Grid item textAlign="left">
              <Typography variant="subtitle2" sx={{ px: "14.5px" }}>
                Nome Completo:
              </Typography>
              <TextField
                field={page.values.name}
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "transparent",
                    },
                  },
                  "&:hover": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                }}
              />
            </Grid>

            <Grid item textAlign="left">
              <Typography variant="subtitle2" sx={{ px: "14.5px" }}>
                E-mail:
              </Typography>
              <TextField
                field={page.values.email}
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "transparent",
                    },
                  },
                  "&:hover": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid item>
              <Button
                type="button"
                variant="text"
                color="info"
                onClick={() =>
                  page.values.hasChange.value
                    ? page.setValues({
                        name: { value: page.data.user.name },
                        hasChange: { value: false },
                      })
                    : navigate(-1)
                }
              >
                {page.values.hasChange.value ? "Cancelar" : "Voltar"}
              </Button>
            </Grid>
            <Grid item></Grid>
          </Grid>
        </Grid>
      </Paper>
    </Page>
  );
}
