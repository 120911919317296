import {
  createPageContext,
  createPageField,
  PageField,
} from "../../components/page";

export interface User {
  id: string;
  active: boolean;
  isAdmin: boolean;
  email: string;
  name: string;
  password: string;
}

export interface Rule {
  name: string;
  description: string;
  status: "ok" | "error" | "none";
}

export interface Data {
  users?: Array<User>;
  rules?: Rule[];
}

export interface Values {
  oldPassword?: PageField;
  password?: PageField;
  confirmPassword?: PageField;
}

const { PageContextProvider, usePageContext } = createPageContext<Data, Values>(
  {
    rules: [
      {
        name: "number",
        description: "No mínimo 1 número (0-9)",
        status: "none",
      },
      {
        name: "characters",
        description: "No mínimo 8 caracteres",
        status: "none",
      },
      {
        name: "upperLetter",
        description: "No mínimo 1 letra maiúscula (A-Z)",
        status: "none",
      },
      {
        name: "confirmPassword",
        description: "Senha e Confirmar senha estão iguais",
        status: "none",
      },
    ],
  },
  {
    password: createPageField("", { required: true }),
    confirmPassword: createPageField("", { required: true }),
    oldPassword: createPageField("", { required: true }),
  }
);

export { PageContextProvider, usePageContext };
