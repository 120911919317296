import { Session } from "../session/session-hook.types";
import { useFetch } from "../fetch/fetch-hook";

const useDashboard = () => {
  const { doFetch } = useFetch();

  const getInfoCardAssetCount = async (session?: Session) => {
    const data = await doFetch({
      session,
      fetchConfig: {
        method: "GET",
        path: `v2/dashboard/asset`,
      },
    });
    return data.count;
  };

  const getInfoCardPendingAssetCount = async (session?: Session) => {
    const data = await doFetch({
      session,
      fetchConfig: {
        method: "GET",
        path: `v2/dashboard/pending-asset`,
      },
    });
    return data.countPending;
  };

  const getPieChartAssetByGenreCount = async (session?: Session) => {
    const data = await doFetch({
      session,
      fetchConfig: {
        method: "GET",
        path: `v2/dashboard/genre`,
      },
    });
    return data;
  };

  const getBarChartAssetByLocationCount = async (session?: Session) => {
    const data = await doFetch({
      session,
      fetchConfig: {
        method: "GET",
        path: `v2/dashboard/location`,
      },
    });
    return data;
  };

  return {
    getInfoCardAssetCount,
    getInfoCardPendingAssetCount,
    getPieChartAssetByGenreCount,
    getBarChartAssetByLocationCount,
  };
};

export { useDashboard };
