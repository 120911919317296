import { Invite } from "../../pages/settings/users/context";
import { Session } from "../session/session-hook.types";
import { useFetch } from "../fetch/fetch-hook";

const useInvite = () => {
  const { doFetch } = useFetch();

  const fetchInvite = async (invite: Invite, session?: Session) => {
    const data = await doFetch({
      session,
      fetchConfig: {
        body: JSON.stringify({
          email: invite.email,
          role: invite.roles,
          location: invite.locations,
        }),
        method: "POST",
        path: `v2/company/invite-user`,
      },
    });
    return data;
  };

  const resendUserInvite = async (email: string, session?: Session) => {
    const data = await doFetch({
      session,
      fetchConfig: {
        body: JSON.stringify({
          email,
        }),
        method: "POST",
        path: `v2/company/resend-invite-user`,
      },
    });
    return data;
  };

  return {
    fetchInvite,
    resendUserInvite,
  };
};

export { useInvite };
