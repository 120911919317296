import { Grid, ListItemButton, Paper } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import List from "../../../../components/list";
import TextField from "../../../../components/text-field";

export function SearchAutocomplete({
  field,
  initialValue,
  queryKey,
  label,
  placeholder,
  context,
  onChange,
}) {
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    if (field.value !== initialValue) {
      const timeOutId = setTimeout(
        () =>
          context.setData({
            searchQueryParams: {
              ...context.data.searchQueryParams,
              [queryKey]: field.value,
            },
          }),
        500
      );
      return () => clearTimeout(timeOutId);
    }
  }, [field]);

  const selectSuggestion = useCallback((item) => {
    context.setValues({
      description: {
        value: item,
      },
    });
    setShowSuggestions(false);
  }, []);

  return (
    <Grid sx={{ position: "relative" }}>
      <TextField
        field={field}
        label={label}
        placeholder={placeholder}
        fullWidth
        onChange={(event) => {
          setShowSuggestions(true);
          onChange(event);
        }}
        autoComplete={"off"}
        onBlur={useCallback(() => setShowSuggestions(false), [])}
      />
      {showSuggestions && (
        <Paper sx={{ position: "absolute", zIndex: "1000", width: "100%" }}>
          <List>
            {context.data.suggestionsList
              .filter((item) =>
                item.toUpperCase().includes(field.value.toUpperCase())
              )
              .map((item, i) => (
                <ListItemButton
                  key={i}
                  onClick={() => {
                    selectSuggestion(item);
                  }}
                  onMouseDown={(event) => {
                    event.preventDefault();
                  }}
                >
                  {item}
                </ListItemButton>
              ))}
          </List>
        </Paper>
      )}
    </Grid>
  );
}
