import {
  ApartmentOutlined as ApartmentOutlinedIcon,
  ChevronLeft as ArrowLeftIcon,
  LockOutlined as MuiLockOutlinedIcon,
} from "@mui/icons-material";
import { CircularProgress, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect } from "react";
import Avatar from "../../components/avatar/avatar";
import Button from "../../components/button";
import Grid from "../../components/grid";
import List, {
  ListItem,
  ListItemButton,
  ListItemText,
} from "../../components/list";
import Page from "../../components/page";
import TextField from "../../components/text-field";
import Typography from "../../components/typography";
import { useCompany } from "../../hooks/company";
import { useSession } from "../../hooks/session";
import { usePageContext } from "./context";

const SignInView = () => {
  const theme = useTheme();
  const hooks = {
    session: useSession(),
    company: useCompany(),
  };
  const page = usePageContext();

  const signIn = async () => {
    page.setValues({
      isLoading: { value: true },
    });
    const generatedSession = await hooks.session
      .signIn({
        email: page.values.email.value,
        password: page.values.password.value,
      })
      .catch((e) => {
        page.notify("error", "Usuário ou senha incorretos");
      })
      .finally(() =>
        page.setValues({
          isLoading: { value: false },
        })
      );

    if (generatedSession) {
      const companies = await hooks.company.fetchCompanies(generatedSession);
      page.setData({
        view: "SelectCompany",
        companies: companies.items.map((i) => ({ id: i.id, name: i.name })),
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Avatar
        sx={{
          margin: theme.spacing(1),
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <MuiLockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Entrar
      </Typography>
      <form
        style={{
          width: "100%",
        }}
        // noValidate
        onSubmit={(e) => {
          e.preventDefault();
          signIn();
        }}
      >
        <TextField
          id="email"
          name="email"
          label="E-mail"
          type={"email"}
          fullWidth
          field={page.values.email}
          margin="normal"
          onChange={(e) => {
            page.setValues({
              email: {
                value: e.target.value,
              },
            });
          }}
        />
        <TextField
          id="password"
          name="password"
          label="Senha"
          fullWidth
          margin="normal"
          type="password"
          field={page.values.password}
          onChange={(e) => {
            page.setValues({
              password: {
                value: e.target.value,
              },
            });
          }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={page.values.isLoading.value}
          sx={{
            margin: theme.spacing(3, 0, 2),
          }}
        >
          {page.values.isLoading.value ? (
            <CircularProgress size={26} />
          ) : (
            "Entrar"
          )}
        </Button>
        <Grid container>
          <Grid item size="xs" textAlign="center">
            <a
              onClick={() => {
                page.setData({
                  view: "ForgotPassword",
                });
              }}
              href="#"
              style={{ color: theme.palette.primary.light }}
            >
              Esqueceu sua senha?
            </a>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const SelectCompanyView = () => {
  const theme = useTheme();
  const page = usePageContext();
  const hooks = {
    session: useSession(),
    company: useCompany(),
  };

  useEffect(() => {
    (async () => {
      if (!page.data.companies && hooks.session) {
        const companies = await hooks.company.fetchCompanies(hooks.session);
        page.setData({
          view: "SelectCompany",
          companies: companies.items.map((i) => ({ id: i.id, name: i.name })),
        });
      }
    })();
  }, []);

  const selectCompany = async (companyId) => {
    await hooks.session.selectCompany(companyId);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Avatar
        sx={{
          margin: theme.spacing(1),
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <ApartmentOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Selecione a empresa
      </Typography>
      <List
        sx={{
          marginTop: theme.spacing(1),
          padding: 0,
          width: "100%",
          border: "1px solid #dee2e6",
          backgroundColor: "1px solid #dee2e6",
        }}
      >
        {page.data.companies?.map((company, index) => {
          return (
            <ListItem key={`company-item-${index}`}>
              <ListItemButton
                sx={{
                  borderBottom: "1px solid #dee2e6",
                  borderCollapse: "collapse",
                }}
                onClick={() => {
                  selectCompany(company.id);
                }}
              >
                <ListItemText>{company.name}</ListItemText>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

function ForgotPasswordView() {
  const theme = useTheme();
  const hooks = {
    session: useSession(),
    company: useCompany(),
  };
  const page = usePageContext();

  const sendEmail = async () => {
    hooks.session
      .sendForgotPasswordEmail({ email: page.values.email.value })
      .then(() =>
        page.notify("success", "E-mail de redefinição de senha enviado")
      )
      .catch((err) => page.notify("error", `Algo deu errado - ${err}`));
  };

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 3,
      }}
    >
      <Avatar
        sx={{
          padding: theme.spacing(4),
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <MuiLockOutlinedIcon fontSize="large" />
      </Avatar>
      <Typography component="h1" variant="h5">
        Recuperar senha
      </Typography>
      <Typography
        component="p"
        variant="subtitle1"
        sx={{ color: theme.palette.text.secondary, textAlign: "center" }}
      >
        Insira seu e-mail, e enviaremos um link para a redefinição de senha
      </Typography>
      <form
        style={{
          width: "100%",
        }}
        // noValidate
        onSubmit={(e) => {
          e.preventDefault();
          sendEmail();
        }}
      >
        <TextField
          id="email"
          name="email"
          label="E-mail"
          type={"email"}
          fullWidth
          field={page.values.email}
          margin="normal"
          onChange={(e) => {
            page.setValues({
              email: {
                value: e.target.value,
              },
            });
          }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{
            margin: theme.spacing(3, 0, 2),
          }}
        >
          Enviar
        </Button>
        <Grid container>
          <Grid item size="xs" textAlign="center">
            <Button
              variant="text"
              startIcon={<ArrowLeftIcon />}
              onClick={() => page.setData({ view: "SignIn" })}
            >
              Voltar para o login
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}

function SignInPage() {
  const page = usePageContext();
  const session = useSession();

  const mapViewComponents = {
    SignIn: <SignInView />,
    SelectCompany: <SelectCompanyView />,
    ForgotPassword: <ForgotPasswordView />,
  };

  const currentView =
    mapViewComponents[
      session.isAuthenticated ? "SelectCompany" : page.data.view
    ];
  return (
    <Page maxWidth="xs" context={page.context}>
      <div
        style={{
          display: "flex",
          height: "100vh",
          alignItems: "center",
        }}
      >
        {currentView}
      </div>
    </Page>
  );
}

export default SignInPage;
