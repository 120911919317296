import {
  createPageContext,
  createPageField,
  PageField,
} from "../../../components/page";
import { Pagination } from "../../../hooks/user/user-hook.types";
import { User } from "../../link-user/context";

export interface Company {
  id: string;
  address: string;
  city: string;
  federalDocument: string;
  isReleased: boolean;
  name: string;
  neighborhood: string;
  phone: string;
  state: string;
  users: User[];
  activeUsersCount: number;
  zipCode: string;
  accountingExport: string;
}

export interface Invite {
  email: string;
  companyName: string;
}

export interface Data {
  invite?: Invite;
  pagination?: Pagination;
  searchQueryParams: string;
  companies?: Company[];
}

export interface Values {
  email?: PageField;
  companyName?: PageField;
  openDialog?: PageField;
  searchInput?: PageField;
  isLoading?: PageField;
  federalDocument?: PageField;
}

const { PageContextProvider, usePageContext } = createPageContext<Data, Values>(
  {
    searchQueryParams: "",
  },
  {
    email: createPageField("", { required: true }),
    openDialog: createPageField(false, { required: true }),
    searchInput: createPageField("", { required: true }),
    isLoading: createPageField(false, { required: true }),
    federalDocument: createPageField("", { required: true }),
  }
);

export { PageContextProvider, usePageContext };
