import {
  IconButton as MuiIconButton,
  Hidden as MuiHidden,
  Drawer as MuiDrawer,
  List as MuiList,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from "@mui/icons-material";
import { useApp } from "../../../../hooks/app";
import { mainListItems, SidebarMenuItemsList } from "./sidebar-menu-items";

const ShiftButton = ({ mobile }) => {
  const theme = useTheme();
  const app = useApp();
  const { isCollapsed, open, collapse } = app.sidebar;
  const shift = () => {
    if (isCollapsed) open();
    else collapse();
  };
  return (
    <MuiIconButton
      onClick={shift}
      sx={{
        position: "fixed",
        zIndex: theme.zIndex.drawer + 1,
        top: theme.components.AppBar.height + 10,
        left: !app.sidebar.isCollapsed
          ? theme.components.SideBar.widthWhenIsOpen - 12
          : parseInt(
              (theme?.components?.SideBar?.widthWhenIsCollapsed || "0")
                .toString()
                .replace("px", "")
            ) - 12,
        width: 24,
        height: 24,
        background: "#fff",
        "&:hover": {
          background: "#f1f1f1",
        },
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
        transition: theme.transitions.create("left", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        ...(!!app.sidebar.isCollapsed && {
          transition: theme.transitions.create("left", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }),
      }}
    >
      {isCollapsed ? (
        <ChevronRightIcon color="primary" />
      ) : (
        <ChevronLeftIcon color="primary" />
      )}
    </MuiIconButton>
  );
};

const Drawer = ({ mobile }) => {
  const theme = useTheme();
  const app = useApp();
  const { isCollapsed, collapse } = app.sidebar;

  return (
    <MuiDrawer
      variant={!mobile ? "permanent" : "temporary"}
      open={!isCollapsed}
      // ModalProps={{
      //   keepMounted: true,
      // }}
      onClose={collapse}
      sx={{
        "& .MuiDrawer-paper": {
          whiteSpace: "nowrap",
          paddingTop: `${theme.components.AppBar.height}px`,
          width: `${theme.components.SideBar.widthWhenIsOpen}px`,
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          boxSizing: "border-box",
          [theme.breakpoints.up("xs")]: {},
          [theme.breakpoints.up("sm")]: {
            position: "relative",
            ...(isCollapsed && {
              overflowX: "hidden",
              transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
              width: theme.spacing(7),
            }),
          },
        },
      }}
    >
      <ShiftButton />
      <MuiList component="nav">
        <SidebarMenuItemsList />
      </MuiList>
    </MuiDrawer>
  );
};

function SideBar() {
  return (
    <>
      <MuiHidden smUp>
        <Drawer mobile />
      </MuiHidden>
      <MuiHidden smDown>
        <Drawer />
      </MuiHidden>
    </>
  );
}

export default SideBar;
