import { Close as CloseIcon } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/button";
import Dialog from "../../../../components/dialog";
import List, {
  ListItemButton,
  ListItemText,
} from "../../../../components/list";
import Typography from "../../../../components/typography";
import { usePageContext } from "../context";

export function ReminderAsset() {
  const page = usePageContext();
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      open={page.data.isReminderOpen}
      xCloseButton={
        <Button
          variant="text"
          color="error"
          onClick={() => page.setData({ isReminderOpen: false })}
        >
          <CloseIcon fontSize="small" />
        </Button>
      }
    >
      {!page.data.reminder.length ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography>Sem lembretes vinculados</Typography>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography component="h1" variant="h5">
            Lembretes vinculados
          </Typography>
          <List
            sx={{
              margin: theme.spacing(1),
              padding: 0,
              width: "100%",
              border: "1px solid #dee2e6",
              backgroundColor: "1px solid #dee2e6",
            }}
          >
            {page.data.reminder?.map((reminder) => {
              return (
                <ListItemButton
                  sx={{
                    borderBottom: "1px solid #dee2e6",
                    borderCollapse: "collapse",
                  }}
                  onClick={() =>
                    navigate(`../../recurring-reminder/form?id=${reminder.id}`)
                  }
                >
                  <ListItemText>{reminder.descriptionReminder}</ListItemText>
                </ListItemButton>
              );
            })}
          </List>
        </div>
      )}
    </Dialog>
  );
}
