import {
  Calculate as CalculateIcon,
  InsertInvitationOutlined as InsertInvitationOutlinedIcon,
  InventoryOutlined as InventoryIcon,
  IosShareOutlined as ShareIcon,
} from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import TableViewIcon from "@mui/icons-material/TableView";
import { useSession } from "../../../../hooks/session";
import SidebarMenuItem from "../sidebar-menu-item";

export const SidebarMenuItemsList = () => {
  const session = useSession();
  return (
    <>
      <SidebarMenuItem
        href="/"
        color="primary"
        label="Home"
        Icon={HomeIcon}
        hasAccess={true}
      />
      <SidebarMenuItem
        href="/assets"
        color="primary"
        label="Ativos"
        Icon={TableViewIcon}
        hasAccess={session.hasAccess({
          key: "menuAssets",
          accessIds: session.access,
        })}
      />
      <SidebarMenuItem
        href="/inventory"
        color="primary"
        label="Inventário"
        Icon={InventoryIcon}
        hasAccess={session.hasAccess({
          key: "menuInventory",
          accessIds: session.access,
        })}
      />
      <SidebarMenuItem
        href="/depreciation"
        color="primary"
        label="Depreciação"
        Icon={CalculateIcon}
        hasAccess={session.hasAccess({
          key: "menuCalculation",
          accessIds: session.access,
        })}
      />
      <SidebarMenuItem
        href="/recurring-reminder"
        color="primary"
        label="Lembrete Recorrente"
        Icon={InsertInvitationOutlinedIcon}
        hasAccess={session.hasAccess({
          key: "menuAssets",
          accessIds: session.access,
        })}
      />
      <SidebarMenuItem
        href="/accounting-export"
        color="primary"
        label="Exportação Contábil"
        Icon={ShareIcon}
        hasAccess={session.hasAccess({
          key: "menuCalculation",
          accessIds: session.access,
        })}
      />
      <SidebarMenuItem
        href="/settings"
        color="primary"
        label="Configurações"
        Icon={SettingsIcon}
        hasAccess={session.hasAccess({
          key: "menuConfig",
          accessIds: session.access,
        })}
      />
    </>
  );
};
