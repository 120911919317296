import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { DataGridProps } from "./datagrid.types";

import { v4 as uuid } from "uuid";

export default function DataGrid(props: DataGridProps) {
  const theme = useTheme();

  return (
    <Paper>
      {props.tableAppBar ?? null}
      <TableContainer>
        {props.rows.length === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 32,
            }}
          >
            <p>{props.noRowsMessage || "Nenhum resultado encontrado"}</p>
          </div>
        ) : (
          <Table sx={{ minWidth: 650 }} size="medium">
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: theme.palette.primary.light,
                }}
              >
                {props.columns.map((header) => (
                  <TableCell
                    key={uuid()}
                    sx={{
                      borderRight: "1px solid #ddd",
                      color: theme.palette.grey[100],
                      textAlign: header.align,
                      width: header.columnSize,
                      fontSize: "14px",
                      padding: 1,
                    }}
                  >
                    {header.header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.rows.map((row) => (
                <TableRow key={uuid()}>
                  {row.cells.map((cell, index) => (
                    <TableCell
                      key={uuid()}
                      sx={{
                        textAlign: props.columns[index].align,
                        color: theme.palette.text.secondary,
                        fontSize: "12px",
                        padding: 1,
                      }}
                    >
                      {cell}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {props.paginationProps && (
        <TablePagination
          onPageChange={props.handlePageChange}
          onRowsPerPageChange={props.handleChangeRowsPerPage}
          component="div"
          labelRowsPerPage={"Linhas por página"}
          rowsPerPageOptions={[10, 25, 50]}
          page={props.paginationProps.page}
          count={props.paginationProps.count}
          rowsPerPage={props.paginationProps?.rowsPerPage}
        />
      )}
    </Paper>
  );
}
