import {
  Add as AddIcon,
  Clear as ClearIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import {
  CircularProgress,
  IconButton as MuiIconButton,
  Tooltip as MuiTooltip,
} from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button";
import Datagrid from "../../components/datagrid";
import Dialog from "../../components/dialog";
import Grid from "../../components/grid";
import Page from "../../components/page";
import Typography from "../../components/typography";
import { useReminder } from "../../hooks/recurring-reminder";
import { useSession } from "../../hooks/session";
import { usePageContext } from "./context";

export default function RecurringReminderPage() {
  const page = usePageContext();
  const navigate = useNavigate();
  const hooks = { reminders: useReminder() };
  const session = useSession();

  useEffect(() => {
    if (!page.data.reminders || page.data.pagination)
      (async () => await getReminders())();
  }, [page.data.pagination]);

  const getReminders = async () => {
    const { reminders, count } = await hooks.reminders.fetchReminder(
      session,
      page.data.pagination
    );
    page.setData({ count, reminders });
  };

  const newItem = async () => {
    navigate(`form`);
  };

  const deleteReminder = async (reminder) => {
    const res = await hooks.reminders.deleteReminder(reminder.id, session);
    if (res.success) {
      const index = page.data.reminders.findIndex(
        (reminderList) => reminderList.id === reminder.id
      );
      page.data.reminders.splice(index, 1);
      page.notify("success", "Lembrete excluído com sucesso");
    } else {
      page.notify("error", "Não foi possível excluir o lembrete");
    }

    page.setData({
      reminders: [...page.data.reminders],
    });
  };

  const rows = (reminders) => {
    return reminders.map((reminder) => {
      return {
        description: reminder?.description,
        actions: (
          <>
            {/* <MuiTooltip title="Exportar lembrete" sx={{ mr: 0.5 }}>
              <span style={{ marginRight: 16 }}>
                <MuiIconButton
                  onClick={async () => {
                    await downloadExcel(reminder.id);
                  }}
                  color="primary"
                >
                  <DownloadIcon />
                </MuiIconButton>
              </span>
            </MuiTooltip> */}
            <MuiTooltip title="Editar lembrete" sx={{ mr: 0.5 }}>
              <span style={{ marginRight: 16 }}>
                <MuiIconButton
                  onClick={() => navigate(`./form?id=${reminder.id}`)}
                  color="primary"
                >
                  <EditIcon />
                </MuiIconButton>
              </span>
            </MuiTooltip>
            <MuiTooltip title="Excluir lembrete" sx={{ mr: 0.5 }}>
              <span style={{ marginRight: 16 }}>
                <MuiIconButton
                  color="warning"
                  onClick={() =>
                    page.setData({ dialog: { isOpen: true, reminder } })
                  }
                >
                  <ClearIcon />
                </MuiIconButton>
              </span>
            </MuiTooltip>
          </>
        ),
      };
    });
  };

  const columns = [
    {
      header: "Descrição",
      align: "left",
    },
    {
      header: "Ações",
      align: "center",
    },
  ];

  const handlePageChange = async (_, newPage) => {
    page.setData({
      pagination: {
        ...page.data.pagination,
        page: newPage,
      },
    });
  };

  // const downloadExcel = async (reminderId) => {
  //   const { reminder } = await hooks.reminders.fetchReminderExport(
  //     session,
  //     reminderId
  //   );
  // };

  const handleChangeRowsPerPage = async (event) => {
    page.setData({
      pagination: {
        page: 0,
        rowsPerPage: event.target.value,
      },
    });
  };

  return (
    <Page
      context={page.context}
      title="Lembrete Recorrente"
      keyAccess="getAsset"
      requiresAuthentication
      showAppBar
      showSideBar
      breadcrumbs={[
        { description: "Home", link: "/" },
        { description: "Lembrete Recorrente", link: "/recurring-reminder" },
      ]}
      toolbar={[
        <Button
          startIcon={<AddIcon />}
          type="button"
          variant="contained"
          color="primary"
          onClick={async () => {
            await newItem();
          }}
        >
          Novo
        </Button>,
        <Dialog
          disableEscapeKeyDown={false}
          open={page.data.dialog.isOpen}
          title="Confirmar exclusão?"
          maxWidth="sm"
          fullWidth={true}
          actions={
            <Grid
              container
              sx={{ justifyContent: "space-between", px: 1, pb: 1 }}
            >
              <Grid item>
                <Button
                  type="button"
                  variant="text"
                  color="warning"
                  onClick={() => {
                    page.setData({
                      dialog: { isOpen: false },
                    });
                  }}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="button"
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={async () => {
                    await deleteReminder(page.data.dialog.reminder);
                    page.setData({
                      dialog: { isOpen: false, reminder: null },
                    });
                  }}
                >
                  Excluir
                </Button>
              </Grid>
            </Grid>
          }
        >
          <Grid container direction={"column"} spacing={3}>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12}>
                <Typography>Não é possível desfazer essa ação.</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Dialog>,
      ]}
    >
      {!page.data.reminders ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Datagrid
          dataLenght={page.data.count}
          rows={rows(page.data.reminders).map((row) => ({
            cells: Object.values(row),
          }))}
          columns={columns}
          paginationProps={{
            ...page.data.pagination,
            count: page.data.count,
          }}
          handlePageChange={async (event, page) => {
            await handlePageChange(event, page);
          }}
          handleChangeRowsPerPage={async (event) => {
            await handleChangeRowsPerPage(event);
          }}
        ></Datagrid>
      )}
    </Page>
  );
}
