import {
  createPageContext,
  createPageField,
  PageField,
} from "../../../components/page";
import { Rule } from "../../change-password/context";
import { Location } from "../../settings/location/list/context";

import CreateCompanyStep from "./steps/company-info";
import InviteUsersStep from "./steps/invite-users-info";
import LocationsStep from "./steps/locals-info";
import CreateUserStep from "./steps/user-info";

export interface User {
  id: string;
  active: boolean;
  isAdmin: boolean;
  email: string;
  name: string;
  password: string;
}

interface Stepper {
  activeStep: number;
  skipped?: Set<number>;
}

interface Steps {
  label: string;
  step: React.ReactNode;
}

export interface Email {
  to: string;
  status: "pending" | "error" | "success";
}

export interface Data {
  users?: User[];
  isLoading?: boolean;
  steps?: Steps[];
  stepper?: Stepper;
  view?: string;
  rules?: Rule[];
  emailList?: Email[];
  locations?: Location[];
}

export interface Values {
  name?: PageField;
  password?: PageField;
  confirmPassword?: PageField;
  companyName?: PageField;
  addressNumber?: PageField;
  addressStreet?: PageField;
  city?: PageField;
  federal_document?: PageField;
  neighborhood?: PageField;
  phone?: PageField;
  state?: PageField;
  zip_code?: PageField;
  accounting_export?: PageField;
}

const { PageContextProvider, usePageContext } = createPageContext<Data, Values>(
  {
    isLoading: false,
    view: "CreateCompany",
    emailList: [
      { to: "", status: "pending" },
      { to: "", status: "pending" },
      { to: "", status: "pending" },
    ],
    rules: [
      {
        name: "number",
        description: "No mínimo 1 número (0-9)",
        status: "none",
      },
      {
        name: "characters",
        description: "No mínimo 8 caracteres",
        status: "none",
      },
      {
        name: "upperLetter",
        description: "No mínimo 1 letra maiúscula (A-Z)",
        status: "none",
      },
      {
        name: "confirmPassword",
        description: "Senha e Confirmar senha estão iguais",
        status: "none",
      },
    ],
    stepper: {
      activeStep: 0,
      skipped: new Set<number>(),
    },
    steps: [
      {
        label: "Finalize seu cadastro",
        step: <CreateUserStep />,
      },
      {
        label: "Dados de sua empresa",
        step: <CreateCompanyStep />,
      },
      {
        label: "Convidar usuários",
        step: <InviteUsersStep />,
      },
      {
        label: "Locais e departamentos",
        step: <LocationsStep />,
      },
      // {
      //   label: "Perfis de acesso",
      //   step: <RolesStep />,
      // },
    ],
  },
  {
    name: createPageField("", { required: true }),
    password: createPageField("", { required: true }),
    confirmPassword: createPageField("", { required: true }),
    addressStreet: createPageField("", { required: true }),
    addressNumber: createPageField("", { required: true }),
    city: createPageField("", { required: true }),
    companyName: createPageField("", { required: true }),
    federal_document: createPageField("", { required: true }),
    neighborhood: createPageField("", { required: true }),
    phone: createPageField("", { required: true }),
    state: createPageField("", { required: true }),
    zip_code: createPageField("", { required: true }),
    accounting_export: createPageField(undefined, { required: true }),
  }
);

export { PageContextProvider, usePageContext };
