import {
  Category as CategoryIcon,
  DescriptionOutlined as DescriptionIcon,
  PinDrop as PinDropIcon,
} from "@mui/icons-material";
import { Checkbox, CircularProgress, Paper } from "@mui/material";
import moment from "moment";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AutoComplete from "../../../components/auto-complete";
import Button from "../../../components/button";
import Datagrid from "../../../components/datagrid";
import Grid from "../../../components/grid";
import Page from "../../../components/page";
import TextField from "../../../components/text-field";
import Typography from "../../../components/typography";
import { useAsset } from "../../../hooks/asset";
import { useLocation } from "../../../hooks/location";
import { useReminder } from "../../../hooks/recurring-reminder";
import { useSession } from "../../../hooks/session";
import { ClearFiltersButton } from "../../assets-menu/assets/components/filters-menu";
import {
  SearchField,
  SelectSearchField,
} from "../../assets-menu/assets/components/search-field";
import { usePageContext } from "./context";

const recurrenceList = [
  { name: "Semanal", value: "weekly" },
  { name: "Mensal", value: "monthly" },
  { name: "Trimestral", value: "quarterly" },
  { name: "Anual", value: "yearly" },
];

export default function RecurringReminderFormPage() {
  const page = usePageContext();
  const hooks = {
    assets: useAsset(),
    location: useLocation(),
    reminder: useReminder(),
  };
  const session = useSession();
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useSearchParams();
  const title = queryParams.get("id") ? "Editar Lembrete" : "Novo Lembrete";

  useEffect(() => {
    clearForm();
    if (!page.data.locationFilter) (async () => await getLocation())();
    if (!page.data.genreFilter) (async () => await getGenres())();
    if (!page.data.assets || page.data.pagination)
      (async () => await getAssets())();
    if (queryParams.get("id"))
      (async () => await getFieldsData(queryParams.get("id")))();
  }, [page.data.pagination, page.data.searchQueryParams]);

  useEffect(() => {
    const pageValues = Object.values(page.values);
    page.setData({
      hasChange: pageValues.some((pageField) => {
        if (Array.isArray(pageField.value)) return pageField.value.length > 0;

        return pageField.value !== "" && pageField.value !== null;
      }),
    });
  }, [page.values]);

  const getFieldsData = async (id) => {
    const reminder = await hooks.reminder.fetchSingleReminder(session, {
      id,
    });

    page.setData({ reminder: { ...reminder } });

    page.setValues({
      descriptionReminder: { value: reminder.description },
      observation: { value: reminder.observation },
      email: { value: reminder.email },
      startDate: { value: moment(reminder.startDate).format("yyyy-MM-DD") },
      recurrence: {
        value:
          recurrenceList.find(
            (recurrence) => recurrence.value === reminder.recurrence
          ) || "",
      },
      selectedAssetsIds: { value: reminder.assetReminderList },
    });
  };

  const getAssets = async () => {
    const { count, asset: assets } = await hooks.assets.fetchAssets(
      session,
      page.data.pagination,
      page.data.searchQueryParams
    );
    page.setData({
      count,
      assets,
    });
  };

  const getLocation = async () => {
    const allLocation = await hooks.location.fetchLocations(session);
    const locationList = allLocation.sort((a, b) => {
      return a > b ? 1 : b > a ? -1 : 0;
    });
    page.setData({
      locationFilter: locationList.map((item) => ({
        description: item.description,
      })),
    });
  };

  const getGenres = async () => {
    const allGenres = await hooks.assets.fetchGenresAccount(session);
    const genreList = allGenres.sort((a, b) => {
      return a > b ? 1 : b > a ? -1 : 0;
    });
    page.setData({
      genreFilter: genreList.map((item) => ({
        description: item.description,
      })),
    });
  };

  const handlePageChange = async (_, newPage) => {
    page.setData({
      pagination: {
        ...page.data.pagination,
        page: newPage,
      },
    });
  };

  const handleChangeRowsPerPage = async (event) => {
    page.setData({
      pagination: {
        page: 0,
        rowsPerPage: event.target.value,
      },
    });
  };

  const getParentCheckBoxStatus = (checkedAssets) => {
    const allAssetsIds = page.data.assets.map((asset) => asset.id);
    if (!allAssetsIds?.length) {
      return;
    }
    if (allAssetsIds.length === checkedAssets.length) return "checked";

    if (
      checkedAssets.length > 0 &&
      checkedAssets.length !== allAssetsIds.length
    )
      return "indeterminate";

    return "unchecked";
  };

  const handleChangeCheckbox = (id, isChecking) => {
    const ids = page.values.selectedAssetsIds.value;
    if (isChecking) {
      ids.push(id);
    } else {
      const idToRemove = ids.indexOf(id);
      ids.splice(idToRemove, 1);
    }

    const parentCheckBoxStatus = getParentCheckBoxStatus(ids);

    page.setValues({
      selectedAssetsIds: ids,
      allCheckedStatus: parentCheckBoxStatus,
    });
  };

  const handleCheckAll = () => {
    const allAssetsIds = page.data.assets.map((asset) => {
      if (asset.id) {
        return asset.id;
      }
      return [];
    });
    const checkedAssets = page.values.selectedAssetsIds.value;
    const parentCheckBoxStatus = getParentCheckBoxStatus(checkedAssets);

    if (!allAssetsIds?.length) {
      return;
    }
    if (parentCheckBoxStatus === "checked") {
      return {
        allCheckedStatus: "unchecked",
        selectedAssetsIds: [],
      };
    }
    if (parentCheckBoxStatus === "unchecked") {
      return {
        allCheckedStatus: "checked",
        selectedAssetsIds: { value: allAssetsIds },
      };
    }
  };

  const rows = (assets) => {
    return assets.map((asset) => {
      const isChecked = page.values.selectedAssetsIds?.value?.includes(
        asset.id
      );

      return {
        checkbox: (
          <Checkbox
            size="small"
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
              color: "gray",
            }}
            checked={isChecked}
            onChange={(event) =>
              handleChangeCheckbox(asset.id, event.target.checked)
            }
          />
        ),
        number: asset.number,
        description: asset.description,
        genre: asset.genre,
        local: asset.location_description,
      };
    });
  };

  const columns = [
    {
      header: (
        <Checkbox
          sx={{
            color: "white",
            "&.Mui-checked": {
              color: "white",
            },
          }}
          checked={page.data.allCheckedStatus === "checked"}
          indeterminate={page.data.allCheckedStatus === "indeterminate"}
          onChange={(event) => {
            page.setValues(handleCheckAll());
          }}
        />
      ),
      columnSize: 50,
    },
    {
      header: "Patrimônio",
      align: "center",
    },
    {
      header: "Descrição",
      align: "left",
    },
    {
      header: "Gênero",
      align: "left",
    },
    {
      header: "Local",
      align: "left",
    },
  ];

  const saveNewReminder = async () => {
    page.setData({
      isLoading: true,
    });
    try {
      const response = await hooks.reminder.createReminder(session, {
        descriptionReminder: page.values.descriptionReminder.value,
        observation: page.values.observation.value,
        email: page.values.email.value,
        startDate: page.values.startDate.value,
        recurrence: page.values.recurrence.value,
        selectedAssetsIds: page.values.selectedAssetsIds.value,
      });
      if (response.errors)
        return page.notify("error", response.errors[0].message);

      return page.notify("success", "Lembrete criado com sucesso!");
    } catch (error) {
      page.notify("error", `${error}`);
    } finally {
      page.setData({
        isLoading: false,
      });
    }
  };

  const saveEditReminder = async () => {
    page.setData({
      isLoading: true,
    });
    try {
      const response = await hooks.reminder.updateReminder(
        session,
        { id: queryParams.get("id") },
        {
          descriptionReminder: page.values.descriptionReminder.value,
          observation: page.values.observation.value,
          email: page.values.email.value,
          startDate: page.values.startDate.value,
          recurrence: page.values.recurrence.value.value,
          selectedAssetsIds: page.values.selectedAssetsIds.value,
        }
      );
      if (response.errors)
        return page.notify("error", response.errors[0].message);

      return page.notify("success", "Lembrete atualizado com sucesso!");
    } catch (error) {
      page.notify("error", `${error}`);
    } finally {
      page.setData({
        isLoading: false,
      });
    }
  };

  const clearForm = async () => {
    page.setValues({
      descriptionReminder: { value: "" },
      observation: { value: "" },
      email: { value: "" },
      startDate: { value: "" },
      recurrence: { value: null },
      selectedAssetsIds: { value: [] },
    });
    page.setData({
      reminder: null,
    });
  };

  return (
    <Page
      context={page.context}
      showAppBar
      showSideBar
      keyAccess="getAsset"
      requiresAuthentication
      title={title}
      breadcrumbs={[
        { description: "Home", link: "/" },
        { description: "Lembrete Recorrente", link: "." },
        { description: title, link: "./form" },
      ]}
    >
      <form
        onSubmit={async (e) => {
          e.preventDefault();

          if (!page.data.reminder) {
            await saveNewReminder();
            return;
          }

          if (queryParams.get("id")) return saveEditReminder();
        }}
      >
        <Paper
          sx={{
            margin: "auto",
            maxWidth: 900,
            p: 3,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography component={"h2"} variant={"h5"}>
                Lembrete recorrente
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Descrição da ação"
                field={page.values.descriptionReminder}
                onChange={(event) =>
                  page.setValues({
                    descriptionReminder: {
                      value: event.target.value,
                    },
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                fullWidth
                label="Observação"
                rows={2}
                field={page.values.observation}
                onChange={(event) =>
                  page.setValues({
                    observation: {
                      value: event.target.value,
                    },
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="email"
                label="E-mail para envio do lembrete"
                field={page.values.email}
                onChange={(event) =>
                  page.setValues({
                    email: {
                      value: event.target.value,
                    },
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Data inicial"
                type={"date"}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                field={page.values.startDate}
                onChange={(event) => {
                  page.setValues({
                    startDate: {
                      value: event.target.value,
                    },
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AutoComplete
                fullWidth
                id="recurrence"
                label="Recorrência"
                field={page.values.recurrence}
                options={recurrenceList ?? []}
                getOptionLabel={(option) => {
                  return option.name || "";
                }}
                onChange={(_, val) => {
                  page.setValues({
                    recurrence: {
                      value: val ?? [],
                    },
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                component={"h2"}
                variant={"h5"}
                sx={{ marginBottom: 2 }}
              >
                Selecione os ativos
              </Typography>
            </Grid>
          </Grid>
          {!page.data.assets ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Datagrid
              tableAppBar={
                <div style={{ padding: 16, display: "flex", gap: 16 }}>
                  <SearchField
                    iconPosition={"start"}
                    initialValue={page.data.searchQueryParams.description}
                    queryKey={"description"}
                    Icon={<DescriptionIcon />}
                    label={"Descrição"}
                    placeholder={"Ex: Monitor, computador..."}
                    context={page}
                  />
                  <SelectSearchField
                    iconPosition={"start"}
                    initialValue={page.data.searchQueryParams.location}
                    queryKey={"location"}
                    Icon={<PinDropIcon />}
                    label={"Local"}
                    placeholder={"Local"}
                    context={page}
                    optionsList={page.data.locationFilter ?? []}
                  />
                  <SelectSearchField
                    iconPosition={"start"}
                    initialValue={page.data.searchQueryParams.genre}
                    queryKey={"genre"}
                    Icon={<CategoryIcon />}
                    label={"Gênero"}
                    placeholder={"Gênero"}
                    context={page}
                    optionsList={page.data.genreFilter ?? []}
                  />
                  <ClearFiltersButton page={page} />
                </div>
              }
              dataLenght={page.data.count}
              rows={rows(page.data.assets).map((row) => ({
                cells: Object.values(row),
              }))}
              columns={columns}
              paginationProps={{
                ...page.data.pagination,
                count: page.data.count,
              }}
              handlePageChange={async (event, page) => {
                await handlePageChange(event, page);
              }}
              handleChangeRowsPerPage={async (event) => {
                await handleChangeRowsPerPage(event);
              }}
            />
          )}
          <Grid
            item
            xs={12}
            container
            spacing={2}
            sx={{ justifyContent: "right", marginTop: 2 }}
          >
            <Grid item>
              <Button
                color="warning"
                variant="text"
                onClick={() => {
                  if (page.data.hasChange) {
                    clearForm();
                    setQueryParams("");
                    return;
                  }
                  navigate("/recurring-reminder");
                }}
              >
                {page.data.hasChange ? "Limpar" : "Cancelar"}
              </Button>
            </Grid>
            <Grid item>
              <Button type="submit" disabled={page.data.isLoading}>
                {page.data.isLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  "Salvar"
                )}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </Page>
  );
}
