import { useContext } from "react";
import MuiSnackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const SnackBar = (props) => {
  const [
    _data,
    _setData,
    _values,
    _setValues,
    _notification,
    _setNotification,
  ] = useContext(props.context);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    _setNotification({
      open: false,
      message: "",
    });
  };

  return (
    <>
      <MuiSnackbar
        open={_notification.open}
        autoHideDuration={3000}
        onClose={() => {
          handleClose();
          _notification.whenSnackCloses && _notification.whenSnackCloses();
        }}
        // action={() => {}}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <MuiAlert
          onClose={() => {
            handleClose();
          }}
          severity={_notification.type}
          variant={"filled"}
        >
          {_notification.message}
        </MuiAlert>
      </MuiSnackbar>
    </>
  );
};

export default SnackBar;
