import {
  Add as AddIcon,
  ExpandMore,
  Publish as PublishIcon,
} from "@mui/icons-material";
import {
  ListItemIcon as MuiListItemIcon,
  ListItemText as MuiListItemText,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/button";
import { useSession } from "../../../../hooks/session";
import { usePageContext } from "../context";

const CreateAssetMenu = ({ anchorEl, handleClose }) => {
  const open = Boolean(anchorEl);
  const menuId = open ? "simple-menu" : undefined;
  const navigate = useNavigate();
  const page = usePageContext();

  return (
    <MuiMenu
      id={menuId}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MuiMenuItem onClick={() => navigate("form")}>
        <MuiListItemIcon>
          <AddIcon fontSize="small" />
        </MuiListItemIcon>
        <MuiListItemText>Criar novo item</MuiListItemText>
      </MuiMenuItem>
      <MuiMenuItem
        onClick={() => {
          handleClose();
          page.setData({
            isModalOpen: true,
          });
        }}
      >
        <MuiListItemIcon>
          <PublishIcon fontSize="small" />
        </MuiListItemIcon>
        <MuiListItemText>Importar dados</MuiListItemText>
      </MuiMenuItem>
    </MuiMenu>
  );
};

export default function CreateAssetButton() {
  const [anchorEl, setAnchorEl] = useState(null);
  const session = useSession();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Button
        endIcon={<ExpandMore />}
        onClick={handleClick}
        disabled={
          !session.hasAccess({
            key: "postAsset",
            accessIds: session.access,
          })
        }
      >
        Novo Ativo
      </Button>
      <CreateAssetMenu
        anchorEl={anchorEl}
        handleClose={() => setAnchorEl(null)}
      />
    </>
  );
}
