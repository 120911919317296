import {
  DomainAddRounded as DomainAddRoundedIcon,
  Search as SearchIcon,
  Group as UsersIcon,
} from "@mui/icons-material";
import { CircularProgress, InputAdornment, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import Button from "../../../components/button/button";
import Datagrid from "../../../components/datagrid";
import Dialog from "../../../components/dialog";
import Grid from "../../../components/grid/grid";
import Page from "../../../components/page";
import TextField from "../../../components/text-field";
import Typography from "../../../components/typography";
import { useCompany } from "../../../hooks/company";
import { useInvite } from "../../../hooks/invite";
import { useSession } from "../../../hooks/session";
import InviteCompanyModal from "./components/invite-company";
import { usePageContext } from "./context";

const SearchField = ({
  initialValue,
  Icon,
  placeholder,
  iconPosition,
  size,
}) => {
  const [searchValue, setSearchValue] = useState(initialValue);
  const page = usePageContext();
  useEffect(() => {
    if (searchValue !== initialValue) {
      const timeOutId = setTimeout(
        () =>
          page.setData({
            searchQueryParams: searchValue,
            pagination: {
              page: 0,
              rowsPerPage: 10,
            },
          }),
        500
      );
      return () => clearTimeout(timeOutId);
    }
  }, [searchValue]);

  const doSearchOnChange = async (event) => {
    setSearchValue(event.target.value);
  };
  return (
    <TextField
      variant="standard"
      placeholder={placeholder}
      fullWidth
      size={size}
      field={{ value: searchValue }}
      onChange={doSearchOnChange}
      type="search"
      InputProps={{
        [`${iconPosition}Adornment`]: (
          <InputAdornment position={iconPosition}>{Icon}</InputAdornment>
        ),
      }}
    />
  );
};

function CompaniesPage() {
  const session = useSession();
  const page = usePageContext();
  const hooks = { companies: useCompany(), invite: useInvite() };

  const theme = useTheme();

  const createRows = (props) => {
    return props.map((company) => {
      let statusColumn = (
        <div
          style={{
            padding: 8,
            backgroundColor: "lightgreen",
            borderRadius: 8,
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Ativo
        </div>
      );

      if (company.isReleased === false) {
        statusColumn = (
          <div
            style={{
              padding: 8,
              backgroundColor: "red",
              borderRadius: 8,
              fontWeight: "bold",
              textAlign: "center",
              color: "whitesmoke",
            }}
          >
            Inativo
          </div>
        );
      }

      return {
        name: company.name === "" ? "Nome Empresa" : company.name,
        federal_document: company.federalDocument,
        city: company.city,
        phone: company.phone,
        status: statusColumn,
        activeUsersCount: (
          <Tooltip title="Usuários ativos">
            <div>
              <Grid container sx={{ justifyContent: "center" }} spacing={2}>
                <Grid item>
                  <UsersIcon sx={{ color: theme.palette.primary.light }} />
                </Grid>
                <Grid item>{company.activeUsersCount}</Grid>
              </Grid>
            </div>
          </Tooltip>
        ),
      };
    });
  };

  const companiesList = async () => {
    const { items: companies } = await hooks.companies.fetchCompanies(
      // {
      //   page: !page.values.pagination.value
      //     ? 0
      //     : page.values.pagination.value.page,
      //   rowsPerPage: !page.values.pagination.value
      //     ? 5
      //     : page.values.pagination.value.rowsPerPage,
      // },
      session
    );

    page.setData({
      companies: companies.map((company) => {
        const activeUsersCount = company.users.filter(
          (user) => user.active === true
        ).length;

        return {
          ...company,
          activeUsersCount,
        };
      }),
    });
  };

  const handlePageChange = async (event, newPage) => {
    if (page.values.pagination.value)
      page.setValues({
        pagination: {
          value: {
            page: newPage,
            rowsPerPage: page.values.pagination.value.rowsPerPage,
            count: page.values.pagination.value.count,
          },
        },
      });

    await companiesList();
  };

  const handleChangeRowsPerPage = async (event) => {
    if (
      page.values.pagination.value?.rowsPerPage !==
      page.values.pagination.value.count
    ) {
      page.setValues({
        pagination: {
          value: {
            page: 0,
            rowsPerPage: event.target.value,
            count: page.values.pagination.value.count,
          },
        },
      });
    }

    await companiesList();
  };

  useEffect(() => {
    const load = async () => {
      if (!page.data.companies || page.values.openDialog.value === false)
        await companiesList();
    };
    load();
  }, [page.values.openDialog]);

  return (
    <Page
      title="Empresas"
      context={page.context}
      showAppBar
      showSideBar
      requiresAuthentication
      keyAccess="getCompanies"
      breadcrumbs={[
        { description: "Home", link: "/" },
        { description: "Configurações", link: "/settings" },
        { description: "Empresas", link: "/settings/companies" },
      ]}
      toolbar={[
        <Tooltip title="Convide um novo usuário por e-mail">
          <div>
            <Button
              startIcon={<DomainAddRoundedIcon />}
              type="button"
              variant="contained"
              color="primary"
              onClick={() =>
                page.setValues({
                  openDialog: { value: true },
                })
              }
            >
              Nova empresa
            </Button>
          </div>
        </Tooltip>,
        <Dialog
          open={page.values.openDialog.value}
          title="Nova empresa"
          fullWidth
          maxWidth={"sm"}
        >
          <div style={{ paddingTop: 8 }}>
            <InviteCompanyModal />
          </div>
        </Dialog>,
      ]}
    >
      {!page.data.companies ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Grid container columns={1} spacing={2} sx={{ pb: 4 }}>
          <Grid item xs={1}>
            <Typography component={"p"} variant={"body1"}>
              Gerencie suas empresas e informações. Para cadastrar nova empresa,
              envie um convite por e-mail clicando no botão "Convidar", depois é
              só preencher o e-mail e suas informações empresariais.
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Datagrid
              tableAppBar={
                <div style={{ padding: 16, display: "flex", gap: 16 }}>
                  <TextField
                    sx={{ flex: 1 }}
                    variant="standard"
                    field={page.values.searchInput}
                    onChange={(event) => {
                      page.setValues({
                        searchInput: { value: event.target.value },
                      });
                      let result = page.data.companies.filter((user) =>
                        user.name
                          .toUpperCase()
                          .includes(page.values.searchInput.value.toUpperCase())
                      );
                      page.setData({
                        filteredUsers: result,
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      placeholder: "Razão Social, CNPJ, ADMIN...",
                    }}
                  />
                </div>
              }
              columns={[
                { header: "Nome" },
                { header: "Cidade", align: "left" },
                { header: "CNPJ", align: "left" },
                { header: "Telefone", align: "left" },
                { header: "Status" },
                { header: "Usuários", align: "center" },
              ]}
              rows={createRows(page.data.companies).map((row) => ({
                cells: Object.values(row),
              }))}
            />
          </Grid>
        </Grid>
      )}
    </Page>
  );
}

export default CompaniesPage;
