import {
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
} from "@mui/material";
import { MenuItemProps } from "./menu-item.types";

function MenuItem(props: MenuItemProps) {
  const muiMenuItemProps: MuiMenuItemProps = {
    autoFocus: false,
    dense: false,
    disabled: false,
    divider: false,
    selected: false,
  };

  if (props.autoFocus) muiMenuItemProps.autoFocus = props.autoFocus;

  if (props.dense) muiMenuItemProps.dense = props.dense;

  if (props.disabled) muiMenuItemProps.disabled = props.disabled;

  if (props.divider) muiMenuItemProps.divider = props.divider;

  if (props.selected) muiMenuItemProps.selected = props.selected;

  return <MuiMenuItem {...muiMenuItemProps}>{props.children}</MuiMenuItem>;
}

export default MenuItem;
