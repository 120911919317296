import {
  FilterListOff as FilterListOffIcon,
  PendingActionsOutlined,
  Search as SearchIcon,
} from "@mui/icons-material";
import {
  Divider as MuiDivider,
  IconButton as MuiIconButton,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  Tooltip as MuiTooltip,
} from "@mui/material";
import { useState } from "react";
import Button from "../../../../components/button";
import Grid from "../../../../components/grid";
import Typography from "../../../../components/typography";
import { DateSearchField, ValueSearchField } from "./search-field";

function FilterMenu({ anchorEl, handleClose, page }) {
  const open = Boolean(anchorEl);
  const menuId = open ? "simple-menu" : undefined;
  let min;
  let max;
  let minDate;
  let maxDate;

  if (page.data.searchQueryParams?.acquireAmount) {
    min = page.data.searchQueryParams.acquireAmount.min;
    max = page.data.searchQueryParams.acquireAmount.max;
  }
  const [acquireAmountMin, setMin] = useState(min || 0);
  const [acquireAmountMax, setMax] = useState(max || 0);

  if (page.data.searchQueryParams?.invoiceDate) {
    minDate = page.data.searchQueryParams.invoiceDate.min;
    maxDate = page.data.searchQueryParams.invoiceDate.max;
  }

  const [dateMin, setMinDate] = useState(minDate);
  const [dateMax, setMaxDate] = useState(maxDate);

  return (
    <MuiMenu
      id={menuId}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <div
        style={{
          padding: "0 16px ",
        }}
      >
        <Typography component={"h2"} variant={"h6"}>
          Filtros
        </Typography>
        <MuiDivider />
        <Grid container spacing={1} direction={"column"} sx={{ mt: 1 }}>
          <Grid item>
            <Typography component={"h2"} variant={"subtitle2"}>
              Data aquisição
            </Typography>
            <MuiMenuItem
              sx={{ gap: 1 }}
              style={{
                backgroundColor: "transparent",
              }}
              disableTouchRipple
              disableRipple
              disableGutters
              unselectable="off"
            >
              <DateSearchField
                label={"inicial"}
                value={dateMin}
                onChange={(event) => setMinDate(event.target.value)}
              />
              até
              <DateSearchField
                label={"final"}
                value={dateMax}
                onChange={(event) => setMaxDate(event.target.value)}
              />
            </MuiMenuItem>
          </Grid>
          <Grid item>
            <Typography component={"h2"} variant={"subtitle2"}>
              Valor da aquisição
            </Typography>
            <MuiMenuItem
              sx={{ gap: 1 }}
              style={{
                backgroundColor: "transparent",
              }}
              disableTouchRipple
              disableRipple
              disableGutters
              unselectable="off"
            >
              <ValueSearchField
                label={"Min."}
                value={acquireAmountMin}
                onChange={(event) => setMin(event.target.value)}
              />
              até
              <ValueSearchField
                label={"Max."}
                value={acquireAmountMax}
                onChange={(event) => setMax(event.target.value)}
              />
            </MuiMenuItem>
          </Grid>
          <Grid item textAlign="right">
            <Button
              type={"button"}
              variant={"outlined"}
              sx={{
                marginRight: 1,
              }}
              onClick={(e) => {
                e.preventDefault();
                page.setData({
                  searchQueryParams: {
                    ...page.data.searchQueryParams,
                    acquireAmount: {
                      max: null,
                      min: null,
                    },
                    invoiceDate: {
                      min: null,
                      max: null,
                    },
                  },
                });
              }}
            >
              Cancelar
            </Button>
            <Button
              startIcon={<SearchIcon />}
              type={"button"}
              onClick={() =>
                page.setData({
                  searchQueryParams: {
                    ...page.data.searchQueryParams,
                    acquireAmount: {
                      max: acquireAmountMax,
                      min: acquireAmountMin,
                    },
                    invoiceDate: {
                      min: dateMin,
                      max: dateMax,
                    },
                  },
                })
              }
            >
              Aplicar filtros
            </Button>
          </Grid>
        </Grid>
      </div>
    </MuiMenu>
  );
}

export function FilterButton({ Icon, page }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <MuiIconButton
        onClick={handleClick}
        sx={{
          marginRight: "15px",
          color: "inherit",
        }}
      >
        <Icon />
      </MuiIconButton>
      <FilterMenu
        anchorEl={anchorEl}
        handleClose={() => setAnchorEl(null)}
        page={page}
      />
    </>
  );
}

export function ClearFiltersButton({ page }) {
  return (
    <MuiTooltip title="Limpar todos os filtros">
      <MuiIconButton
        onClick={(e) => {
          e.preventDefault();
          page.setData({
            searchQueryParams: {
              acquireAmount: {
                min: null,
                max: null,
                hasAcquireAmount: false,
              },
              invoiceDate: {
                max: null,
                min: null,
              },
              description: "",
              invoiceNumber: "",
              number: "",
              rfid: "",
              location: "",
              genre: "",
            },
          });
        }}
      >
        <FilterListOffIcon />
      </MuiIconButton>
    </MuiTooltip>
  );
}

export function PendingActionsButton({ page }) {
  return (
    <MuiTooltip title="Ativos pendentes">
      <MuiIconButton
        onClick={() =>
          page.setData({
            searchQueryParams: {
              ...page.data.searchQueryParams,
              acquireAmount: {
                ...page.data.searchQueryParams.acquireAmount,
                hasAcquireAmount:
                  !page.data.searchQueryParams.acquireAmount.hasAcquireAmount,
              },
            },
          })
        }
        sx={{
          marginRight: "15px",
          color: page.data.searchQueryParams.acquireAmount?.hasAcquireAmount
            ? "inherit"
            : "default",
        }}
      >
        <PendingActionsOutlined />
      </MuiIconButton>
    </MuiTooltip>
  );
}
