import Page from "./update-user-invite";
import { PageContextProvider } from "./context";

const Component = () => {
  return (
    <PageContextProvider>
      <Page />
    </PageContextProvider>
  );
};

export default Component;
