import SettingsIcon from "@mui/icons-material/Settings";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { SidebarMenuItemProps } from "./sidebar-menu-item.types";

export default function SidebarMenuItem({
  color,
  href,
  label,
  hasAccess,
  Icon,
}) {
  return (
    <ListItemButton
      component="a"
      href={href}
      sx={{ display: hasAccess ? null : "none" }}
    >
      <ListItemIcon>
        <Icon color={color} />
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>
  );
}
