import {
  createPageContext,
  createPageField,
  PageField,
} from "../../../components/page";

export interface User {
  id: string;
  name: string;
  email: string;
  admin: boolean;
  active: boolean;
  locations?: Location[];
  roles?: Role[];
  editedRole?: {
    insert: [];
    delete: [];
  };
  editedLocation?: {
    insert: [];
    delete: [];
  };
}

export interface Role {
  id: string;
  name: string;
}

export interface Location {
  id: string;
  name: string;
}

export interface Data {
  user?: User;
  roles?: Role[];
  locations?: Location[];
  error?: boolean;
  oldData?: {
    roles?: Role[];
    locations?: Location[];
  };
}

export interface Values {
  name?: PageField;
  email?: PageField;
  roles?: PageField;
  locations?: PageField;
  openDialog?: PageField;
  isLoading?: PageField;
}

const { PageContextProvider, usePageContext } = createPageContext<Data, Values>(
  {},
  {
    name: createPageField("", { required: true }),
    email: createPageField("", { required: true }),
    roles: createPageField("", { required: true }),
    locations: createPageField("", { required: true }),
    openDialog: createPageField(false, { required: true }),
    isLoading: createPageField(false, { required: true }),
  }
);

export { PageContextProvider, usePageContext };
