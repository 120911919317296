// import { makeStyles } from "@mui/material";
import {
  ArrowBackIos,
  ArrowForwardIos,
  Close as CloseIcon,
  ImageNotSupportedOutlined as ImageNotSupportedOutlinedIcon,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import { useState } from "react";
import Dialog from "../../../../components/dialog";
import Grid from "../../../../components/grid";
import Typography from "../../../../components/typography";
import { usePageContext } from "../context";

export function PicturesAssetGallery({ isOpen }) {
  const page = usePageContext();
  let [index, setIndex] = useState(0);

  const firstIndex = 0;
  const lastIndex = page.data.picturesUrls.length - 1;

  const changeNextPicture = async () => {
    setIndex(index + 1);

    if (index === lastIndex) setIndex((index = 0));
  };

  const changePreviousPicture = async () => {
    setIndex(index - 1);

    if (index === firstIndex) setIndex(lastIndex);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={page.data.isGalleryOpen}
      xCloseButton={
        <Button
          variant="text"
          color="error"
          onClick={() => page.setData({ isGalleryOpen: false })}
        >
          <CloseIcon fontSize="small" />
        </Button>
      }
    >
      <Grid
        container
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Grid
          item
          sx={{
            maxWidth: "600px",
            height: "60vh",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          {!page.data.picturesUrls.length ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImageNotSupportedOutlinedIcon />
              <Typography>Imagem não localizada</Typography>
            </div>
          ) : (
            <img
              alt="Imagem do Ativo"
              style={{ display: "block", maxWidth: "100%", maxHeight: "100%" }}
              src={page.data.picturesUrls[index]}
            />
          )}
        </Grid>
      </Grid>
      <Grid container fullWidth>
        <Grid
          item
          xs={12}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Button onClick={() => changePreviousPicture()}>
            <ArrowBackIos />
          </Button>
          {[index + 1]} / {page.data.picturesUrls.length ?? 0}
          <Button onClick={() => changeNextPicture()}>
            <ArrowForwardIos />
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}
