import {
  Category as CategoryIcon,
  DescriptionOutlined as DescriptionIcon,
  DocumentScannerOutlined as DocumentScannerOutlinedIcon,
  FileDownload as DownloadIcon,
  FilterList,
} from "@mui/icons-material";
import { CircularProgress, Tooltip as MuiTooltip } from "@mui/material";
import moment from "moment";
import { useEffect } from "react";
import * as XLSX from "xlsx";
import Button from "../../../components/button";
import Datagrid from "../../../components/datagrid";
import Page from "../../../components/page";
import { useAsset } from "../../../hooks/asset";
import { useSession } from "../../../hooks/session";
import {
  ClearFiltersButton,
  FilterButton,
} from "../../assets-menu/assets/components/filters-menu";
import {
  SearchField,
  SelectSearchField,
} from "../../assets-menu/assets/components/search-field";
import { usePageContext } from "./context";

export default function CalcPage() {
  const page = usePageContext();
  const hooks = {
    calc: useAsset(),
    assets: useAsset(),
  };
  const session = useSession();

  useEffect(() => {
    if (!page.data.genreFilter) (async () => await getGenres())();
    if (!page.data.assets || page.data.pagination)
      (async () => await getCalculatedAssets())();
  }, [page.data.pagination, page.data.searchQueryParams]);

  const getCalculatedAssets = async () => {
    const { count, asset: assets } =
      await hooks.calc.fetchCalculatedDepreciation(
        session,
        page.data.pagination,
        page.data.searchQueryParams
      );

    page.setData({
      count,
      assets,
    });
  };

  const getGenres = async () => {
    const allGenres = await hooks.assets.fetchGenresAccount(session);
    const genreList = allGenres.sort((a, b) => {
      return a > b ? 1 : b > a ? -1 : 0;
    });
    page.setData({
      genreFilter: genreList.map((item) => ({
        description: item.description,
      })),
    });
  };

  const handlePageChange = async (_, newPage) => {
    page.setData({
      pagination: {
        ...page.data.pagination,
        page: newPage,
      },
    });
  };

  const handleChangeRowsPerPage = async (event) => {
    page.setData({
      pagination: {
        page: 0,
        rowsPerPage: event.target.value,
      },
    });
  };

  const rows = (assets) =>
    assets.map((asset) => ({
      description: asset.description,
      number: asset.number,
      genre: asset.genre,
      acquirementValue: asset.acquirementValue.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        style: "currency",
        currency: "BRL",
      }),
      accountingValue: parseFloat(asset.accountingValue).toLocaleString(
        "pt-BR",
        {
          minimumFractionDigits: 2,
          style: "currency",
          currency: "BRL",
        }
      ),
    }));

  const columns = [
    {
      header: "Ativo",
      align: "left",
    },
    {
      header: "Patrimônio",
      align: "left",
    },
    {
      header: "Gênero",
      align: "left",
    },
    {
      header: "Valor da aquisição",
      align: "right",
    },
    {
      header: "Valor contábil",
      align: "right",
    },
  ];

  const downloadAccountingDepreciationCalculation = async () => {
    const { assets } = await hooks.calc.fetchCalculatedDepreciationExport(
      session,
      page.data.searchQueryParams
    );

    let dataToExport = assets.map((asset) => {
      const assetsToExport = {
        Patrimônio: asset?.number,
        Descrição: asset?.description,
        "Valor de aquisição": asset?.acquirementValue.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          style: "currency",
          currency: "BRL",
        }),
        "Valor contábil": parseFloat(asset?.accountingValue).toLocaleString(
          "pt-BR",
          {
            minimumFractionDigits: 2,
            style: "currency",
            currency: "BRL",
          }
        ),
        "Depreciação contábil acumulada": parseFloat(
          asset?.accumulatedAccountingDepreciation
        ).toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          style: "currency",
          currency: "BRL",
        }),
      };

      asset?.lastYearAccountingDepreciation.forEach((i) => {
        assetsToExport[i.month] = i.percentInThisMonth.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          style: "currency",
          currency: "BRL",
        });
      });

      return assetsToExport;
    });

    const filename = `Depreciacao Contabil ${
      session.account.selectedCompany.name
    }_${Date.now()}.xlsx`;
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, filename);
  };

  const downloadTaxDepreciationCalculation = async () => {
    const { assets } = await hooks.calc.fetchCalculatedDepreciationExport(
      session,
      page.data.searchQueryParams
    );

    let dataToExport = assets.map((asset) => {
      const assetsToExport = {
        Patrimônio: asset?.number,
        Descrição: asset?.description,
        "Valor de aquisição": asset?.acquirementValue.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          style: "currency",
          currency: "BRL",
        }),
        "Valor contábil": parseFloat(asset?.accountingValue).toLocaleString(
          "pt-BR",
          {
            minimumFractionDigits: 2,
            style: "currency",
            currency: "BRL",
          }
        ),
        "Depreciação contábil acumulada": parseFloat(
          asset?.accumulatedAccountingDepreciation
        ).toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          style: "currency",
          currency: "BRL",
        }),
      };

      asset?.lastYearTaxDepreciation.forEach((i) => {
        assetsToExport[i.month] = i.percentInThisMonth.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          style: "currency",
          currency: "BRL",
        });
      });

      return assetsToExport;
    });

    const filename = `Depreciacao Fiscal ${
      session.account.selectedCompany.name
    }_${Date.now()}.xlsx`;
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, filename);
  };

  const downloadInfoAssets = async () => {
    const { assets } = await hooks.calc.fetchCalculatedDepreciationExport(
      session,
      page.data.searchQueryParams
    );

    let dataToExport = assets.map((asset) => {
      const assetsToExport = {
        Patrimônio: asset?.number,
        Descrição: asset?.description,
        Gênero: asset?.genre,
        "Data de aquisição": moment(asset?.invoiceDate).format("DD/MM/YYYY"),
        "Documento Fiscal": asset?.invoiceNumber,
        "Valor de aquisição": asset?.acquirementValue.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          style: "currency",
          currency: "BRL",
        }),
        "Valor contábil": parseFloat(asset?.accountingValue).toLocaleString(
          "pt-BR",
          {
            minimumFractionDigits: 2,
            style: "currency",
            currency: "BRL",
          }
        ),
        "Valor futuro de venda": asset?.futureSellAmount,
        "Valor justo de mercado": asset?.remainingAmount,
        "Vida útil/Anos": asset?.yearsToDepreciate,
        "Depreciação fiscal acumulada": parseFloat(
          asset?.accumulatedTaxDepreciation
        ).toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          style: "currency",
          currency: "BRL",
        }),
        "Depreciação contábil acumulada": parseFloat(
          asset?.accumulatedAccountingDepreciation
        ).toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          style: "currency",
          currency: "BRL",
        }),
      };

      return assetsToExport;
    });

    const filename = `Depreciacao dos Ativos ${
      session.account.selectedCompany.name
    }_${Date.now()}.xlsx`;
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, filename);
  };

  return (
    <Page
      context={page.context}
      requiresAuthentication
      keyAccess="getCalculation"
      showSideBar
      showAppBar
      title={"Depreciação"}
      breadcrumbs={[
        { description: "Home", link: "/" },
        { description: "Depreciação", link: "/depreciation" },
      ]}
      toolbar={[
        <MuiTooltip title="Exportar dados">
          <div>
            <Button
              startIcon={<DownloadIcon />}
              sx={{
                backgroundColor: "#66bb6a",
                ":hover": { backgroundColor: "#10783f" },
              }}
              onClick={async () => {
                page.setData({
                  searchQueryParams: {
                    calculation: false,
                  },
                });
                await downloadInfoAssets();
              }}
            >
              Exportar
            </Button>
          </div>
        </MuiTooltip>,
        <MuiTooltip title="Exportar cálculo fiscal">
          <div>
            <Button
              startIcon={<DownloadIcon />}
              sx={{
                backgroundColor: "#66bb6a",
                ":hover": { backgroundColor: "#10783f" },
              }}
              onClick={async () => {
                page.setData({
                  searchQueryParams: {
                    calculation: true,
                  },
                });
                await downloadTaxDepreciationCalculation();
              }}
            >
              Cálculo Fiscal
            </Button>
          </div>
        </MuiTooltip>,
        <MuiTooltip title="Exportar cálculo fiscal">
          <div>
            <Button
              startIcon={<DownloadIcon />}
              sx={{
                backgroundColor: "#66bb6a",
                ":hover": { backgroundColor: "#10783f" },
              }}
              onClick={async () => {
                page.setData({
                  searchQueryParams: {
                    calculation: true,
                  },
                });
                await downloadAccountingDepreciationCalculation();
              }}
            >
              Cálculo Contábil
            </Button>
          </div>
        </MuiTooltip>,
      ]}
    >
      {!page.data.assets ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Datagrid
          tableAppBar={
            <div style={{ padding: 16, display: "flex", gap: 16 }}>
              <SearchField
                iconPosition={"start"}
                initialValue={page.data.searchQueryParams.description}
                queryKey={"description"}
                Icon={<DescriptionIcon />}
                label={"Descrição"}
                placeholder={"Ex: Monitor, computador..."}
                context={page}
              />

              <SearchField
                iconPosition={"start"}
                initialValue={page.data.searchQueryParams.number}
                queryKey={"number"}
                Icon={<DocumentScannerOutlinedIcon />}
                label={"Patrimônio"}
                placeholder={"Número interno de controle da empresa"}
                context={page}
              />

              <SelectSearchField
                iconPosition={"start"}
                initialValue={page.data.searchQueryParams.genre}
                queryKey={"genre"}
                Icon={<CategoryIcon />}
                label={"Gênero"}
                placeholder={"Gênero"}
                context={page}
                optionsList={page.data.genreFilter ?? []}
              />

              <SearchField
                initialValue={page.data.searchQueryParams.dateEndCalculation}
                queryKey={"dateEndCalculation"}
                label={"Data final do cálculo"}
                placeholder={"Data final do cálculo"}
                type={"date"}
                context={page}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <div style={{ display: "flex" }}>
                <FilterButton Icon={FilterList} page={page} />
                <ClearFiltersButton page={page} />
              </div>
            </div>
          }
          dataLenght={page.data.count}
          rows={rows(page.data.assets).map((row) => ({
            cells: Object.values(row),
          }))}
          columns={columns}
          paginationProps={{ ...page.data.pagination, count: page.data.count }}
          handlePageChange={async (event, page) => {
            await handlePageChange(event, page);
          }}
          handleChangeRowsPerPage={async (event) => {
            await handleChangeRowsPerPage(event);
          }}
        />
      )}
    </Page>
  );
}
