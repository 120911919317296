import { Grid, Paper, Typography, useTheme } from "@mui/material";
import { useSession } from "../../../../hooks/session";

export default function DashChartCard({
  fullWidth,
  label,
  Chart,
  accessId,
  data,
}) {
  const theme = useTheme();
  const session = useSession();
  return (
    session.hasAccess({ key: accessId, accessIds: session.access }) && (
      <Grid item xs={fullWidth ? 12 : true}>
        <Paper
          elevation={25}
          sx={{
            p: 2,
            boxSizing: "border-box",
            borderLeft: `4px solid ${theme.palette.primary.main}`,
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <Typography component={"h3"} variant={"subtitle2"}>
                {label}
              </Typography>
            </Grid>
            <Grid item width={"100%"} height={300}>
              <Chart data={data} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    )
  );
}
