import Avatar from "../../components/avatar/avatar";
import Button from "../../components/button";
import Typography from "../../components/typography";
import TextField from "../../components/text-field";
import Page from "../../components/page";
import jwt from "jwt-decode";

import {
  Check as CheckIcon,
  PendingOutlined as PendingIcon,
  Close as CloseIcon,
  WebAssetOffOutlined as WebAssetOffOutlinedIcon,
} from "@mui/icons-material";
import { usePageContext } from "./context";
import { useSession } from "../../hooks/session";
import { useUser } from "../../hooks/user";
import { useTheme } from "@mui/material/styles";
import Grid from "../../components/grid";
import { useNavigate } from "react-router-dom";
import { Paper } from "@mui/material";
import { useEffect } from "react";

const CreateUserView = () => {
  const page = usePageContext();
  const theme = useTheme();
  const hooks = { user: useUser(), session: useSession() };
  const urlParams = new URLSearchParams(window.location.search);
  const invitetoken = urlParams.get("invitetoken");
  const tokenInfo = jwt(invitetoken);
  const navigate = useNavigate();

  const createUser = async () => {
    await hooks.user
      .linkNewUser(
        {
          name: page.values.name.value,
          password: page.values.password.value,
        },
        {
          token: invitetoken,
        }
      )
      .then((res) => {
        if (res.errors) return page.notify("error", res.errors[0].message);
        page.notify("success", "Usuário criado com sucesso", () =>
          navigate("/")
        );
      });
  };

  const handleChange = (e) => {
    page.setValues({
      name: {
        value: e.target.value,
      },
    });
  };

  return (
    <Paper sx={{ p: 3 }}>
      <Grid container columns={2} spacing={2}>
        <Grid item xs={2}>
          <Typography component="h1" variant="h4" sx={{ width: "100%" }}>
            Criar usuário
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <form
            id="sendInviteForm"
            style={{
              width: "100%",
              marginTop: theme.spacing(1),
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 16,
            }}
            onSubmit={async (e) => {
              e.preventDefault();
              let isNotValidated = page.data.rules.find(
                (rule) => rule.status !== "ok"
              );
              if (isNotValidated) {
                page.notify(
                  "error",
                  "Senha não atende aos requisitos, tente novamente"
                );
              } else {
                await createUser();
              }
            }}
          >
            <Typography
              component={"h2"}
              variant="subtitle1"
              sx={{ width: "100%" }}
            >
              Bem vindo {tokenInfo.UserEmail}!
            </Typography>
            <Typography
              component={"h3"}
              variant="subtitle2"
              sx={{ width: "100%" }}
            >
              Vamos finalizar seu cadastro:
            </Typography>
            <TextField
              id="name"
              name="name"
              label="Nome"
              fullWidth
              margin="normal"
              field={page.values.name}
              onChange={handleChange}
            />
            <TextField
              id="password"
              name="password"
              type="password"
              fullWidth
              margin="none"
              label="Senha"
              field={page.values.password}
              onChange={(e) => {
                page.setValues({
                  password: {
                    value: e.target.value,
                  },
                });

                page.setData({
                  rules: page.data.rules.map((rule) => {
                    if (rule.name === "number") {
                      const regexNumber = /[0-9]/;
                      if (regexNumber.test(e.target.value)) {
                        rule.status = "ok";
                      } else if (e.target.value.length === 0) {
                        rule.status = "none";
                      } else {
                        rule.status = "error";
                      }
                    }

                    if (rule.name === "characters") {
                      if (e.target.value.length >= 8) {
                        rule.status = "ok";
                      } else if (e.target.value.length === 0) {
                        rule.status = "none";
                      } else {
                        rule.status = "error";
                      }
                    }

                    if (rule.name === "upperLetter") {
                      const regexUpperCase = /[A-Z]/;
                      if (regexUpperCase.test(e.target.value)) {
                        rule.status = "ok";
                      } else if (e.target.value.length === 0) {
                        rule.status = "none";
                      } else {
                        rule.status = "error";
                      }
                    }

                    if (rule.name === "confirmPassword") {
                      if (
                        page.values.confirmPassword.value === e.target.value &&
                        e.target.value !== ""
                      ) {
                        rule.status = "ok";
                      } else if (e.target.value.length === 0) {
                        rule.status = "none";
                      } else {
                        rule.status = "error";
                      }
                    }

                    return rule;
                  }),
                });
              }}
            />
            <TextField
              id="confirm-password"
              name="confirm-password"
              type="password"
              fullWidth
              margin="none"
              label="Confirmar senha"
              field={page.values.confirmPassword}
              onChange={(e) => {
                page.setValues({
                  confirmPassword: {
                    value: e.target.value,
                  },
                });

                page.setData({
                  rules: page.data.rules.map((rule) => {
                    if (rule.name === "confirmPassword") {
                      if (
                        page.values.password.value === e.target.value &&
                        e.target.value !== ""
                      ) {
                        rule.status = "ok";
                      } else if (e.target.value.length === 0) {
                        rule.status = "none";
                      } else {
                        rule.status = "error";
                      }
                    }

                    return rule;
                  }),
                });
              }}
            />
            <Grid item xs={2} sx={{ width: "100%" }}>
              <Typography component="h1" variant="h6">
                Sua senha deve conter:
              </Typography>
              {page.data.rules.some((rule) => rule) &&
                page.data.rules.map((rule) => (
                  <div
                    key={rule.name}
                    style={{
                      display: "flex",
                      gap: 8,
                    }}
                  >
                    <Typography component="p" variant="caption">
                      {rule.description}
                    </Typography>
                    {rule.status === "none" ? (
                      <PendingIcon fontSize="small" />
                    ) : rule.status === "ok" ? (
                      <CheckIcon fontSize="small" color="success" />
                    ) : (
                      <CloseIcon fontSize="small" color="error" />
                    )}
                  </div>
                ))}
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{
                margin: theme.spacing(2, 0, 8),
              }}
            >
              Salvar
            </Button>
          </form>
        </Grid>
      </Grid>
    </Paper>
  );
};
const AlreadyLoggedInWithAnotherUserView = () => {
  const hooks = { user: useUser(), session: useSession() };

  const navigate = useNavigate();

  return (
    <Paper sx={{ p: 3, maxWidth: 500 }}>
      <Grid container columns={2} spacing={3} textAlign="center">
        <Grid item sm={2}>
          <Typography component={"h1"} variant={"h5"}>
            Sessão já iniciada
          </Typography>
        </Grid>
        <Grid item sm={2}>
          <Typography component={"p"} variant={"body1"}>
            Parece que você já possui uma sessão iniciada com o e-mail{" "}
            <b>{hooks.session.account.user.email}</b>. Deseja alterar ou
            permanecer na mesma conta?
          </Typography>
        </Grid>
        <Grid item sm={2} container columns={2} spacing={1}>
          <Grid item xs={1}>
            <Button
              fullWidth
              variant="text"
              color="warning"
              onClick={async () => {
                await hooks.session.signOut();
              }}
            >
              Alterar
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Button
              fullWidth
              onClick={async () => {
                navigate("/");
              }}
            >
              Permanecer
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const ExpiredView = () => {
  const theme = useTheme();
  const urlParams = new URLSearchParams(window.location.search);
  const invitetoken = urlParams.get("invitetoken");
  const tokenInfo = jwt(invitetoken);
  const navigate = useNavigate();

  return (
    <Paper sx={{ p: 3, maxWidth: 500 }}>
      <Grid container columns={2} spacing={3} textAlign="center">
        <Grid item sm={2}>
          <Typography
            sx={{ color: theme.palette.warning.light }}
            component={"h1"}
            variant={"h5"}
          >
            Convite Expirado!
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <WebAssetOffOutlinedIcon sx={{ fontSize: 150 }} />
        </Grid>

        <Grid item sm={2}>
          <Typography component={"p"} variant={"body1"}>
            O convite para entrar na empresa <b>{tokenInfo.CompanyName}</b>{" "}
            expirou, solicite ao administrador que envie novamente
          </Typography>
        </Grid>
        <Grid item sm={2}>
          <Button
            fullWidth
            onClick={async () => {
              navigate("/");
            }}
          >
            Ok
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

function LinkUserPage() {
  const page = usePageContext();
  const hooks = { user: useUser(), session: useSession() };
  const urlParams = new URLSearchParams(window.location.search);
  const invitetoken = urlParams.get("invitetoken");
  const tokenInfo = jwt(invitetoken);
  const isExpired = tokenInfo.ExpiresAt < Date.now() / 1000;
  const isAlreadyLoggedInWithAnotherUser =
    tokenInfo.UserEmail !== hooks.session.account.user.email;

  useEffect(() => {
    if (isExpired) page.setData({ view: "Expired" });
    if (isAlreadyLoggedInWithAnotherUser && hooks.session.isAuthenticated)
      page.setData({ view: "AlreadyLoggedInWithAnotherUser" });
  }, []);

  const mapViewComponents = {
    CreateUser: <CreateUserView />,
    Expired: <ExpiredView />,
    AlreadyLoggedInWithAnotherUser: <AlreadyLoggedInWithAnotherUserView />,
  };
  const currentView = mapViewComponents[page.data.view];

  return (
    <Page context={page.context} maxWidth="md">
      <div
        style={{
          display: "flex",
          height: "100vh",
          maxWidth: "500px",
          alignItems: "center",
          margin: "auto",
        }}
      >
        {currentView}
      </div>
    </Page>
  );
}

export default LinkUserPage;
