import {
  Add as AddIcon,
  Clear as ClearIcon,
  Edit as EditIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { CircularProgress, IconButton, InputAdornment } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import Button from "../../../components/button/button";
import Datagrid from "../../../components/datagrid";
import Grid from "../../../components/grid/grid";
import Page from "../../../components/page";
import TextField from "../../../components/text-field/text-field";
import Typography from "../../../components/typography";
import { useRole } from "../../../hooks/role";
import { useSession } from "../../../hooks/session";
import { usePageContext } from "./context";

function RolePage() {
  const session = useSession();
  const page = usePageContext();
  const navigate = useNavigate();
  const hooks = { role: useRole() };
  useEffect(() => {
    if (!page.data.roles) roleList();
  }, []);

  const roleList = async () => {
    const roles = await hooks.role.fetchRoles(session);

    page.setData({
      roles: roles.map((i) => ({
        id: i.id,
        name: i.name,
        companyId: i.companyId,
        status: i.status,
        view: i.view,
      })),
      searchResult: roles.map((i) => ({
        id: i.id,
        name: i.name,
        companyId: i.companyId,
        status: i.status,
        view: i.view,
      })),
    });
  };

  const newItem = async () => {
    navigate(`/settings/access/${uuid()}`);
  };

  const editItem = async (item) => {
    navigate(`/settings/access/${item.id}`);
  };

  const deleteItem = async (item) => {
    const role = page.data.roles.find((i) => i.id === item.id);
    role.status = "deleted";
    if (role.status === "deleted") {
      const res = await hooks.role.deleteItem(role, session);
      if (res.status === 200) {
        const index = page.data.roles.findIndex((i) => i.id === item.id);
        page.data.roles.splice(index, 1);
      }
    }
    page.setData({
      roles: [...page.data.roles],
      searchResult: [...page.data.roles],
    });
  };

  const datagridToolBar = () => (
    <div style={{ padding: 16, display: "flex", gap: 16 }}>
      <TextField
        fullWidth
        variant="standard"
        field={page.values.searchField}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: page.values.searchField.value !== "" && (
            <InputAdornment position="end">
              <Button
                variant="text"
                onClick={() => {
                  page.setValues({ searchField: { value: "" } });
                  page.setData({ searchResult: page.data.roles });
                }}
              >
                <ClearIcon />
              </Button>
            </InputAdornment>
          ),
          placeholder: "Pesquise pelo nome de um perfil",
        }}
        onChange={(newValue) => {
          page.setValues({
            searchField: { value: newValue.target.value },
          });
          let result = page.data.roles.filter((role) =>
            role.name
              .toUpperCase()
              .includes(page.values.searchField.value.toUpperCase())
          );
          page.setData({
            searchResult: result,
          });
        }}
      />
    </div>
  );

  const createRows = (roles) =>
    roles.map((role) => ({
      name: role.name,
      actions: (
        <div style={{ display: "flex", gap: 16, justifyContent: "center" }}>
          <IconButton
            edge="end"
            disabled={
              !session.hasAccess({ key: "putRole", accessIds: session.access })
            }
            onClick={() => {
              editItem(role);
            }}
          >
            <EditIcon color="primary" />
          </IconButton>
          <IconButton
            edge="end"
            disabled={
              !session.hasAccess({
                key: "deleteRole",
                accessIds: session.access,
              })
            }
            onClick={() => {
              deleteItem(role);
            }}
          >
            <ClearIcon color="warning" />
          </IconButton>
        </div>
      ),
    }));

  return (
    <Page
      title="Perfis"
      showAppBar
      showSideBar
      requiresAuthentication
      keyAccess="getRoles"
      breadcrumbs={[
        { description: "Home", link: "/" },
        { description: "Configurações", link: "/settings" },
        { description: "Perfis", link: "/settings/roles" },
      ]}
      toolbar={[
        <Button
          startIcon={<AddIcon />}
          type="button"
          variant="contained"
          color="primary"
          disabled={
            !session.hasAccess({ key: "postRole", accessIds: session.access })
          }
          onClick={async () => {
            await newItem();
          }}
        >
          Novo
        </Button>,
      ]}
    >
      {page.data.roles === undefined ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Grid container columns={1} spacing={2} sx={{ pb: 4 }}>
          <Grid item xs={1}>
            <Typography component={"p"} variant={"body1"}>
              Os perfis de acesso são responsáveis por administrar o que um
              usuário tem acesso, desde visualizar menus a até mesmo algumas
              funcionalidades dentro do sistema.
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Datagrid
              tableAppBar={datagridToolBar()}
              columns={[
                {
                  header: "Nome",
                  align: "left",
                },
                {
                  header: "Ações",
                  align: "center",
                },
              ]}
              rows={createRows(page.data.searchResult).map((cell) => ({
                cells: Object.values(cell),
              }))}
            />
          </Grid>
        </Grid>
      )}
    </Page>
  );
}

export default RolePage;
