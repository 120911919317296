import {
  DateRangeOutlined as DateRangeOutlinedIcon,
  DescriptionOutlined as DescriptionIcon,
  East as EastIcon,
  FilterList,
  FilterListOff as FilterListOffIcon,
  NearMe as NearMeIcon,
  OpenInNewOutlined,
  Search as SearchIcon,
} from "@mui/icons-material";
import {
  CircularProgress,
  InputAdornment,
  Divider as MuiDivider,
  IconButton as MuiIconButton,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  Switch as MuiSwitch,
  Tooltip as MuiTooltip,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AutoComplete from "../../../components/auto-complete";
import Button from "../../../components/button";
import Datagrid from "../../../components/datagrid";
import Dialog from "../../../components/dialog";
import Grid from "../../../components/grid";
import Page from "../../../components/page";
import TextField from "../../../components/text-field";
import Typography from "../../../components/typography";
import { useAsset } from "../../../hooks/asset";
import { useLocation } from "../../../hooks/location";
import { useSession } from "../../../hooks/session";
import { usePageContext } from "./context";

export default function AssetsMovementPage() {
  const page = usePageContext();
  const hooks = { assets: useAsset(), local: useLocation() };
  const session = useSession();
  const [queryParams, setQueryParams] = useSearchParams();
  const assetId = queryParams.get("id");
  const navigate = useNavigate();

  useEffect(() => {
    if (!page.data.assets || page.data.pagination)
      (async () => await getAssetMovements())();
    if (!page.data.locals) (async () => await getLocals())();
  }, [page.data.pagination, page.data.searchQueryParams]);

  const getLocals = async () => {
    const allLocals = await hooks.local.fetchLocations(session);

    const children = allLocals.filter((local, _, array) =>
      array.every((arrayItem) => arrayItem.parentId !== local.id)
    );

    page.setData({
      locals: children.map((child) => ({
        id: child.id,
        name: child.description,
      })),
    });
  };

  const getAssetMovements = async () => {
    const { count, assetSpot: assets } = await hooks.assets.fetchMovements(
      session,
      page.data.pagination,
      { ...page.data.searchQueryParams, assetId }
    );
    page.setData({
      count,
      assets,
    });
  };

  const getAllMovements = () => {
    setQueryParams("");
    page.setData({
      searchQueryParams: {
        ...page.data.searchQueryParams,
        assetId: "",
      },
    });
  };

  const moveAsset = async () => {
    await hooks.assets.moveAsset(
      session,
      page.data.dialog.selectedAsset,
      page.values.newLocal.value.id
    );
    page.notify("success", "Ativo movido com sucesso", async () => {
      await getAssetMovements();
      page.setData({
        dialog: {
          isOpen: false,
        },
        pagination: {
          page: 0,
          rowsPerPage: 10,
        },
      });
    });
  };

  const handlePageChange = async (_, newPage) => {
    page.setData({
      pagination: {
        ...page.data.pagination,
        page: newPage,
      },
    });
  };

  const handleChangeRowsPerPage = async (event) => {
    page.setData({
      pagination: {
        page: 0,
        rowsPerPage: event.target.value,
      },
    });
  };

  const rows = (assets) =>
    assets.map((asset) => ({
      description: (
        <MuiTooltip title="Ver ativo">
          <div>
            <Button
              endIcon={<OpenInNewOutlined />}
              onClick={() => navigate(`../form?id=${asset.assetId}`)}
              variant={"text"}
            >
              {asset.description}
            </Button>
          </div>
        </MuiTooltip>
      ),
      rfid: asset.assetRfid,
      dateIn: moment(asset.dateIn).format("DD/MM/YYYY"),
      dateOut: asset.dateOut
        ? moment(asset.dateOut).format("DD/MM/YYYY")
        : "Local atual do ativo",
      local: asset.location,
      actions: !asset.dateOut && (
        <MuiTooltip title="Movimentar ativo">
          <MuiIconButton
            edge="end"
            onClick={() => {
              page.setValues({
                local: { value: { name: asset.location } },
              });

              page.setData({
                dialog: {
                  isOpen: true,
                  title: `Mover Ativo: ${asset.description}`,
                  selectedAsset: asset.assetId,
                },
              });
            }}
            color="primary"
          >
            <NearMeIcon />
          </MuiIconButton>
        </MuiTooltip>
      ),
    }));

  const columns = [
    {
      header: "Ativo",
      align: "left",
    },
    {
      header: "Tag RFID",
      align: "left",
    },
    {
      header: "Data de entrada",
      align: "left",
    },
    {
      header: "Data de saída",
      align: "left",
    },
    {
      header: "Local",
      align: "left",
    },
    {
      header: "Ações",
      align: "center",
    },
  ];

  return (
    <Page
      context={page.context}
      requiresAuthentication
      keyAccess="getAsset"
      showSideBar
      showAppBar
      title="Movimentação de Ativos"
      breadcrumbs={[
        { description: "Home", link: "/" },
        { description: "Ativos", link: "/assets" },
        { description: "Movimentação de Ativos", link: "/assets/movimentacao" },
      ]}
      toolbar={[
        <Button type="button" onClick={getAllMovements}>
          Ver toda movimentação
        </Button>,
        <Dialog
          open={page.data.dialog.isOpen}
          title={page.data.dialog.title}
          fullWidth
          maxWidth={"sm"}
        >
          <Grid container spacing={3} sx={{ pt: 1 }}>
            <Grid item xs={6}>
              <AutoComplete
                id="local"
                disabled
                label="Local atual"
                field={page.values.local}
                options={page.data.locals ?? []}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => {
                  return option.name || "";
                }}
                onChange={(_, val) => {
                  page.setValues({
                    local: {
                      value: val,
                    },
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <AutoComplete
                id="local-novo"
                label="Novo local"
                field={page.values.newLocal}
                options={page.data.locals ?? []}
                getOptionLabel={(option) => {
                  return option.name || "";
                }}
                onChange={(_, val) => {
                  page.setValues({
                    newLocal: {
                      value: val,
                    },
                  });
                }}
              />
            </Grid>
            <Grid
              item
              container
              xs={12}
              spacing={1}
              sx={{ justifyContent: "right" }}
            >
              <Grid item>
                <Button
                  type="button"
                  variant="text"
                  color="warning"
                  onClick={() => {
                    page.setData({ dialog: { isOpen: false } });
                    page.setValues({ newLocal: { value: null } });
                  }}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="button"
                  endIcon={<EastIcon />}
                  onClick={async () => await moveAsset()}
                >
                  Mover
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Dialog>,
      ]}
    >
      {!page.data.assets ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Grid container columns={1} spacing={2} sx={{ pb: 4 }}>
          <Grid item xs={1}>
            <Typography component={"p"} variant={"body1"}>
              Acompanhe a movimentação de seus ativos e altere eles de local
              quando necessário. Busque pela descrição ou filtre por período de
              data de entrada ou saída
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Datagrid
              tableAppBar={
                !queryParams.get("id") && (
                  <div style={{ padding: 16, display: "flex", gap: 16 }}>
                    <SearchField
                      iconPosition={"start"}
                      initialValue={page.data.searchQueryParams.description}
                      queryKey={"description"}
                      Icon={<DescriptionIcon />}
                      label={"Ativo"}
                      placeholder={"Ex: Monitor, computador..."}
                    />
                    <div style={{ display: "flex" }}>
                      <FilterButton Icon={FilterList} page={page} />
                      <ClearFiltersButton page={page} />
                    </div>
                  </div>
                )
              }
              dataLenght={page.data.count}
              rows={rows(page.data.assets).map((row) => ({
                cells: Object.values(row),
              }))}
              columns={columns}
              paginationProps={{
                ...page.data.pagination,
                count: page.data.count,
              }}
              handlePageChange={async (event, page) => {
                await handlePageChange(event, page);
              }}
              handleChangeRowsPerPage={async (event) => {
                await handleChangeRowsPerPage(event);
              }}
            />
          </Grid>
        </Grid>
      )}
    </Page>
  );
}

const ClearFiltersButton = (page) => {
  return (
    <MuiTooltip title="Limpar todos os filtros">
      <MuiIconButton
        onClick={() =>
          page.setData({
            searchQueryParams: {
              description: "",
              dateInMin: null,
              dateInMax: null,
            },
          })
        }
      >
        <FilterListOffIcon />
      </MuiIconButton>
    </MuiTooltip>
  );
};

const FilterMenu = ({ anchorEl, handleClose }) => {
  const page = usePageContext();

  const open = Boolean(anchorEl);
  const menuId = open ? "simple-menu" : undefined;
  let minDateOut;
  let maxDateOut;
  let minDateIn;
  let maxDateIn;
  if (page.data.searchQueryParams) {
    minDateIn = page.data.searchQueryParams.dateInMin;
    minDateOut = page.data.searchQueryParams.dateOutMin;
    maxDateIn = page.data.searchQueryParams.dateInMax;
    maxDateOut = page.data.searchQueryParams.dateOutMax;
  }

  const [inMin, setMinDateIn] = useState(minDateIn);
  const [outMin, setMinDateOut] = useState(minDateOut);
  const [inMax, setMaxDateIn] = useState(maxDateIn);
  const [outMax, setMaxDateOut] = useState(maxDateOut);
  const [onlyWithMovement, setOnlyWithMovement] = useState(
    page.data.searchQueryParams.movementOnly
  );

  return (
    <MuiMenu
      id={menuId}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <div
        style={{
          padding: "0 16px ",
        }}
      >
        <Typography component={"h2"} variant={"h6"}>
          Período
        </Typography>
        <MuiDivider />
        <Grid container spacing={1} direction={"column"} sx={{ mt: 1 }}>
          <Grid item>
            <Typography component={"h2"} variant={"subtitle2"}>
              Data entrada
            </Typography>
            <MuiMenuItem
              sx={{ gap: 1 }}
              style={{
                backgroundColor: "transparent",
              }}
              disableTouchRipple
              disableRipple
              disableGutters
              unselectable="off"
            >
              <DateSearchField
                label={"inicial"}
                value={inMin}
                onChange={(event) => setMinDateIn(event.target.value)}
              />
              até
              <DateSearchField
                label={"final"}
                value={inMax}
                onChange={(event) => setMaxDateIn(event.target.value)}
              />
            </MuiMenuItem>
          </Grid>
          <Grid item>
            <Typography component={"h2"} variant={"subtitle2"}>
              Data saída
            </Typography>
            <MuiMenuItem
              sx={{ gap: 1 }}
              style={{
                backgroundColor: "transparent",
              }}
              disableTouchRipple
              disableRipple
              disableGutters
              unselectable="off"
            >
              <DateSearchField
                label={"inicial"}
                value={outMin}
                onChange={(event) => setMinDateOut(event.target.value)}
              />
              até
              <DateSearchField
                label={"final"}
                value={outMax}
                onChange={(event) => setMaxDateOut(event.target.value)}
              />
            </MuiMenuItem>
          </Grid>
          <Grid item>
            <MuiMenuItem
              sx={{ justifyContent: "space-between" }}
              style={{
                backgroundColor: "transparent",
              }}
              disableTouchRipple
              disableRipple
              disableGutters
              unselectable="off"
              onClick={() => setOnlyWithMovement(!onlyWithMovement)}
            >
              <Typography component={"h2"} variant={"subtitle2"}>
                Apenas com movimentação
              </Typography>
              <MuiSwitch
                checked={onlyWithMovement}
                onChange={(_, checked) => setOnlyWithMovement(checked)}
              />
            </MuiMenuItem>
          </Grid>
          <Grid item textAlign="right">
            <Button
              type={"button"}
              variant={"outlined"}
              sx={{
                marginRight: 1,
              }}
              onClick={(e) => {
                e.preventDefault();
                page.setData({
                  searchQueryParams: {
                    ...page.data.searchQueryParams,
                    dateInMax: null,
                    dateInMin: null,
                    dateOutMax: null,
                    dateOutMin: null,
                    movementOnly: false,
                  },
                });
              }}
            >
              Cancelar
            </Button>
            <Button
              startIcon={<SearchIcon />}
              type={"button"}
              onClick={() =>
                page.setData({
                  searchQueryParams: {
                    ...page.data.searchQueryParams,
                    dateInMax: inMax,
                    dateInMin: inMin,
                    dateOutMax: outMax,
                    dateOutMin: outMin,
                    movementOnly: onlyWithMovement,
                  },
                  pagination: {
                    page: 0,
                    rowsPerPage: 10,
                  },
                })
              }
            >
              Aplicar filtros
            </Button>
          </Grid>
        </Grid>
      </div>
    </MuiMenu>
  );
};

const FilterButton = ({ Icon, page }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <MuiIconButton
        onClick={handleClick}
        sx={{
          marginRight: "15px",
          color: "inherit",
        }}
      >
        <Icon />
      </MuiIconButton>
      <FilterMenu anchorEl={anchorEl} handleClose={() => setAnchorEl(null)} />
    </>
  );
};

const SearchField = ({
  initialValue,
  queryKey,
  Icon,
  label,
  placeholder,
  iconPosition,
  size,
}) => {
  const [searchValue, setSearchValue] = useState(initialValue);

  const page = usePageContext();

  useEffect(() => {
    if (searchValue !== initialValue) {
      const timeOutId = setTimeout(
        () =>
          page.setData({
            searchQueryParams: {
              ...page.data.searchQueryParams,
              [queryKey]: searchValue,
            },
            pagination: {
              page: 0,
              rowsPerPage: 10,
            },
          }),
        500
      );
      return () => clearTimeout(timeOutId);
    }
  }, [searchValue]);

  const doSearchOnChange = async (event) => {
    setSearchValue(event.target.value);
  };
  return (
    <TextField
      variant="standard"
      placeholder={placeholder}
      label={label}
      fullWidth
      size={size}
      field={{ value: searchValue }}
      onChange={doSearchOnChange}
      type="search"
      InputProps={{
        [`${iconPosition}Adornment`]: (
          <InputAdornment position={iconPosition}>{Icon}</InputAdornment>
        ),
      }}
    />
  );
};

const DateSearchField = ({ label, value, onChange, variant, size }) => {
  return (
    <TextField
      variant={variant ?? "outlined"}
      label={label}
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <DateRangeOutlinedIcon />
          </InputAdornment>
        ),
      }}
      size={size}
      type="date"
      field={{ value: value }}
      onChange={onChange}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};
