import { Paid as PaidIcon } from "@mui/icons-material";
import {
  InputAdornment,
  MenuItem,
  TextField as TextFieldMui,
} from "@mui/material";
import { useEffect, useState } from "react";
import Select from "../../../../components/select/select";
import TextField from "../../../../components/text-field";

export function SearchField({
  initialValue,
  queryKey,
  Icon,
  label,
  placeholder,
  iconPosition,
  context,
  type,
}) {
  const [searchValue, setSearchValue] = useState(initialValue);

  useEffect(() => {
    if (searchValue !== initialValue) {
      const timeOutId = setTimeout(
        () =>
          context.setData({
            searchQueryParams: {
              ...context.data.searchQueryParams,
              [queryKey]: searchValue,
            },
            pagination: {
              page: 0,
              rowsPerPage: 10,
            },
          }),
        500
      );
      return () => clearTimeout(timeOutId);
    }
  }, [searchValue]);

  useEffect(() => {
    setSearchValue(context.data.searchQueryParams[queryKey]);
  }, [context]);

  const doSearchOnChange = async (event) => {
    setSearchValue(event.target.value);
  };
  return (
    <TextFieldMui
      value={searchValue}
      variant="standard"
      placeholder={placeholder}
      label={label}
      fullWidth
      size={"small"}
      field={{ value: searchValue }}
      onChange={doSearchOnChange}
      type={type}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        [`${iconPosition}Adornment`]: (
          <InputAdornment position={iconPosition}>{Icon}</InputAdornment>
        ),
      }}
    />
  );
}

export function DateSearchField({ label, value, onChange }) {
  return (
    <TextField
      variant="outlined"
      label={label}
      fullWidth
      size={"small"}
      type="date"
      field={{ value: value }}
      onChange={onChange}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
}

export function ValueSearchField({ label, value, onChange }) {
  const fieldValue = !value || value < 0 ? 0 : value;
  return (
    <TextField
      variant="outlined"
      placeholder="0,00"
      label={label}
      fullWidth
      size={"small"}
      name="acquireAmount"
      id="acquireAmount"
      field={{
        value: parseFloat(fieldValue).toFixed(2),
      }}
      onChange={onChange}
      type={"number"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <PaidIcon />
          </InputAdornment>
        ),
      }}
    />
  );
}

export function SelectSearchField({
  initialValue,
  queryKey,
  Icon,
  label,
  placeholder,
  iconPosition,
  context,
  type,
  optionsList,
}) {
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (searchValue !== initialValue) {
      const timeOutId = setTimeout(
        () =>
          context.setData({
            searchQueryParams: {
              ...context.data.searchQueryParams,
              [queryKey]: searchValue,
            },
            pagination: {
              page: 0,
              rowsPerPage: 10,
            },
          }),
        500
      );
      return () => clearTimeout(timeOutId);
    }
  }, [searchValue]);

  useEffect(() => {
    setSearchValue(context.data.searchQueryParams[queryKey]);
  }, [context]);

  const doSearchOnChange = async (event) => {
    setSearchValue(event.target.value);
  };

  return (
    <Select
      value={searchValue}
      label={label}
      placeholder={placeholder}
      onChange={doSearchOnChange}
      variant="standard"
      fullWidth
      size={"small"}
      field={{ value: searchValue }}
      type={type}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        [`${iconPosition}Adornment`]: (
          <InputAdornment position={iconPosition}>{Icon}</InputAdornment>
        ),
      }}
    >
      {optionsList.map((option) => (
        <MenuItem key={option.description} value={option.description}>
          {option.description}
        </MenuItem>
      ))}
    </Select>
  );
}
