import { createPageContext } from "../../components/page";
import { Pagination } from "../../hooks/user/user-hook.types";
import { Reminder } from "./recurring-reminder-form/context";

export interface Data {
  reminders?: Reminder[];
  count?: number;
  pagination?: Pagination;
  dialog?: {
    isOpen: boolean;
    reminder?: Reminder;
  };
}

export interface Values {}

const { PageContextProvider, usePageContext } = createPageContext<Data, Values>(
  {
    dialog: { isOpen: false },
    pagination: {
      page: 0,
      rowsPerPage: 10,
      count: 1,
    },
  },
  {}
);

export { PageContextProvider, usePageContext };
