import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import Datagrid from "../../../components/datagrid";
import Page from "../../../components/page";
import TextField from "../../../components/text-field";
import { useAsset } from "../../../hooks/asset";
import { useSession } from "../../../hooks/session";
import { usePageContext } from "./context";

function AccountField({ initialValue, accountType, genreId }) {
  const [accountValue, setAccountValue] = useState(initialValue);
  const page = usePageContext();
  const hooks = { asset: useAsset() };
  const session = useSession();

  return (
    <TextField
      field={{ value: accountValue }}
      fullWidth
      onChange={(event) => {
        setAccountValue(event.target.value);
      }}
      onBlur={async () => {
        const genres = page.data.genres;
        const editedGenre = genres.find((genre) => genre.genreId === genreId);

        const res = await hooks.asset.updateGenreAccount(session, {
          id: genreId,
          [`${accountType}Account`]: accountValue,
        });
        if (res.errors) return page.notify("error", res.errors[0].message);
        editedGenre[`${accountType}Account`] = accountValue;
        page.setData({ genres });
        page.notify("success", "Conta alterada com sucesso");
      }}
      sx={{
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "transparent",
          },
        },
        "&:hover": {
          backgroundColor: "#f5f5f5",
        },
        "&:hover fieldset": {
          border: "none",
        },
        "& .MuiInputBase-input": {
          textAlign: "right",
        },
      }}
    />
  );
}

export default function GenresPage() {
  const page = usePageContext();
  const hooks = { genres: useAsset() };
  const session = useSession();

  useEffect(() => {
    if (!page.data.genres) (async () => await getGenres())();
  }, []);

  const getGenres = async () => {
    const res = await hooks.genres.fetchGenresAccount(session);
    const genres = res.sort((a, b) => {
      const aCode = parseInt(a.percentage);
      const bCode = parseInt(b.percentage);
      return aCode > bCode ? 1 : bCode > aCode ? -1 : 0;
    });
    page.setData({
      genres,
    });
  };

  const rows = (genres) => {
    return genres.map((genre) => ({
      description: genre.description,
      percentage: `${genre.percentage}%`,
      debitAccount: (
        <AccountField
          initialValue={genre.debitAccount}
          genreId={genre.genreId}
          accountType="debit"
        />
      ),
      creditAccount: (
        <AccountField
          initialValue={genre.creditAccount}
          genreId={genre.genreId}
          accountType="credit"
        />
      ),
    }));
  };

  const columns = [
    {
      header: "Descrição",
      align: "left",
    },
    {
      header: "Depreciação",
      align: "right",
    },
    {
      header: "Conta Débito",
      align: "right",
    },
    {
      header: "Conta Crédito",
      align: "right",
    },
  ];

  return (
    <Page
      context={page.context}
      requiresAuthentication
      showSideBar
      showAppBar
      title="Gêneros"
      breadcrumbs={[
        { description: "Home", link: "/" },
        { description: "Configurações", link: "/settings" },
        { description: "Gêneros", link: "/genres" },
      ]}
    >
      {!page.data.genres ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Datagrid
          dataLenght={page.data.genres.length}
          rows={rows(page.data.genres).map((row) => ({
            cells: Object.values(row),
          }))}
          columns={columns}
        />
      )}
    </Page>
  );
}
