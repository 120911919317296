import {
  ListItem as MuiListItem,
  ListItemProps as MuiListItemProps,
} from "@mui/material";
import { SxProps } from "@mui/material/styles";

export interface ListItemProps {
  id?: string;
  sx?: SxProps;
  children: JSX.Element;
}

function ListItem(props: ListItemProps): React.Attributes {
  const muiListItemProps: MuiListItemProps = {
    disablePadding: true,
  };

  if (props.id) {
    muiListItemProps.id = props.id.toString();
    muiListItemProps.key = props.id;
  }

  if (props.sx)
    muiListItemProps.sx = {
      ...props.sx,
    };

  return <MuiListItem {...muiListItemProps}>{props.children}</MuiListItem>;
}

export { ListItem };
