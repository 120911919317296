import { Add } from "@mui/icons-material";
import { ListItemIcon, ListItemText, TextField, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import Avatar from "../../../../components/avatar";
import { ListItemButton } from "../../../../components/list";
// import TextField from "../../../../components/text-field";
import { Email, usePageContext } from "../context";

interface InviteListProps {
  invite: Email;
}
export default function InviteListButton(props: InviteListProps) {
  const [isInEditMode, setEditMode] = useState(false);
  const [email, setEmail] = useState("");
  const page = usePageContext();

  const theme = useTheme();
  const emailRegex =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi;
  const validateEmail = (emailForValidation: string) =>
    emailRegex.test(emailForValidation);

  const handleFocusOut = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    setEmail(event.target.value);
    setEditMode(false);
    handleSaveEmail(event.target.value);
  };

  const handleSaveEmail = (mail: string) => {
    if (validateEmail(mail) || mail == "") {
      props.invite.to = mail;
      if (mail == "") props.invite.status = "pending";
      page.setData({
        emailList: page.data.emailList!,
      });
    }
    setEmail(!mail ? "" : props.invite.to);
  };
  return (
    <ListItemButton
      sx={{
        border: "2px dotted #ddd",
        borderColor:
          props.invite.status !== "pending"
            ? theme.palette[props.invite.status].light
            : null,
        borderRadius: 1,
        backgroundColor:
          props.invite.status !== "pending"
            ? theme.palette[props.invite.status].light
            : null,
        color: props.invite.status !== "pending" ? "white" : null,
        ":hover": {
          color:
            props.invite.status !== "pending"
              ? theme.palette[props.invite.status].light
              : null,
        },
      }}
      onClick={() => setEditMode(!isInEditMode)}
    >
      {isInEditMode ? (
        <TextField
          onBlur={handleFocusOut}
          fullWidth
          size={"medium"}
          autoFocus
          variant="standard"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{
            disableUnderline: true,
          }}
        />
      ) : (
        <>
          <ListItemIcon>
            <Avatar
              sx={{
                backgroundColor:
                  email !== "" ? theme.palette.primary.light : null,
              }}
            >
              {email !== "" ? email[0].toUpperCase() : <Add />}
            </Avatar>
          </ListItemIcon>
          <ListItemText>{email !== "" ? email : "Convidar"}</ListItemText>
        </>
      )}
    </ListItemButton>
  );
}
