import { CircularProgress, Paper, useTheme } from "@mui/material";
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import AutoComplete from "../../../../components/auto-complete";
import Button from "../../../../components/button";
import Grid from "../../../../components/grid";
import MenuItem from "../../../../components/menu-item";
import Select from "../../../../components/select";
import TextField from "../../../../components/text-field";
import Typography from "../../../../components/typography";
import { useCompany } from "../../../../hooks/company";
import { usePageContext } from "../context";

const SearchField = ({ label, placeholder, size }) => {
  const page = usePageContext();
  const [searchValue, setSearchValue] = useState(page.values.zip_code.value);
  const [searchError, setError] = useState({ error: false, errorMessage: "" });
  const hook = { company: useCompany() };
  useEffect(() => {
    if (searchValue === "") {
      page.setValues({
        zip_code: {
          value: "",
        },
      });
      return setError({
        error: false,
        errorMessage: "",
      });
    }
    if (searchValue !== page.values.zip_code.value) {
      const timeOutId = setTimeout(async () => {
        const addressSearch = await hook.company.fetchAddressData(searchValue);

        if (addressSearch.hasError === true) {
          page.setValues({
            zip_code: {
              value: "",
            },
          });
          return setError({ error: true, errorMessage: addressSearch.message });
        }

        page.setValues({
          zip_code: {
            value: searchValue,
          },
          neighborhood: {
            value: addressSearch.bairro,
          },
          city: {
            value: addressSearch.localidade,
          },
          addressStreet: {
            value: addressSearch.logradouro,
          },
          state: {
            value: addressSearch.uf,
          },
        });
        setError({ error: false, errorMessage: "" });
      }, 500);
      return () => clearTimeout(timeOutId);
    }
  }, [searchValue]);

  const doSearchOnChange = async (event) => {
    setSearchValue(event.target.value);
  };
  return (
    <TextField
      placeholder={placeholder}
      label={label}
      fullWidth
      size={size}
      field={{
        value: searchValue,
        required: true,
        ...searchError,
      }}
      onChange={doSearchOnChange}
      type="search"
    />
  );
};

export default function CreateCompanyStep() {
  const page = usePageContext();
  const theme = useTheme();
  const hooks = { company: useCompany() };
  const [urlParams] = useSearchParams();
  const invitetoken = urlParams.get("invitetoken");
  const tokenInfo = jwtDecode(invitetoken);

  useEffect(() => {
    if (tokenInfo)
      page.setValues({
        companyName: {
          value: tokenInfo.CompanyName,
        },
        federal_document: {
          value: tokenInfo.FederalDocument,
        },
      });
  }, []);

  const isStepSkipped = (step) => page.data.stepper.skipped.has(step);

  const handleNext = () => {
    const { activeStep, skipped } = page.data.stepper;

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    page.setData({
      stepper: {
        activeStep: activeStep + 1,
        skipped: newSkipped,
      },
    });
  };

  const handleBack = () => {
    const { activeStep, skipped } = page.data.stepper;

    page.setData({
      stepper: {
        skipped,
        activeStep: activeStep - 1,
      },
    });
  };

  const createCompany = async () => {
    const address = `${page.values.addressStreet.value}, ${page.values.addressNumber.value}`;
    const res = await hooks.company.createCompany(
      address,
      page.values.city.value,
      page.values.neighborhood.value,
      page.values.phone.value,
      page.values.state.value,
      page.values.zip_code.value,
      page.values.accounting_export.value.value,
      invitetoken
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // return handleNext();
    let isNotValidated = page.values.zip_code.value === "";
    let hasError = false;

    if (isNotValidated) {
      return page.notify("error", "CEP Inválido");
    }
    page.setData({
      isLoading: true,
    });
    try {
      await createCompany();
      page.notify("success", "Empresa criada com sucesso!");
    } catch (error) {
      hasError = true;
      page.notify("error", "Algo deu errado!");
    } finally {
      page.setData({
        isLoading: false,
      });
      if (!hasError) handleNext();
    }
  };

  const handleChange = (e, fieldName) => {
    page.setValues({
      [fieldName]: {
        value: e.target.value,
      },
    });
  };

  const accountingExport = [
    { name: "Gêneros", value: "genre" },
    { name: "Bens", value: "asset" },
  ];

  return (
    <Paper sx={{ p: 3 }}>
      <Grid container columns={2} spacing={2}>
        <Grid item xs={2}>
          <Typography component="h1" variant="h4" sx={{ width: "100%" }}>
            Cadastro de empresa
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <form
            id="newCompanyForm"
            style={{
              width: "100%",
              marginTop: theme.spacing(1),
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 16,
            }}
            onSubmit={handleSubmit}
          >
            <Typography
              component={"h2"}
              variant="subtitle1"
              sx={{ width: "100%" }}
            >
              Finalize o cadastro de sua empresa
            </Typography>

            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12}>
                <Typography component="h1" variant="h6">
                  Dados cadastrais:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="name"
                  name="name"
                  fullWidth
                  margin="none"
                  label="Razão Social"
                  field={page.values.companyName}
                  onChange={(e) => handleChange(e, "companyName")}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  id=""
                  name="cnpj"
                  fullWidth
                  margin="none"
                  label="CNPJ"
                  field={page.values.federal_document}
                  onChange={(e) => handleChange(e, "federal_document")}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <AutoComplete
                  id="accounting_export"
                  label="Tipo de exportação contábil"
                  fullWidth
                  getOptionLabel={(option) => option.name}
                  field={page.values.accounting_export}
                  options={accountingExport}
                  onChange={(_, value) => {
                    page.setValues({
                      accounting_export: {
                        value,
                      },
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  id="phone"
                  name="phone"
                  type="tel"
                  fullWidth
                  margin="none"
                  label="Número de telefone"
                  field={page.values.phone}
                  onChange={(e) => handleChange(e, "phone")}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography component="h1" variant="h6">
                  {page.values.zip_code.value === ""
                    ? "Digite seu CEP:"
                    : "Endereço:"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <SearchField
                  label={"CEP"}
                  placeholder={"Digite o CEP de sua empresa"}
                />
              </Grid>
              {page.values.zip_code.value !== "" && (
                <>
                  <Grid item xs={12} md={6} lg={9}>
                    <TextField
                      id="city"
                      name="city"
                      type="text"
                      fullWidth
                      margin="none"
                      label="Cidade"
                      field={page.values.city}
                      onChange={(e) => handleChange(e, "city")}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <TextField
                      id="state"
                      name="state"
                      type="text"
                      fullWidth
                      margin="none"
                      label="UF"
                      field={page.values.state}
                      onChange={(e) => handleChange(e, "state")}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={9}>
                    <TextField
                      id="address-street"
                      name="address-street"
                      type="text"
                      fullWidth
                      margin="none"
                      label="Rua"
                      field={page.values.addressStreet}
                      onChange={(e) => handleChange(e, "addressStreet")}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <TextField
                      id="address-number"
                      name="address-number"
                      type="text"
                      fullWidth
                      margin="none"
                      label="Número"
                      field={page.values.addressNumber}
                      onChange={(e) => handleChange(e, "addressNumber")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="neighborhood"
                      name="neighborhood"
                      type="text"
                      fullWidth
                      margin="none"
                      label="Bairro"
                      field={page.values.neighborhood}
                      onChange={(e) => handleChange(e, "neighborhood")}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid
              item
              container
              xs={12}
              sx={{ justifyContent: "space-between" }}
            >
              <Grid item>
                <Button
                  type="button"
                  onClick={handleBack}
                  disabled={tokenInfo.UserExist}
                  fullWidth
                  variant="outlined"
                  color="primary"
                >
                  Voltar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={page.data.isLoading}
                >
                  {page.data.isLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Salvar e continuar"
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Paper>
  );
}
