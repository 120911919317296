import { Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/button";
import Grid from "../../../components/grid";
import Typography from "../../../components/typography";
import { useSession } from "../../../hooks/session";
import { useUser } from "../../../hooks/user";

export default function AlreadyLoggedInWithAnotherUserView() {
  const hooks = { user: useUser(), session: useSession() };

  const navigate = useNavigate();

  return (
    <Paper sx={{ p: 3, maxWidth: 500 }}>
      <Grid container columns={2} spacing={3} textAlign="center">
        <Grid item sm={2}>
          <Typography component={"h1"} variant={"h5"}>
            Sessão já iniciada
          </Typography>
        </Grid>
        <Grid item sm={2}>
          <Typography component={"p"} variant={"body1"}>
            Parece que você já possui uma sessão iniciada com o e-mail{" "}
            <b>{hooks.session.account.user.email}</b>. Deseja alterar ou
            permanecer na mesma conta?
          </Typography>
        </Grid>
        <Grid item sm={2} container columns={2} spacing={1}>
          <Grid item xs={1}>
            <Button
              fullWidth
              variant="text"
              color="warning"
              onClick={async () => {
                await hooks.session.signOut();
              }}
            >
              Alterar
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Button
              fullWidth
              onClick={async () => {
                navigate("/");
              }}
            >
              Permanecer
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
