import {
  Add as AddIcon,
  Check as CheckIcon,
  Clear as ClearIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { CircularProgress, IconButton, Paper, useTheme } from "@mui/material";
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Button from "../../../../components/button";
import Grid from "../../../../components/grid";
import List, { ListItem, ListItemText } from "../../../../components/list";
import TextField from "../../../../components/text-field";
import Typography from "../../../../components/typography";
import { useLocation } from "../../../../hooks/location";

import { usePageContext } from "../context";

function Location({ location, level, token }) {
  const theme = useTheme();
  const page = usePageContext();
  const hooks = {
    location: useLocation(),
  };
  const childrens = page.data.locations
    ?.filter((c) => c.parentId === location.id)
    .map((c) => (
      <Location key={c.id} location={c} level={level + 1} token={token} />
    ));

  const marginLeft = level * 4;

  const [temp, setTemp] = useState("");

  const deleteItem = async (item) => {
    const location = page.data.locations.find((i) => i.id === item.id);
    location.status = "deleted";
    if (location.status === "deleted") {
      const res = await hooks.location.deleteItem(location, { token });
      if (res.status === 200) {
        const index = page.data.locations.findIndex((i) => i.id === item.id);
        page.data.locations.splice(index, 1);
      } else {
        page.notify("error", "Não foi possível excluir o local");
      }
    }
    page.setData({
      locations: [...page.data.locations],
    });
  };

  const editItem = async (item) => {
    const location = page.data.locations.find((i) => i.id === item.id);
    location.status = "edited";
    location.view = "editing";
    setTemp(location.description);
    page.setData({
      locations: [...page.data.locations],
    });
  };

  const saveEdit = async (item) => {
    const location = page.data.locations.find((i) => i.id === item.id);

    location.description = temp;
    location.view = "default";

    page.setData({
      locations: [...page.data.locations],
    });

    if (location.status === "new") {
      hooks.location.addItem(location, { token });
    } else {
      hooks.location.saveItem(location, { token });
    }
    page.notify("success", "Salvo com sucesso");
  };

  const cancelEdit = async (item) => {
    const location = page.data.locations.find((i) => i.id === item.id);
    if (location.status === "new") {
      const index = page.data.locations.findIndex((i) => i.id === item.id);
      page.data.locations.splice(index, 1);
    }
    location.view = "default";
    setTemp(location.description);
    page.setData({
      locations: [...page.data.locations],
    });
  };

  const addSubitem = async (location) => {
    page.data.locations.push({
      parentId: location.id,
      description: "",
      status: "new",
      view: "editing",
    });
    setTemp("");
    page.setData({
      locations: [...page.data.locations],
    });
  };

  return (
    <>
      <ListItem
        id="item"
        key={location.id}
        sx={{
          border: "1px solid #dee2e6",
          backgroundColor: "white",
          borderRadius: "5px",
          paddingX: 2,
          maxWidth: `calc(100% - ${theme.spacing(marginLeft)})`,

          marginLeft: theme.spacing(marginLeft),
        }}
      >
        {location.view === "editing" && (
          <>
            <TextField
              id="location"
              name="location"
              field={{ value: temp }}
              onChange={(e) => {
                setTemp(e.target.value);
              }}
            />
            <Grid>
              <IconButton
                edge="end"
                onClick={() => {
                  cancelEdit(location);
                }}
              >
                <ClearIcon />
              </IconButton>
              <IconButton
                edge="end"
                onClick={() => {
                  saveEdit(location);
                }}
              >
                <CheckIcon />
              </IconButton>
            </Grid>
          </>
        )}
        {location.view === "default" && (
          <>
            <ListItemText>{location.description}</ListItemText>
            <Grid>
              <IconButton
                onClick={() => {
                  deleteItem(location);
                }}
                edge="end"
              >
                <ClearIcon />
              </IconButton>
              <IconButton
                edge="end"
                onClick={() => {
                  editItem(location);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                edge="end"
                onClick={async () => {
                  await addSubitem(location);
                }}
              >
                <AddIcon />
              </IconButton>
            </Grid>
          </>
        )}
      </ListItem>
      {childrens}
    </>
  );
}

export default function LocationsStep() {
  const page = usePageContext();
  const theme = useTheme();
  const hooks = { locals: useLocation() };
  const [urlParams] = useSearchParams();
  const invitetoken = urlParams.get("invitetoken");

  useEffect(() => {
    if (!page.data.locations) (async () => await fetchLocations())();
  }, []);

  const fetchLocations = async () => {
    const locations = await hooks.locals.fetchLocations({ token: invitetoken });
    if (locations) {
      page.setData({ locations });
    }
  };

  const isStepSkipped = (step) => page.data.stepper.skipped.has(step);

  const handleNext = () => {
    const { activeStep, skipped } = page.data.stepper;

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    page.setData({
      stepper: {
        activeStep: activeStep + 1,
        skipped: newSkipped,
      },
    });
  };

  const handleBack = () => {
    const { activeStep, skipped } = page.data.stepper;

    page.setData({
      stepper: {
        skipped,
        activeStep: activeStep - 1,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleNext();
  };

  return (
    <Paper sx={{ p: 3 }}>
      <Grid container columns={2} spacing={2}>
        <Grid item xs={2}>
          <Typography component="h1" variant="h4" sx={{ width: "100%" }}>
            Defina os locais iniciais de sua empresa
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <form
            id="newCompanyForm"
            style={{
              width: "100%",
              marginTop: theme.spacing(1),
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
            onSubmit={handleSubmit}
          >
            <Typography component={"h2"} variant="subtitle1">
              Divida suas salas e departamentos da maneira que melhor atenda sua
              realidade, sendo suportado quantos subníveis forem necessários!
              Edite o modelo abaixo ou pule esta etapa caso o mesmo já atenda à
              sua empresa
            </Typography>
            {!page.data.locations ? (
              <CircularProgress />
            ) : (
              <List sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
                {page.data.locations.map((local) => (
                  <Location
                    key={local.id}
                    location={local}
                    level={0}
                    token={invitetoken}
                  />
                ))}
              </List>
            )}
            <Grid item container xs={12} spacing={1}>
              <Grid item>
                <Button
                  type="button"
                  onClick={handleBack}
                  fullWidth
                  variant="outlined"
                  color="primary"
                >
                  Voltar
                </Button>
              </Grid>
              <Grid item sx={{ flex: 1 }}></Grid>

              <Grid item>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={page.data.isLoading}
                >
                  {page.data.isLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Finalizar"
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Paper>
  );
}
