import { createPageContext } from "../../../components/page";
import { Pagination } from "../../../hooks/user/user-hook.types";

export interface Asset {
  id?: string;
  description?: string;
  invoiceDate?: { min: Date; max: Date };
  acquireAmount?: {
    min?: number;
    max?: number;
    hasAcquireAmount: boolean;
  };
  number?: string;
  invoiceNumber?: string;
  dateEndCalculation?: Date;
  location?: string;
  genre?: string;
  calculation?: boolean;
}

export interface CalculationData {
  month: string;
  percentInThisMonth: number;
}

export interface AssetWithCalculation {
  accountingValue: number;
  acquirementDate: Date;
  acquirementValue: number;
  monthlyTaxDepreciation: CalculationData[];
  monthlyAccountingDepreciation: CalculationData[];
  description: string;
  id: string;
}

export interface Dialog {
  isOpen: boolean;
  assetId: string;
}

export interface Data {
  assets?: AssetWithCalculation[];
  pagination?: Pagination;
  searchQueryParams?: Asset;
  months?: string[];
  count?: number;
  calcView: "monthlyAccountingDepreciation" | "accumulatedTaxDepreciation";
  dialogInfo?: Dialog;
}

export interface Values {}

const { PageContextProvider, usePageContext } = createPageContext<Data, Values>(
  {
    pagination: {
      page: 0,
      rowsPerPage: 10,
      count: 1,
    },
    months: [],
    calcView: "monthlyAccountingDepreciation",
    dialogInfo: { isOpen: false, assetId: "" },
    searchQueryParams: {
      acquireAmount: {
        hasAcquireAmount: false,
        max: undefined,
        min: undefined,
      },
    },
  },
  {}
);

export { PageContextProvider, usePageContext };
