import moment from "moment";
import {
  createPageContext,
  createPageField,
  PageField,
} from "../../components/page";
import { Pagination } from "../../hooks/user/user-hook.types";

export interface Asset {
  id?: string;
  description?: string;
  genre?: string;
  debitAccount?: number;
  creditAccount?: number;
  monthYear?: string;
}
export interface Data {
  accountingExport?: Asset[];
  count?: number;
  pagination?: Pagination;
  selectedAssetsIds?: string[];
  allCheckedStatus?: "unchecked" | "checked" | "indeterminate";
}

export interface Values {
  status?: PageField;
  monthYear?: PageField;
  openDialog?: PageField;
  layout?: PageField;
  companyNumber?: PageField;
}

const { PageContextProvider, usePageContext } = createPageContext<Data, Values>(
  {
    selectedAssetsIds: [],
    allCheckedStatus: "unchecked",
    pagination: {
      page: 0,
      rowsPerPage: 10,
      count: 1,
    },
  },
  {
    status: createPageField(
      { name: "Todos", value: "all" },
      { required: true }
    ),
    monthYear: createPageField(
      moment().subtract(1, "months").format("YYYY-MM"),
      { required: true }
    ),
    openDialog: createPageField(false, { required: true }),
    layout: createPageField(
      { label: "Domínio", value: "dominio" },
      { required: true }
    ),
    companyNumber: createPageField("", { required: true }),
  }
);

export { PageContextProvider, usePageContext };
