import {
  Category as CategoryIcon,
  DescriptionOutlined as DescriptionIcon,
  DocumentScannerOutlined as DocumentScannerOutlinedIcon,
  FileDownload as DownloadIcon,
  Edit as EditIcon,
  FilterList as FilterListIcon,
  InsertInvitationOutlined as InsertInvitationOutlinedIcon,
  MoveDownOutlined as MoveDownOutlinedIcon,
  PhotoLibrary as PhotoLibraryIcon,
  PinDrop as PinDropIcon,
  ReceiptOutlined as ReceiptIcon,
  TimelineOutlined as TimelineOutlinedIcon,
} from "@mui/icons-material";
import {
  CircularProgress,
  IconButton as MuiIconButton,
  Tooltip as MuiTooltip,
} from "@mui/material";
import moment from "moment";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import Button from "../../../components/button";
import Datagrid from "../../../components/datagrid";
import Page from "../../../components/page";
import { useAsset } from "../../../hooks/asset";
import { useLocation } from "../../../hooks/location";
import { useSession } from "../../../hooks/session";
import {
  ClearFiltersButton,
  FilterButton,
  PendingActionsButton,
} from "./components/filters-menu";
import { ImportFileModal } from "./components/import-file-modal";
import CreateAssetButton from "./components/new-asset-menu";
import { PicturesAssetGallery } from "./components/pictures-asset-gallery";
import { ReminderAsset } from "./components/reminder-asset";
import { SearchField, SelectSearchField } from "./components/search-field";
import { usePageContext } from "./context";

export default function AssetsPage() {
  const page = usePageContext();
  const hooks = { assets: useAsset(), location: useLocation() };
  const session = useSession();
  const navigate = useNavigate();

  useEffect(() => {
    if (!page.data.locationFilter) (async () => await getLocation())();
    if (!page.data.genreFilter) (async () => await getGenres())();
    if (!page.data.assets || page.data.pagination)
      (async () => await getAssets())();
  }, [page.data.pagination, page.data.searchQueryParams]);

  const getAssets = async () => {
    const { count, asset: assets } = await hooks.assets.fetchAssets(
      session,
      page.data.pagination,
      page.data.searchQueryParams
    );
    page.setData({
      count,
      assets,
    });
  };

  const getLocation = async () => {
    const allLocation = await hooks.location.fetchLocations(session);
    const locationList = allLocation.sort((a, b) => {
      return a > b ? 1 : b > a ? -1 : 0;
    });
    page.setData({
      locationFilter: locationList.map((item) => ({
        description: item.description,
      })),
    });
  };

  const getGenres = async () => {
    const allGenres = await hooks.assets.fetchGenresAccount(session);
    const genreList = allGenres.sort((a, b) => {
      return a > b ? 1 : b > a ? -1 : 0;
    });
    page.setData({
      genreFilter: genreList.map((item) => ({
        description: item.description,
      })),
    });
  };

  const handlePageChange = async (_, newPage) => {
    page.setData({
      pagination: {
        ...page.data.pagination,
        page: newPage,
      },
    });
  };

  const handleChangeRowsPerPage = async (event) => {
    page.setData({
      pagination: {
        page: 0,
        rowsPerPage: event.target.value,
      },
    });
  };

  const rows = (assets) =>
    assets.map((asset) => ({
      rfid: asset.rfid,
      number: asset.number,
      nf: asset.invoiceNumber,
      description: asset.description,
      local: asset.location_description,
      genre: asset.genre,
      actions: asset.acquireAmount ? (
        <>
          <MuiTooltip
            title={
              session.hasAccess({
                key: "putAsset",
                accessIds: session.access,
              })
                ? "Editar ativo"
                : "Sem permissão"
            }
            sx={{ mr: 0.5 }}
          >
            <span style={{ marginRight: 16 }}>
              <MuiIconButton
                edge="end"
                onClick={() => navigate(`./form?id=${asset.id}`)}
                color="primary"
                disabled={
                  !session.hasAccess({
                    key: "putAsset",
                    accessIds: session.access,
                  })
                }
              >
                <EditIcon />
              </MuiIconButton>
            </span>
          </MuiTooltip>
          <MuiTooltip
            title={
              session.hasAccess({
                key: "putAsset",
                accessIds: session.access,
              })
                ? "Ver movimentação"
                : "Sem permissão"
            }
            sx={{ mr: 0.5 }}
          >
            <span style={{ marginRight: 16 }}>
              <MuiIconButton
                edge="end"
                onClick={() => navigate(`./movimentacao?id=${asset.id}`)}
                color="primary"
                disabled={
                  !session.hasAccess({
                    key: "moveAsset",
                    accessIds: session.access,
                  })
                }
              >
                <MoveDownOutlinedIcon />
              </MuiIconButton>
            </span>
          </MuiTooltip>
          <MuiTooltip title="Ver histórico" sx={{ mr: 0.5 }}>
            <MuiIconButton
              edge="end"
              onClick={() => navigate(`./ocorrencias?id=${asset.id}`)}
              color="primary"
            >
              <TimelineOutlinedIcon />
            </MuiIconButton>
          </MuiTooltip>
          <MuiTooltip title="Ver lembrete" sx={{ mr: 0.5 }}>
            <MuiIconButton
              edge="end"
              onClick={async () => {
                const reminder = await hooks.assets.fetchReminder(
                  session,
                  asset.id
                );
                page.setData({
                  isReminderOpen: true,
                  reminder,
                });
              }}
              color="primary"
            >
              <InsertInvitationOutlinedIcon />
            </MuiIconButton>
          </MuiTooltip>
          <MuiTooltip title="Ver imagens" sx={{ mr: 0.5 }}>
            <MuiIconButton
              edge="end"
              onClick={async () => {
                const picturesUrls = await hooks.assets.fetchPicturesUrls(
                  session,
                  asset.id
                );
                page.setData({
                  isGalleryOpen: true,
                  picturesUrls,
                });
              }}
              color="primary"
            >
              <PhotoLibraryIcon />
            </MuiIconButton>
          </MuiTooltip>
        </>
      ) : (
        <>
          <MuiTooltip title="Editar ativo" sx={{ mr: 0.5 }}>
            <div>
              <Button
                type="button"
                variant="text"
                onClick={() => navigate(`./form?id=${asset.id}`)}
                startIcon={<EditIcon />}
                color="warning"
                disabled={
                  !session.hasAccess({
                    key: "putAsset",
                    accessIds: session.access,
                  })
                }
              >
                {" "}
                finalizar cadastro
              </Button>
            </div>
          </MuiTooltip>
        </>
      ),
    }));

  const columns = [
    {
      header: "RFID",
      align: "left",
    },
    {
      header: "Patrimônio",
      align: "left",
    },
    {
      header: "Documento",
      align: "left",
    },
    {
      header: "Descrição",
      align: "left",
    },
    {
      header: "Local",
      align: "left",
    },
    {
      header: "Gênero",
      align: "left",
    },
    {
      header: "Ações",
      align: "center",
      columnSize: 250,
    },
  ];

  const downloadExcel = async () => {
    const { assets } = await hooks.assets.fetchAssetsExport(
      session,
      page.data.searchQueryParams
    );

    const assetsToExport = assets.map((asset) => ({
      "TAG RFID": asset?.rfid,
      Patrimônio: asset?.number,
      Descrição: asset?.description,
      Gênero: asset?.genre,
      "Data de aquisição": moment(asset?.invoiceDate).format("DD/MM/YYYY"),
      "Documento Fiscal": asset?.invoiceNumber,
      "Valor de aquisição": asset?.acquireAmount.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        style: "currency",
        currency: "BRL",
      }),
      "Vida útil/Anos": asset?.yearsToDepreciate,
      "Valor futuro de venda": asset?.futureSellAmount,
      "Conta Crédito": asset?.creditAccount,
      "Conta Débito": asset?.debitAccount,
      Fornecedor: asset?.provider,
      Observação: asset?.observation,
      "Local atual": asset?.location_description,
    }));

    const filename = `${
      session.account.selectedCompany.name
    }_${Date.now()}.xlsx`;
    const worksheet = XLSX.utils.json_to_sheet(assetsToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, filename);
  };

  return (
    <Page
      context={page.context}
      requiresAuthentication
      keyAccess="getAsset"
      showSideBar
      showAppBar
      title="Ativos"
      breadcrumbs={[
        { description: "Home", link: "/" },
        { description: "Ativos", link: "/assets" },
      ]}
      toolbar={[
        <ImportFileModal isOpen={page.data.isModalOpen} />,
        <PicturesAssetGallery isOpen={page.data.isGalleryOpen} />,
        <ReminderAsset isOpen={page.data.isReminderOpen} />,
        <MuiTooltip title="Exportar dados">
          {!page.data.assets ? (
            <div>
              <Button disabled startIcon={<DownloadIcon />}>
                Exportar
              </Button>
            </div>
          ) : (
            <div>
              <Button
                onClick={async () => {
                  await downloadExcel();
                }}
                startIcon={<DownloadIcon />}
                sx={{
                  backgroundColor: "#66bb6a",
                  ":hover": { backgroundColor: "#10783f" },
                }}
              >
                Exportar
              </Button>
            </div>
          )}
        </MuiTooltip>,
        <CreateAssetButton />,
      ]}
    >
      {!page.data.assets ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Datagrid
          tableAppBar={
            <div style={{ padding: 16, display: "flex", gap: 16 }}>
              <SearchField
                iconPosition={"start"}
                initialValue={page.data.searchQueryParams.description}
                queryKey={"description"}
                Icon={<DescriptionIcon />}
                label={"Descrição"}
                placeholder={"Ex: Monitor, computador..."}
                context={page}
              />

              <SearchField
                iconPosition={"start"}
                initialValue={page.data.searchQueryParams.number}
                queryKey={"number"}
                Icon={<DocumentScannerOutlinedIcon />}
                label={"Patrimônio"}
                placeholder={"Número interno de controle da empresa"}
                context={page}
              />

              <SearchField
                iconPosition={"start"}
                initialValue={page.data.searchQueryParams.invoiceNumber}
                queryKey={"invoiceNumber"}
                Icon={<ReceiptIcon />}
                label={"Nota Fiscal"}
                placeholder={"NF em que o ativo foi faturado"}
                context={page}
              />

              <SelectSearchField
                iconPosition={"start"}
                initialValue={page.data.searchQueryParams.location}
                queryKey={"location"}
                Icon={<PinDropIcon />}
                label={"Local"}
                placeholder={"Local"}
                context={page}
                optionsList={page.data.locationFilter ?? []}
              />

              <SelectSearchField
                iconPosition={"start"}
                initialValue={page.data.searchQueryParams.genre}
                queryKey={"genre"}
                Icon={<CategoryIcon />}
                label={"Gênero"}
                placeholder={"Gênero"}
                context={page}
                optionsList={page.data.genreFilter ?? []}
              />

              <div style={{ display: "flex" }}>
                <PendingActionsButton page={page} />
                <FilterButton Icon={FilterListIcon} page={page} />
                <ClearFiltersButton page={page} />
              </div>
            </div>
          }
          dataLenght={page.data.count}
          rows={rows(page.data.assets).map((row) => ({
            cells: Object.values(row),
          }))}
          columns={columns}
          paginationProps={{
            ...page.data.pagination,
            count: page.data.count,
          }}
          handlePageChange={async (event, page) => {
            await handlePageChange(event, page);
          }}
          handleChangeRowsPerPage={async (event) => {
            await handleChangeRowsPerPage(event);
          }}
        />
      )}
    </Page>
  );
}
