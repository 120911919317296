import {
  createPageContext,
  createPageField,
  PageField,
} from "../../components/page";
import { Pagination } from "../../hooks/user/user-hook.types";
import { Location } from "../settings/location/list/context";
import { User } from "../settings/profile/context";

export interface AssetInventory {
  id?: string;
  inventoryId?: string;
  assetId?: string;
  isLocated?: boolean;
  description?: string;
  rfid?: string;
}

export interface Data {
  assetinventory?: AssetInventory[];
  location?: Location[];
  user?: User[];
  count?: number;
  pagination?: Pagination;
  dateList?: string[];
}

export interface Values {
  locationInventory?: PageField;
  dateInventory?: PageField;
}

const { PageContextProvider, usePageContext } = createPageContext<Data, Values>(
  {
    pagination: {
      page: 0,
      rowsPerPage: 10,
      count: 1,
    },
    dateList: [],
  },
  {
    locationInventory: createPageField("", { required: true }),
    dateInventory: createPageField("", { required: true }),
  }
);

export { PageContextProvider, usePageContext };
