import { useParams } from "react-router-dom";
import {
  createPageContext,
  // createPageField,
  // PageField,
} from "../../../../components/page";

export interface Params {
  id?: string;
}

export interface Data {}

export interface Values {}

const { PageContextProvider, usePageContext: _usePageContext } =
  createPageContext<Data, Values>({}, {});

const usePageContext = () => {
  const _params = useParams();
  const params: Params = {
    id: _params.id?.toString() || "",
  };
  const ctx = _usePageContext();

  return {
    ...ctx,
    params,
  };
};

export { PageContextProvider, usePageContext };
