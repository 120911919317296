import Autocomplete from "../../../../components/auto-complete/auto-complete";
import Button from "../../../../components/button/button";
import Grid from "../../../../components/grid/grid";
import TextField from "../../../../components/text-field";
import { useEffect } from "react";
import { useSession } from "../../../../hooks/session";
import { usePageContext } from "../context";
import { useRole } from "../../../../hooks/role";
import { useInvite } from "../../../../hooks/invite";
import { useLocation } from "../../../../hooks/location";
import { Mail as MailIcon } from "@mui/icons-material";
import { useUser } from "../../../../hooks/user";
import { CircularProgress } from "@mui/material";
import Typography from "../../../../components/typography";
import { useCompany } from "../../../../hooks/company";

function InviteCompanyModal() {
  const session = useSession();
  const page = usePageContext();
  const hooks = {
    company: useCompany(),
  };

  const sendInvite = async () => {
    await hooks.company
      .inviteNewCompany(
        page.values.companyName.value,
        page.values.email.value,
        page.values.federalDocument.value,
        session
      )
      .then((res) => {
        if (res.errors)
          return page.notify(
            "error",
            "Falha ao enviar convite: " + res.errors[0].message
          );
        return page.notify("success", "Convite enviado com sucesso");
      });
  };

  function handleClose() {
    page.setValues({
      openDialog: { value: false },
    });
  }

  const clearForm = async () => {
    page.setValues({
      email: {
        value: "",
      },
      roles: {
        value: [],
      },
      locations: {
        value: [],
      },
    });
  };

  return (
    <form
      onSubmit={async (e) => {
        page.setValues({
          isLoading: { value: true },
        });
        e.preventDefault();
        sendInvite()
          .then(() => {
            clearForm();
            handleClose();
          })
          .finally(() =>
            page.setValues({
              isLoading: { value: false },
            })
          );
      }}
    >
      <Grid container spacing={2} columns={2}>
        <Grid item xs={2}>
          <Typography>
            Informe o nome da nova empresa e o endereço de e-mail de seu novo
            responsável, para onde será enviado o link para finalizar o
            cadastro.
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="email"
            name="email"
            label="E-mail"
            fullWidth
            sx={{
              margin: 0,
            }}
            field={page.values.email}
            margin="normal"
            onChange={(e) => {
              page.setValues({
                email: {
                  value: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="companyName"
            name="companyName"
            label="Razão Social"
            fullWidth
            sx={{
              margin: 0,
            }}
            field={page.values.companyName}
            margin="normal"
            onChange={(e) => {
              page.setValues({
                companyName: {
                  value: e.target.value,
                },
              });
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="federalDocument"
            name="federalDocument"
            label="CNPJ"
            fullWidth
            sx={{
              margin: 0,
            }}
            field={page.values.federalDocument}
            margin="normal"
            onChange={(e) => {
              page.setValues({
                federalDocument: {
                  value: e.target.value,
                },
              });
            }}
          />
        </Grid>

        <Grid item xs={1}>
          <Button
            fullWidth
            variant="text"
            color="warning"
            onClick={handleClose}
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Button
            type="submit"
            startIcon={
              page.values.isLoading.value ? (
                <CircularProgress size={24} />
              ) : (
                <MailIcon />
              )
            }
            fullWidth
            variant="contained"
            color="primary"
            disabled={page.values.isLoading.value}
          >
            {page.values.isLoading.value ? "Enviando..." : "Enviar"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default InviteCompanyModal;
