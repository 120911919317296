import { Location } from "../../pages/settings/location/list/context";
import { useFetch } from "../fetch/fetch-hook";
import { Session } from "../session/session-hook.types";

const useLocation = () => {
  const { doFetch } = useFetch();

  const fetchLocations = async (session?: Session) => {
    const data = await doFetch({
      session,
      fetchConfig: {
        method: "GET",
        path: `v2/location`,
      },
    });
    return data;
  };

  const fetchInventoryLocation = async (session?: Session) => {
    const data = await doFetch({
      session,
      fetchConfig: {
        method: "GET",
        path: `v2/inventory-location`,
      },
    });
    return data;
  };

  const addItem = async (location: Location, session?: Session) => {
    const data = await doFetch({
      session,
      fetchConfig: {
        body: JSON.stringify(location),
        method: "POST",
        path: `v2/location`,
      },
    });

    return data;
  };
  const saveItem = async (location: Location, session?: Session) => {
    const data = await doFetch({
      session,
      fetchConfig: {
        body: JSON.stringify(location),
        method: "PUT",
        path: `v2/location/${location.id}`,
      },
    });
    return data;
  };

  const deleteItem = async (location: Location, session?: Session) => {
    const data = await doFetch({
      session,
      fetchConfig: {
        method: "DELETE",
        path: `v2/location/${location.id}`,
      },
    });
    return data;
  };

  return {
    fetchLocations,
    fetchInventoryLocation,
    addItem,
    saveItem,
    deleteItem,
  };
};

export { useLocation };
