import { CircularProgress, Paper, useTheme } from "@mui/material";
import jwtDecode from "jwt-decode";
import { useSearchParams } from "react-router-dom";
import Button from "../../../../components/button";
import Grid from "../../../../components/grid";
import List from "../../../../components/list";
import Typography from "../../../../components/typography";
import { useInvite } from "../../../../hooks/invite";
import InviteListButton from "../components/invite_list_button";
import { usePageContext } from "../context";

export default function InviteUsersStep() {
  const page = usePageContext();
  const theme = useTheme();
  const hooks = { invite: useInvite() };

  const [urlParams] = useSearchParams();
  const invitetoken = urlParams.get("invitetoken");
  const tokenInfo = jwtDecode(invitetoken);

  const isStepSkipped = (step) => page.data.stepper.skipped.has(step);

  const handleNext = () => {
    const { activeStep, skipped } = page.data.stepper;

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    page.setData({
      stepper: {
        activeStep: activeStep + 1,
        skipped: newSkipped,
      },
    });
  };
  const isStepOptional = (step) => step > 1;

  const sendEmail = async (to) => {
    const { Roles: roles, Locations: locations } = tokenInfo;
    const emailIndex = page.data.emailList.findIndex(
      (email) => email.to == to && email.to !== ""
    );
    const invite = page.data.emailList[emailIndex];

    if (invite)
      await hooks.invite
        .fetchInvite(
          {
            email: to,
            roles,
            locations,
          },
          {
            token: invitetoken,
          }
        )
        .then((data) => {
          if (data.errors) {
            page.notify("error", `${data.errors[0].message} (${to})`);
            invite.status = "error";
            return;
          }
          invite.status = "success";
        })
        .catch((err) => {
          page.notify("error", `Envio falhou para ${to}`);
          invite.status = "error";
        })
        .finally(() => page.setData({ emailList: page.data.emailList }));
  };

  const handleSkip = () => {
    const { activeStep, skipped } = page.data.stepper;

    if (!isStepOptional(activeStep)) {
      return page.notify("error", "Você não pode pular uma etapa obrigatória");
    }

    const skip = (prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(page.data.stepper.activeStep);
      return newSkipped;
    };
    page.setData({
      stepper: {
        activeStep: activeStep + 1,
        skipped: skip(skipped),
      },
    });
  };

  const handleBack = () => {
    const { activeStep, skipped } = page.data.stepper;

    page.setData({
      stepper: {
        skipped,
        activeStep: activeStep - 1,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isEmailListEmpty =
      page.data.emailList.filter((email) => email.to !== "").length === 0;
    const hasEmailsWithErroBeforeSend =
      page.data.emailList.findIndex((email) => email.status === "error") >= 0;
    let isNotValidated = isEmailListEmpty || hasEmailsWithErroBeforeSend;

    if (isNotValidated) {
      return page.notify(
        "error",
        isEmailListEmpty
          ? "Adicione ao menos 1 e-mail para convite, ou pule esta etapa"
          : "Corriga os e-mails com erro antes de tentar enviar novamente"
      );
    }
    page.setData({
      isLoading: true,
    });
    try {
      for (const email of page.data.emailList) {
        if (email.status === "pending") await sendEmail(email.to);
      }
      page.notify("success", "Convites enviados com sucesso!");
    } catch (error) {
      page.notify("error", "Algo deu errado!");
    } finally {
      const hasError =
        page.data.emailList.findIndex((email) => email.status === "error") >= 0;
      page.setData({
        isLoading: false,
      });

      if (!hasError) handleNext();
    }
  };

  return (
    <Paper sx={{ p: 3 }}>
      <Grid container columns={2} spacing={2}>
        <Grid item xs={2}>
          <Typography component="h1" variant="h4" sx={{ width: "100%" }}>
            Convide seus colaboradores!
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <form
            id="newCompanyForm"
            style={{
              width: "100%",
              marginTop: theme.spacing(1),
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
            onSubmit={handleSubmit}
          >
            <Typography component={"h2"} variant="subtitle1">
              Convide 3 de seus colaboradores que terão os mesmos acessos que
              você, ou pule essa etapa para finalizar o cadastro posteriormente
              dentro do sistema
            </Typography>
            <List sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
              <InviteListButton invite={page.data.emailList[0]} />
              <InviteListButton invite={page.data.emailList[1]} />
              <InviteListButton invite={page.data.emailList[2]} />
            </List>
            <Grid item container xs={12} spacing={1}>
              <Grid item>
                <Button
                  type="button"
                  onClick={handleBack}
                  fullWidth
                  variant="outlined"
                  color="primary"
                >
                  Voltar
                </Button>
              </Grid>
              <Grid item sx={{ flex: 1 }}></Grid>
              <Grid item>
                <Button
                  type="button"
                  onClick={handleSkip}
                  fullWidth
                  variant="outlined"
                  color="primary"
                  disabled={page.data.isLoading}
                >
                  Pular
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={page.data.isLoading}
                >
                  {page.data.isLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Enviar e continuar"
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Paper>
  );
}
