import jwt from "jwt-decode";
import { createContext, useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../config";
import { Session, SignInProps, VerifyAccessProps } from "./session-hook.types";

const SessionContext = createContext<any>({});

const useSession = () => {
  const navigate = useNavigate();
  const [state, setState] = useContext(SessionContext);

  const session: Session = state;

  const setSession = useCallback(
    (
      props: Session = {
        isAuthenticated: false,
        access: [],
      }
    ) => {
      setState((current: any) => ({ ...current, ...props }));
    },
    []
  );

  const signIn = useCallback(async (props: SignInProps) => {
    const { email, password } = props;
    if (!email || !password) throw new Error("TODO");
    const res = await fetch(`${config.api.url}/v2/auth`, {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });

    const initialData = await res.json();
    let initialToken;
    if (initialData.Token) {
      initialToken = initialData.Token;
    }

    const tokenInfo: any = jwt(initialToken);

    const session = {
      isAuthenticated: true,
      access: [],
      token: initialToken,
      expiresAt: tokenInfo.ExpiresAt,
      iat: tokenInfo.iat,
      account: {
        id: tokenInfo.CompanyId,
        user: {
          id: tokenInfo.UserId,
          email: email,
          name: tokenInfo.UserName,
          scope: tokenInfo.Scope,
        },
      },
    };

    setSession(session);

    return session;
  }, []);

  const signOut = useCallback(async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("access");
    setSession({
      isAuthenticated: false,
      access: [],
      token: null,
      iat: null,
      expiresAt: null,
      account: {
        id: null,
        user: {
          id: null,
          name: "",
          email: "",
          scope: "",
        },
        selectedCompany: {
          id: "",
          name: "",
          cnpj: "",
        },
      },
    });
    navigate("/signin");
  }, []);

  const selectCompany = useCallback(async (companyId: string) => {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", `Bearer ${session?.token || ""}`);
    const res = await fetch(`${config.api.url}/v2/auth/company/${companyId}`, {
      method: "POST",
      headers: requestHeaders,
      body: JSON.stringify({}),
    });
    const data = await res.json();
    let token;
    if (data.refreshToken) {
      token = data.refreshToken;
    }
    const tokenInfo: any = jwt(token);
    localStorage.setItem("access", JSON.stringify(data.access));
    setSession({
      isAuthenticated: true,
      access: data.access.access,
      token,
      iat: tokenInfo.iat,
      expiresAt: tokenInfo.ExpiresAt,
      account: {
        id: tokenInfo.CompanyId,
        user: {
          id: tokenInfo.UserId,
          email: tokenInfo.UserEmail,
          name: tokenInfo.UserName,
          scope: tokenInfo.Scope,
        },
        selectedCompany: {
          id: tokenInfo.CompanyId,
          name: tokenInfo.CompanyName,
          cnpj: tokenInfo.CompanyCnpj,
          accountingExport: tokenInfo.accountingExport,
        },
      },
    });

    localStorage.setItem("token", token);

    navigate("/");
  }, []);

  const sendForgotPasswordEmail = useCallback(
    async (signInProps: SignInProps) => {
      const { email } = signInProps;
      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.set("Content-Type", "application/json");

      const res = await fetch(
        `${config.api.url}/v2/user/send-email-change-password`,
        {
          method: "POST",
          headers: requestHeaders,
          body: JSON.stringify({
            email,
          }),
        }
      );
      return res;
    },
    []
  );

  const hasAccess: (props: VerifyAccessProps) => boolean | undefined = (
    props: VerifyAccessProps
  ) => {
    const { accessIds } = props;
    const key = props.key!;
    const routeAccess: any = {
      getAsset: "28dd9e5b-697d-413a-abcc-d860fdf59ef3", //Ativos - Visualizar
      postAsset: "76df2b87-4f1c-47ca-9a94-6836f9bfb416", //Ativos - Cadastrar
      putAsset: "32d18d97-0c84-4508-a92c-cee0ebe87f77", //Ativos - Editar
      moveAsset: "e85e2a81-68b5-43ce-8427-5c44919474cb", //Ativos - Movimentação
      menuCalculation: "8f15ef1a-8dcc-400e-8eac-1cc937454790", //Menu - Cálculo
      getCalculation: "e00b8bb0-f229-4ff5-ab3e-73ac2cc87f80", //Cálculo - Visualizar
      getLocations: "42ae6012-aa1f-4b9d-8f41-c99dc4d732ce", //Locais - Visualizar
      postLocation: "bc6a53d6-0eeb-4ea3-9e58-8d5555a44d11", //Locais - Cadastrar
      putLocation: "7fb8b06e-c2d1-495c-accf-a0b3803730ec", //Locais - Editar
      deleteLocation: "57fb8245-a7b8-4d01-ae8f-c91516e7f49c", //Locais - Remover
      getCompanies: "ce5f05a4-0bcb-4bd7-90ff-b2cfb581cda3", //Empresas - Visualizar
      inviteCompany: "20fb4c28-6b7e-413c-ace4-28b7ab21f4bb", //Empresas - Convidar
      getRoles: "5e42ed99-c39e-44b1-97f9-729a314a8073", //Perfis - Visualizar
      postRole: "99103ec6-b54e-486b-bee1-c8d359d07058", //Perfis - Cadastrar
      putRole: "c0e75a67-11d8-4b19-b353-1fc15692d249", //Perfis - Editar
      deleteRole: "f72d16a0-ce6e-4dbf-b8b2-74ba95de35e5", //Perfis - Remover
      getUsers: "506c6bc1-8d3f-4055-8c3c-37f4c20f266c", //Usuários - Visualizar
      putUser: "1e9d62d0-1ce7-488a-b328-60f46e3dbbaa", //Usuários - Editar
      inviteUser: "de9fed57-51a5-4d88-934f-7c842e16fecb", //Usuários - Convidar
      menuConfig: "9d91ae1f-86bf-41f2-bceb-bf48463be3bb", //Menu - Configuração
      menuHome: "e26ad965-62cb-4268-a3dc-2e0abd624f54", //Menu - Home
      menuAssets: "fe4222c2-f575-4523-983b-6fcf340ddf3a", //Menu - Ativos
      menuInventory: "21c72a71-af20-49fc-a871-675206c88de3", //Menu - Inventário
      getInventory: "c5486c1f-b55c-4a25-8f0d-7eebcdef53fd", //Inventário - Visualizar
      dashAssetsCount: "5627ed96-f8f8-4c8b-a403-c3e03fe63e4a", //Dash - Contagem de Ativos
      dashAssetsPendingCount: "57289697-3b4a-41db-ab8e-35fa438a5abb", //Dash - Ativos Pendentes
      dashDepreciation: "6b5a92a1-e6f7-4796-a889-81df53478d27", //Dash - Depreciação
      dashAssetsValue: "2256a168-088c-4c69-9f20-af8901d21a8a", //Dash - Valor do patrimônio
      dashAssetsValueChart: "a47305fc-7a1a-46d3-b27c-1417a4fe469c", //Dash - Valor do patrimônio (Gráfico)
      dashAssetsLocation: "b50a9b04-dcf0-4007-9e37-de0d340f6896", //Dash - Ativos por local
      dashAssetsGenre: "9edf57ba-3eef-4472-ac56-88794ffa73b6", //Dash - Ativos por genêro
      exportCont: "d1e533ee-49df-4a76-9404-1ce6ce7e0ebe", //Menu - Exportação
      inventory: "c19c7efd-da3c-4001-9e4f-8d44fd6ae481", //Menu - Inventário
    };

    const accessId = routeAccess[key];
    if (!accessId) return;

    return accessIds.indexOf(accessId) === -1 ? false : true;
  };

  const handleAccessValidation = (props: VerifyAccessProps) => {
    const { key } = props;

    if (!key) return;

    if (!hasAccess(props)) return navigate("/unauthorized");
  };

  return {
    ...session,
    setSession,
    signIn,
    signOut,
    selectCompany,
    sendForgotPasswordEmail,
    handleAccessValidation,
    hasAccess,
  };
};

const SessionContextProvider = ({ children }: { children: JSX.Element }) => {
  let sessionFromCookie = null;
  const tokenFromCookie = localStorage.getItem("token");
  const accessFromCookie = JSON.parse(localStorage.getItem("access") || "[]");
  if (tokenFromCookie) {
    const tokenInfo: any = jwt(tokenFromCookie);
    sessionFromCookie = {
      isAuthenticated: true,
      token: tokenFromCookie,
      access: accessFromCookie.access,
      iat: tokenInfo.iat,
      expiresAt: tokenInfo.ExpiresAt,
      account: {
        id: tokenInfo.CompanyId,
        user: {
          id: tokenInfo.UserId,
          email: tokenInfo.UserEmail,
          name: tokenInfo.UserName,
          scope: tokenInfo.Scope,
        },
        selectedCompany: {
          id: tokenInfo.CompanyId,
          name: tokenInfo.CompanyName,
          cnpj: tokenInfo.CompanyCnpj,
          accountingExport: tokenInfo.accountingExport,
        },
      },
    };
  }

  const [state, setState] = useState(
    sessionFromCookie || {
      token: null,
      iat: null,
      expiresAt: null,
      account: {
        id: null,
        user: {
          id: null,
          name: "",
          email: "",
          scope: "",
        },
        selectedCompany: {
          id: null,
          name: "",
          cnpj: "",
        },
      },
    }
  );
  return (
    <SessionContext.Provider value={[state, setState]}>
      {children}
    </SessionContext.Provider>
  );
};

export { SessionContextProvider, useSession };
