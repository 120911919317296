import config from "../../config";
import { useCallback } from "react";
import { Role } from "../../pages/settings/role/context";
import { Session } from "../session/session-hook.types";

const useRole = () => {
  const fetchRoles = useCallback(async (session?: Session) => {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", `Bearer ${session?.token || ""}`);
    const res = await fetch(`${config.api.url}/v2/roles`, {
      method: "GET",
      headers: requestHeaders,
    });
    const data = await res.json();
    return data;
  }, []);

  const addItem = useCallback(async (role: Role, session?: Session) => {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", `Bearer ${session?.token || ""}`);

    const res = await fetch(`${config.api.url}/v2/roles`, {
      method: "POST",
      headers: requestHeaders,
      body: JSON.stringify({
        id: role.id,
        name: role.name,
        // status: "new",
        view: "default",
      }),
    });
    const data = res.json();
    return data;
  }, []);

  const saveItem = useCallback(async (role: Role, session?: Session) => {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", `Bearer ${session?.token || ""}`);

    const res = await fetch(`${config.api.url}/v2/roles/${role.id}`, {
      method: "PUT",
      headers: requestHeaders,
      body: JSON.stringify({
        name: role.name,
        view: role.view,
      }),
    });
    const data = res.json();
    return data;
  }, []);

  const deleteItem = useCallback(async (role: Role, session?: Session) => {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", `Bearer ${session?.token || ""}`);

    const res = await fetch(`${config.api.url}/v2/roles/${role.id}`, {
      method: "DELETE",
      headers: requestHeaders,
    });

    return res;
  }, []);

  return { fetchRoles, addItem, saveItem, deleteItem };
};

export { useRole };
