import { useFetch } from "../fetch/fetch-hook";
import { Session } from "../session/session-hook.types";
import { Pagination } from "../user/user-hook.types";

const useInventory = () => {
  const { doFetch } = useFetch();

  const fetchDatesInventory = async (
    session?: Session,
    locationId?: string
  ) => {
    const data = await doFetch({
      session,
      fetchConfig: {
        method: "GET",
        path: `v2/assetinventory/${locationId}`,
      },
    });
    return data;
  };

  const fetchAssetsInventory = async (
    session?: Session,
    pagination?: Pagination,
    locationId?: string,
    dateInventory?: string
  ) => {
    const data = await doFetch({
      session,
      fetchConfig: {
        method: "GET",
        pagination,
        path: `v2/assetinventory`,
        query: `locationId=${locationId}&dateInventory=${dateInventory}`,
      },
    });
    return data;
  };

  return { fetchDatesInventory, fetchAssetsInventory };
};

export { useInventory };
