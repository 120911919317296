import { Reminder } from "../../pages/recurring-reminder/recurring-reminder-form/context";
import { useFetch } from "../fetch/fetch-hook";
import { Session } from "../session/session-hook.types";
import { Pagination } from "../user/user-hook.types";

const useReminder = () => {
  const { doFetch } = useFetch();

  const createReminder = async (session?: Session, reminder?: Reminder) => {
    const data = await doFetch({
      session,
      fetchConfig: {
        body: JSON.stringify(reminder),
        method: "POST",
        path: `v2/reminder`,
      },
    });
    return data;
  };

  const fetchReminder = async (
    session?: Session,
    pagination?: Pagination,
    searchQuery: Partial<Reminder> = {}
  ) => {
    const { descriptionReminder } = searchQuery || {};

    const data = await doFetch({
      session,
      fetchConfig: {
        method: "GET",
        pagination,
        path: `v2/reminder`,
        query: `descriptionReminder=${descriptionReminder || ""}`,
      },
    });
    return data;
  };

  const fetchSingleReminder = async (
    session?: Session,
    searchQuery: Partial<Reminder> = {}
  ) => {
    const { id } = searchQuery;
    const data = await doFetch({
      session,
      fetchConfig: {
        method: "GET",
        path: `v2/reminder/${id}`,
      },
    });

    return data;
  };

  const fetchReminderExport = async (
    session?: Session,
    reminderId?: string
  ) => {
    const data = await doFetch({
      session,
      fetchConfig: {
        method: "GET",
        path: `v2/reminder-export/${reminderId}`,
      },
    });
    return data;
  };

  const updateReminder = async (
    session?: Session,
    searchQuery: Partial<Reminder> = {},
    reminder?: Reminder
  ) => {
    const { id } = searchQuery;
    const data = await doFetch({
      session,
      fetchConfig: {
        body: JSON.stringify(reminder),
        method: "PUT",
        path: `v2/reminder/${id}`,
      },
    });

    return data;
  };

  const deleteReminder = async (reminder?: Reminder, session?: Session) => {
    const data = await doFetch({
      session,
      fetchConfig: {
        method: "DELETE",
        path: `v2/reminder/${reminder}`,
      },
    });
    return data;
  };

  return {
    createReminder,
    fetchReminder,
    fetchSingleReminder,
    fetchReminderExport,
    updateReminder,
    deleteReminder,
  };
};

export { useReminder };
