import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import moment from "moment";
import { useState } from "react";
import Button from "../../../components/button";
import Grid from "../../../components/grid";
import TextField from "../../../components/text-field";
import Typography from "../../../components/typography";
import { useSession } from "../../../hooks/session";
import { usePageContext } from "../context";

function CompanyNumberField(props) {
  const page = usePageContext();

  let maxLength;
  let label;
  if (props.layout === "office") {
    maxLength = 4;
    label = "Código da empresa:";
  }
  if (props.layout === "mgc") {
    maxLength = 5;
    label = "Número sequencial:";
  }

  return (
    <Grid
      container
      spacing={2}
      sx={{
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid item>
        <Typography>{label}</Typography>
      </Grid>
      <Grid item>
        <TextField
          size="small"
          field={page.values.companyNumber}
          onChange={(event) => {
            if (event.target.value.length <= maxLength)
              page.setValues({
                companyNumber: { value: event.target.value },
              });
          }}
        />
      </Grid>
    </Grid>
  );
}

export default function LayoutExportPage() {
  const page = usePageContext();
  const session = useSession();

  const [value, setValue] = useState("dominio");

  function handleClose() {
    page.setValues({
      openDialog: { value: false },
    });
  }

  const cnpj = session.account.selectedCompany.cnpj.replace(/\D+/gm, "");

  const createArray = (length, typeFill, assignValues) => {
    let array;

    array = new Array(length).fill(typeFill);

    if (assignValues)
      Object.assign(array, [...assignValues.toString()]).splice(
        length,
        assignValues.length
      );

    return array;
  };

  const renderTxt = {
    dominio: (assets) => {
      let txtDominio = `|0000|${cnpj}\n`;
      let assetMonthYear = "";

      for (const asset of assets) {
        if (!page.data.selectedAssetsIds.includes(asset.id)) continue;

        if (asset.monthlyAccountingDepreciation[0].month) {
          assetMonthYear =
            asset.monthlyAccountingDepreciation[0].month.split("-");
          assetMonthYear = assetMonthYear[1] + assetMonthYear[0];
        }

        const lote6000 = `|6000|X||||\n`;
        const lote6100 = `|6100|${moment().format("DD/MM/YYYY")}|${
          asset.debitAccount
        }|${
          asset.creditAccount
        }|${asset.monthlyAccountingDepreciation[0].resultAccountingDepreciation.replace(
          ".",
          ","
        )}||Depreciação ${moment(
          asset.monthlyAccountingDepreciation[0].month
        ).format("MM/YYYY")} - ${asset.description}||||\n`;
        txtDominio += lote6000 + lote6100;
      }

      const fileName = `${cnpj}_${assetMonthYear}.txt`;

      return { txt: txtDominio, fileName };
    },
    office: (assets) => {
      let txtOffice = "";
      const arrayTxt = [];

      for (const asset of assets) {
        if (!page.data.selectedAssetsIds.includes(asset.id)) continue;

        let date = moment().format("DD/MM/YYYY");
        let complement =
          "Depreciação " +
          moment(
            new Date(asset.monthlyAccountingDepreciation[0].month + "-02")
          ).format("MM/YYYY") +
          " - " +
          asset.description;
        const percentInThisMonth =
          asset.monthlyAccountingDepreciation[0].resultAccountingDepreciation.replace(
            /\D+/gm,
            ""
          );

        arrayTxt.push(
          { length: 5, typeFill: " ", assignValues: "", type: "alphanumeric" },
          {
            length: 18,
            typeFill: " ",
            assignValues: asset.debitAccount,
            type: "alphanumeric",
          },
          {
            length: 18,
            typeFill: " ",
            assignValues: asset.creditAccount,
            type: "alphanumeric",
          },
          { length: 4, typeFill: " ", assignValues: "", type: "alphanumeric" },
          { length: 1, typeFill: " ", assignValues: "", type: "alphanumeric" },
          {
            length: 12,
            typeFill: "0",
            assignValues: percentInThisMonth,
            type: "numeric",
          },
          {
            length: 10,
            typeFill: " ",
            assignValues: date,
            type: "alphanumeric",
          },
          { length: 6, typeFill: " ", assignValues: "", type: "alphanumeric" },
          {
            length: 143,
            typeFill: " ",
            assignValues: complement,
            type: "alphanumeric",
          },
          { length: 20, typeFill: " ", assignValues: "", type: "alphanumeric" },
          {
            length: 20,
            typeFill: " ",
            assignValues: "8",
            type: "alphanumeric",
          },
          { length: 20, typeFill: " ", assignValues: "", type: "alphanumeric" },
          { length: 15, typeFill: "0", assignValues: "", type: "numeric" },
          { length: 20, typeFill: " ", assignValues: "", type: "alphanumeric" },
          { length: 15, typeFill: "0", assignValues: "", type: "numeric" },
          { length: 1, typeFill: " ", assignValues: "N", type: "alphanumeric" },
          { length: 4, typeFill: " ", assignValues: "", type: "alphanumeric" },
          { length: 10, typeFill: " ", assignValues: "", type: "alphanumeric" },
          { length: 1, typeFill: "", assignValues: "\n", type: "alphanumeric" }
        );
      }

      const createArrayOffice = ({ length, typeFill, assignValues, type }) => {
        if (assignValues === "\n") return assignValues;

        if (type === "alphanumeric") {
          return assignValues.padStart(length, typeFill);
        }
        return assignValues.padEnd(length, typeFill);
      };

      for (let txt of arrayTxt) {
        txtOffice += createArrayOffice({ ...txt });
      }

      const companyId = session.account.selectedCompany.id.substring(0, 4);
      const companyNumber = page.values.companyNumber.value;
      const month =
        assets[0].monthlyAccountingDepreciation[0].month.split("-")[1];

      const fileName = `FI${companyId}${companyNumber}.${month}`;

      return { txt: txtOffice, fileName };
    },
    mgc: (assets) => {
      let txtMgc = "";
      const arrayHeader = [];
      const arrayTxt = [];

      const date = moment().format("DDMMYYYY");
      const sequentialNumber = page.values.companyNumber.value;
      const totalAssets = page.data.selectedAssetsIds.length;

      arrayHeader.push(
        { length: 1, typeFill: "", assignValues: "C" },
        { length: 1, typeFill: "", assignValues: "M" },
        { length: 8, typeFill: "", assignValues: date },
        { length: 15, typeFill: "0", assignValues: totalAssets },
        { length: 20, typeFill: "", assignValues: sequentialNumber },
        { length: 3, typeFill: "", assignValues: "OUT" },
        { length: 10, typeFill: "0", assignValues: sequentialNumber },
        { length: 1, typeFill: "", assignValues: "L" },
        { length: 14, typeFill: "", assignValues: cnpj },
        { length: 22, typeFill: " ", assignValues: "" },
        { length: 5, typeFill: "", assignValues: "00001" },
        { length: 1, typeFill: "", assignValues: "\n" }
      );

      for (const [index, asset] of assets.entries()) {
        if (!page.data.selectedAssetsIds.includes(asset.id)) continue;

        let complement =
          "Depreciação " +
          moment(
            new Date(asset.monthlyAccountingDepreciation[0].month + "-02")
          ).format("MM/YYYY") +
          " - " +
          asset.description;
        let percentInThisMonth =
          asset.monthlyAccountingDepreciation[0].resultAccountingDepreciation.replace(
            /\D+/gm,
            ""
          );
        const sequential = index + 1;

        arrayTxt.push(
          { length: 1, typeFill: "", assignValues: "L" },
          { length: 8, typeFill: "", assignValues: date },
          { length: 6, typeFill: "", assignValues: asset.debitAccount },
          { length: 6, typeFill: "", assignValues: asset.creditAccount },
          { length: 3, typeFill: "0", assignValues: "" },
          { length: 25, typeFill: "", assignValues: complement },
          { length: 15, typeFill: "", assignValues: percentInThisMonth },
          { length: 3, typeFill: "0", assignValues: "" },
          { length: 14, typeFill: "0", assignValues: "" },
          { length: 14, typeFill: "0", assignValues: "" },
          { length: 5, typeFill: "0", assignValues: sequential },
          { length: 1, typeFill: "", assignValues: "|" },
          { length: 90, typeFill: " ", assignValues: "" },
          { length: 6, typeFill: "0", assignValues: "" },
          { length: 2, typeFill: " ", assignValues: "" },
          { length: 1, typeFill: "", assignValues: "|" },
          { length: 32, typeFill: " ", assignValues: "" },
          { length: 300, typeFill: " ", assignValues: "" },
          { length: 4, typeFill: "0", assignValues: "" },
          { length: 3, typeFill: "0", assignValues: "" },
          { length: 1, typeFill: "0", assignValues: "" },
          { length: 1, typeFill: "0", assignValues: "" },
          { length: 18, typeFill: "", assignValues: cnpj },
          { length: 20, typeFill: " ", assignValues: "" }, //nro documento
          { length: 1, typeFill: "", assignValues: "\n" }
        );
      }

      for (let txt of arrayHeader) {
        txtMgc += createArray(txt.length, txt.typeFill, txt.assignValues).join(
          ""
        );
      }

      for (let txt of arrayTxt) {
        txtMgc += createArray(txt.length, txt.typeFill, txt.assignValues).join(
          ""
        );
      }

      const fileName = `LOTD${sequentialNumber}_${cnpj}.txt`;

      return { txt: txtMgc, fileName };
    },
    qyon: (assets) => {
      let txtQyon = "";
      const arrayTxt = [];
      let assetMonthYear = "";

      for (const [index, asset] of assets.entries()) {
        if (!page.data.selectedAssetsIds.includes(asset.id)) continue;

        assetMonthYear =
          asset.monthlyAccountingDepreciation[0].month.split("-");
        assetMonthYear = assetMonthYear[1] + assetMonthYear[0];

        const sequential = index + 1;
        let date = moment().format("DD/MM/YYYY");
        const percentInThisMonth =
          asset.monthlyAccountingDepreciation[0].resultAccountingDepreciation.replace(
            ".",
            ","
          );
        let complement =
          "Depreciação " +
          moment(
            new Date(asset.monthlyAccountingDepreciation[0].month + "-02")
          ).format("MM/YYYY") +
          " - " +
          asset.description;

        arrayTxt.push([
          { length: 10, typeFill: "", assignValues: sequential },
          { length: 10, typeFill: "", assignValues: date },
          { length: 45, typeFill: "", assignValues: asset.debitAccount },
          { length: 45, typeFill: "", assignValues: asset.creditAccount },
          { length: 19, typeFill: "", assignValues: percentInThisMonth },
          { length: 4, typeFill: "", assignValues: "" },
          { length: 255, typeFill: "", assignValues: complement },
          { length: 1, typeFill: "", assignValues: 9 },
          { length: 1, typeFill: "", assignValues: "\n" },
        ]);
      }

      for (const array of arrayTxt) {
        for (const [index, txt] of array.entries()) {
          txtQyon += createArray(
            txt.length,
            txt.typeFill,
            txt.assignValues
          ).join("");
          if (index <= array.length - 3) txtQyon += ";";
        }
      }

      const fileName = `${cnpj}_${assetMonthYear}.txt`;

      return { txt: txtQyon, fileName };
    },
  };

  const handleClick = async () => {
    const layout = value;
    const fnRenderTxt = renderTxt[layout];
    const { txt, fileName } = fnRenderTxt(page.data.accountingExport);

    const element = document.createElement("a");
    const file = new Blob([txt], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `${fileName}`;
    document.body.appendChild(element);
    element.click();
  };

  const layout = [
    {
      label: "Domínio",
      value: "dominio",
    },
    { label: "IOB - MGC", value: "mgc" },
    { label: "IOB - Office", value: "office" },
    { label: "Qyon", value: "qyon" },
  ];

  return (
    <Grid container spacing={3} columns={2}>
      <Grid item xs={2}>
        <RadioGroup
          defaultValue={"dominio"}
          sx={{ justifyContent: "space-around" }}
          name="radio-buttons-group"
          onChange={(event) => {
            setValue(event.target.value);
            page.setValues({ companyNumber: { value: "" } });
          }}
        >
          {layout.map((element) => (
            <FormControlLabel
              key={element.value}
              value={element.value}
              control={<Radio />}
              label={element.label}
            />
          ))}
        </RadioGroup>
      </Grid>
      <Grid item xs={2}>
        {value !== "dominio" && value !== "qyon" && (
          <CompanyNumberField layout={value} />
        )}
      </Grid>
      <Grid item xs={1}>
        <Button fullWidth variant="text" color="warning" onClick={handleClose}>
          Cancelar
        </Button>
      </Grid>
      <Grid item xs={1}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleClick}
        >
          Exportar
        </Button>
      </Grid>
    </Grid>
  );
}
