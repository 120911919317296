/* eslint-disable react/prop-types */
import { WebAssetOffOutlined } from "@mui/icons-material";
import {
  Box as MuiBox,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Container as MuiContainer,
  CssBaseline as MuiCssBaseline,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Link as MuiLink,
  Paper as MuiPaper,
  Toolbar as MuiToolbar,
  Typography as MuiTypography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useSession } from "../../hooks/session";
import AppBar from "./components/appbar";
import SideBar from "./components/sidebar";
import SnackBar from "./components/snackbar";
import { PageFieldOptions, PageProps } from "./page.types";

function PageTitle(props: any): JSX.Element {
  return <></>;
}

function Page(props: PageProps): JSX.Element {
  const session = useSession();
  const theme = useTheme();
  const navigate = useNavigate();
  const isExpired = session.expiresAt! < Date.now() / 1000;
  useEffect(() => {
    if (session.isAuthenticated)
      session.handleAccessValidation({
        key: props.keyAccess,
        accessIds: session.access,
      });
  }, []);
  if (props.requiresAuthentication && !session.isAuthenticated)
    return <Navigate to={{ pathname: "/signin" }} />;

  const ContentContainer = ({ children }: { children: JSX.Element }) => {
    return props.paper === true ? (
      <MuiPaper>{children}</MuiPaper>
    ) : (
      <>{children}</>
    );
  };

  if (session.isAuthenticated && isExpired)
    return (
      <>
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme.palette.grey[100],
          }}
        >
          <AppBar />
          <MuiPaper sx={{ p: 3, maxWidth: 500 }}>
            <MuiGrid container columns={2} spacing={3} textAlign="center">
              <MuiGrid item sm={2}>
                <MuiTypography
                  sx={{ color: theme.palette.warning.light }}
                  component={"h1"}
                  variant={"h5"}
                >
                  Parece que sua sessão expirou
                </MuiTypography>
              </MuiGrid>
              <MuiGrid item xs={2}>
                <WebAssetOffOutlined sx={{ fontSize: 150 }} />
              </MuiGrid>

              <MuiGrid item sm={2}>
                <MuiTypography component={"p"} variant={"body1"}>
                  Sua última sessão expirou. Por favor, faça Login novamente
                  para continuar conectado como{" "}
                  <b>{session.account?.user?.name}</b>
                </MuiTypography>
              </MuiGrid>
              <MuiGrid item sm={2}>
                <MuiButton
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    if (session.isAuthenticated) session.signOut();
                    navigate("/");
                  }}
                >
                  Ir para Login
                </MuiButton>
              </MuiGrid>
            </MuiGrid>
          </MuiPaper>
        </div>
      </>
    );

  return (
    <MuiBox sx={{ display: "flex" }}>
      <MuiCssBaseline />
      <AppBar />

      {props.showSideBar && <SideBar />}
      {props.context && <SnackBar context={props.context} />}
      <MuiBox
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
          paddingBottom: props.title && 2,
        }}
      >
        {props.showAppBar && <MuiToolbar />}
        <MuiContainer maxWidth={props.maxWidth} sx={{ mt: 2 }}>
          {props.title && (
            <>
              <MuiGrid container>
                <MuiGrid item xs={12}>
                  {props.breadcrumbs && (
                    <MuiBreadcrumbs>
                      {props.breadcrumbs.map((breadcrumb, i, array) => (
                        <MuiLink
                          key={breadcrumb.description}
                          fontWeight={
                            array[array.length - 1] !== breadcrumb
                              ? "normal"
                              : "bold"
                          }
                          underline={
                            array[array.length - 1] !== breadcrumb
                              ? "hover"
                              : "always"
                          }
                          href={breadcrumb.link}
                        >
                          {breadcrumb.description}
                        </MuiLink>
                      ))}
                    </MuiBreadcrumbs>
                  )}
                </MuiGrid>
                <MuiGrid item xs={6}>
                  <h1 style={{ margin: "10px 0", padding: 0 }}>
                    {props.title || ""}
                  </h1>
                </MuiGrid>
                <MuiGrid item xs={6}>
                  <MuiGrid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignContent="center"
                    height={"100%"}
                  >
                    {props.toolbar?.map((toolbarItem, i) => {
                      return (
                        <MuiGrid
                          item
                          key={i}
                          sx={{ marginLeft: theme.spacing(2) }}
                        >
                          {toolbarItem}
                        </MuiGrid>
                      );
                    })}

                    {/* <MuiGrid item>{props.toolbar}</MuiGrid> */}
                  </MuiGrid>
                </MuiGrid>
              </MuiGrid>
              <MuiDivider sx={{ marginBottom: "50px" }} />
            </>
          )}
          {/* <ContentContainer>{props.children}</ContentContainer> */}
          {props.children}
        </MuiContainer>
      </MuiBox>
    </MuiBox>
  );
}

const createPageField = (
  defaultValue?: string | boolean | Array<any> | any,
  options: PageFieldOptions = {}
) => {
  return {
    value: defaultValue,
    required: options.required || false,
    error: false,
    errorMessage: "",
  };
};

function createPageContext<Data, Values>(data: Data, values: Values) {
  const PageContext = createContext<any>({});

  const PageContextProvider = ({ children }: { children: JSX.Element }) => {
    const [_data, _setData] = useState({ ...data });
    const [_values, _setValues] = useState(values);
    const [_notification, _setNotification] = useState({
      open: false,
      message: "",
      type: "info",
    });
    return (
      <PageContext.Provider
        value={[
          _data,
          _setData,
          _values,
          _setValues,
          _notification,
          _setNotification,
        ]}
      >
        {children}
      </PageContext.Provider>
    );
  };

  function usePageContext(): {
    data: Data;
    values: Values;
    setData: (data: Data) => void;
    setValues: (values: Values) => void;
    notify: (
      type: "success" | "warning" | "error",
      message: string,
      whenSnackCloses?: () => void
    ) => void;
    context: React.Context<any>;
  } {
    const [
      _data,
      _setData,
      _values,
      _setValues,
      _notification,
      _setNotification,
    ] = useContext(PageContext);

    const setData = useCallback((props: any) => {
      _setData((current: any) => {
        return { ...current, ...props };
      });
    }, []);

    const setValues = useCallback((props: any) => {
      _setValues((current: any) => {
        Object.keys(props).map(
          (prop: string) =>
            (current[prop] = {
              ...current[prop],
              ...props[prop],
              ...(!props[prop].error && { errorMessage: undefined }),
            })
        );
        return { ...current };
      });
    }, []);

    const notify = useCallback(
      (
        type: "success" | "warning" | "error",
        message: string,
        whenSnackCloses?: () => void
      ) => {
        _setNotification({
          open: true,
          message,
          type,
          whenSnackCloses,
        });
      },
      []
    );

    return {
      data: _data,
      values: _values,
      setData,
      setValues,
      notify,
      context: PageContext,
    };
  }

  return {
    PageContextProvider,
    usePageContext,
  };
}

Page.createField = createPageField;

export default Page;
export { createPageField, createPageContext };
