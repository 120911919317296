import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/button";
import Grid from "../../../components/grid/grid";
import Page from "../../../components/page";
import TextField from "../../../components/text-field";
import { useAccess } from "../../../hooks/access";
import { useRole } from "../../../hooks/role";
import { useSession } from "../../../hooks/session";
import { usePageContext } from "../role/context";
import { AccessListItem } from "./access-listitem";

function AccessPage() {
  // const theme = useTheme();
  const session = useSession();
  const page = usePageContext();
  const hooks = { role: useRole(), access: useAccess() };
  const navigate = useNavigate();
  const { id } = useParams();
  const [oldAccess, setOldAccess] = useState();
  const [tempName, setTempName] = useState("");

  useEffect(() => {
    if (!page.data.roles) roleList();
    if (!page.data.access) accessList();
  }, [page.values.selectedRole.value]);

  const roleList = async () => {
    await hooks.role.fetchRoles(session).then((res) => {
      let emptyRole;
      const role = res.find((where) => where.id === id);
      if (!role) {
        emptyRole = {
          id,
          name: "",
          view: "new",
        };
      }
      page.setValues({
        selectedRole: { value: role ?? emptyRole },
      });
      page.setData({
        roles: res.map((i) => ({
          id: i.id,
          name: i.name,
        })),
      });
      if (res) {
        setTempName(role && role.name);
      }
    });
  };

  const accessList = async () => {
    const access = await hooks.access
      .fetchAccess(session, id)
      .finally(() => page.setValues({ isLoading: { value: false } }));
    access.sort((a, b) => {
      return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
    });

    setOldAccess(access);
    page.setData({
      access: access.map((i) => ({
        id: i.id,
        name: i.name,
        roleAccess: i.roleAccess,
        vincaccessId: i.vincaccessId,
      })),
    });
  };

  const handleSaveAccesVinc = async () => {
    page.setValues({
      isLoading: { value: true },
    });
    tempName !== page.values.selectedRole.value.name
      ? await saveEdit()
      : page.notify("success", "Salvo com sucesso");
    for (let index = 0; index < oldAccess.length; index++) {
      if (oldAccess[index].roleAccess !== page.data.access[index].roleAccess) {
        if (page.data.access[index].roleAccess === true) {
          await hooks.access.addAccessVinc(
            page.data.access[index],
            page.values.selectedRole.value.id,
            session
          );
        } else {
          await hooks.access.deleteAccessVinc(
            page.data.access[index].vincaccessId,
            session
          );
        }
      }
    }
    await accessList();
  };

  const saveEdit = async () => {
    const role = page.values.selectedRole.value;
    page.setValues({
      isLoading: { value: true },
    });
    let oldName;
    let res;

    oldName = role.name;
    role.name = tempName;

    if (role.view === "new") {
      res = await hooks.role.addItem(role, session);
    } else {
      res = await hooks.role.saveItem(role, session);
    }
    // res = await hooks.role.saveItem(role, session);
    if (res) {
      if (res.errors) {
        role.name = oldName;
        page.notify("error", `${res.errors[0].message}: Esse perfil já existe`);
      } else {
        role.view = "default";
        page.notify("success", "Salvo com sucesso");
        // navigate(`/settings/roles`);
      }

      page.setValues({
        selectedRole: {
          value: role,
        },
        isLoading: {
          value: false,
        },
      });
    }
  };

  const cancelEdit = async () => {
    const role = page.values.selectedRole.value;
    if (role.view === "new") {
      navigate(`/settings/roles`);
      // await hooks.role.deleteItem(role, session).then(() => {
      // });
    }
    role.view = "default";
    setTempName(role.name);

    page.setData({
      access: page.data.access.map((actualAccess, index) => ({
        id: oldAccess[index].id,
        name: oldAccess[index].name,
        roleAccess: oldAccess[index].roleAccess,
        vincaccessId: oldAccess[index].vincaccessId,
      })),
    });

    page.setValues({
      selectedRole: {
        value: role,
      },
    });
  };

  return (
    <Page
      context={page.context}
      showAppBar
      showSideBar
      requiresAuthentication
      keyAccess="getRoles"
      breadcrumbs={[
        { description: "Home", link: "/" },
        { description: "Configurações", link: "/settings" },
        { description: "Perfis", link: "/settings/roles" },
        { description: "Acessos", link: `/settings/access/${id}` },
      ]}
      title="Configurar Acessos"
    >
      <Grid container columns={6} spacing={1}>
        <Grid item xs={6} sx={{ marginBottom: 2 }}>
          <TextField
            label="Nome do Perfil"
            fullWidth
            field={{ value: tempName }}
            onChange={(event) => {
              setTempName(event.target.value);
            }}
          />
        </Grid>

        {page.data.access?.length === 0 ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "60vh",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p>Nenhum acesso cadastrado no momento</p>
          </div>
        ) : (
          page.data.access?.map((accessRow) => {
            return <AccessListItem key={accessRow.id} access={accessRow} />;
          })
        )}
        <Grid item xs={6} sx={{ marginTop: 2 }}>
          <div
            style={{
              display: "flex",
              gap: 8,
            }}
          >
            <Button
              type="button"
              variant="contained"
              color="warning"
              disabled={
                (page.values.selectedRole.value.view !== "new" &&
                  tempName === page.values.selectedRole.value.name) ||
                page.values.isLoading.value
              }
              onClick={cancelEdit}
            >
              Cancelar
            </Button>
            <Button
              type="button"
              variant="contained"
              color="primary"
              disabled={page.values.isLoading.value === true}
              onClick={async () => await handleSaveAccesVinc()}
            >
              {page.values.isLoading.value === true ? (
                <CircularProgress size={24.5} sx={{ color: "white" }} />
              ) : (
                "Salvar"
              )}
            </Button>
          </div>
        </Grid>
      </Grid>
    </Page>
  );
}

export default AccessPage;
