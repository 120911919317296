import {
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar as MuiToolbar,
  Tooltip as MuiTooltip,
  Typography as MuiTypography,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Notifications as NotificationsIcon,
  AccountCircle as AccountCircleIcon,
  Domain as DomainIcon,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { useApp } from "../../../../hooks/app";
import { AppBarProps } from "./appbar.types";
import Logo from "../../../../assets/img/logo.png";
import AssetLogo from "./logo-svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Dialog from "../../../dialog";
import SwapCompany from "../swap-company-modal";
import Button from "../../../button";
import AccountMenu from "../account-menu";
import NotificationMenu from "../notification-menu";
import { useSession } from "../../../../hooks/session";

/**
 * @param {AppBarProps} props
 * @returns
 */
const AppBar = (props) => {
  const theme = useTheme();
  const app = useApp();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const session = useSession();
  const isAuthenticated =
    session.isAuthenticated && session.account.selectedCompany !== undefined;

  const toggleSideBarVisibility = () => {
    const { isCollapsed, open, collapse } = app.sidebar;
    if (isCollapsed) open();
    else collapse();
  };

  const SwapCompanyDialog = () => {
    return (
      <Dialog
        open={isDialogOpen}
        fullWidth
        maxWidth={"sm"}
        actions={
          <Button
            type="button"
            variant="text"
            fullWidth
            onClick={() => setDialogOpen(false)}
          >
            Cancelar
          </Button>
        }
      >
        <div style={{ paddingTop: 8 }}>
          <SwapCompany closeDialog={() => setDialogOpen(false)} />
        </div>
      </Dialog>
    );
  };

  const Hamburger = ({ onClick }) => {
    return (
      <MuiIconButton
        sx={{
          marginRight: "30px",
          color: "inherit",
          [theme.breakpoints.up("sm")]: {
            display: "none",
          },
        }}
        onClick={onClick}
      >
        <MenuIcon />
      </MuiIconButton>
    );
  };

  const Title = () => (
    <MuiTypography
      variant="h6"
      noWrap
      sx={{
        flexGrow: 1,
        color: "inherit",
      }}
    >
      {props.title}
    </MuiTypography>
  );

  const RightIconButton = ({ Icon, onClick, Menu }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    return (
      <>
        <MuiIconButton
          onClick={handleClick}
          sx={{
            marginRight: "15px",
            color: "inherit",
          }}
        >
          <Icon />
        </MuiIconButton>
        {Menu && (
          <Menu
            anchorEl={anchorEl}
            handleClose={() => setAnchorEl(null)}
            openDialog={() => setDialogOpen(true)}
          />
        )}
      </>
    );
  };

  return (
    <MuiAppBar
      position="absolute"
      sx={{
        zIndex: theme.zIndex.drawer + 1,
        height: theme.components.AppBar.height,
        backgroundColor: "#2D3A40",
      }}
    >
      <MuiToolbar
        sx={{
          paddingRight: "24px",
        }}
      >
        <SwapCompanyDialog />
        <Hamburger onClick={toggleSideBarVisibility} />
        <AssetLogo height={"25"} />

        <Title />
        {isAuthenticated && (
          <>
            <MuiTooltip title="Trocar empresa">
              <div>
                <Button
                  startIcon={<DomainIcon />}
                  type="button"
                  variant="outlined"
                  color="inherit"
                  onClick={() => setDialogOpen(true)}
                  sx={{
                    marginRight: 2,
                    [theme.breakpoints.down("sm")]: {
                      display: "none",
                    },
                  }}
                >
                  {session.account.selectedCompany.name}
                </Button>
              </div>
            </MuiTooltip>
            <RightIconButton Icon={AccountCircleIcon} Menu={AccountMenu} />
          </>
        )}
      </MuiToolbar>
    </MuiAppBar>
  );
};

export default AppBar;
