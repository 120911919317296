import { useTheme } from "@mui/material";
import { palette } from "@mui/system";
import React, { PureComponent } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Tooltip,
  Cell,
  ResponsiveContainer,
  Legend,
} from "recharts";

export default function PieChartComponent({ data }) {
  const theme = useTheme();

  const palette = [
    ...Object.values(theme.palette.warning),
    ...Object.values(theme.palette.info),
    ...Object.values(theme.palette.success),
    ...Object.values(theme.palette.primary),
  ];

  const COLORS = palette.filter((color) => color !== "#fff");

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Legend layout="vertical" align="left" verticalAlign="middle" />
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          outerRadius={80}
          fill="#8884d8"
          label
          dataKey="assetsCount"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
}
