import { Grid, Paper, Typography, useTheme } from "@mui/material";

import { useSession } from "../../../../hooks/session";
import "../../style/animated-numbers.css";

export default function InfoCard({ label, Icon, cardColor, value, accessId }) {
  const theme = useTheme();
  const session = useSession();
  return (
    session.hasAccess({ key: accessId, accessIds: session.access }) && (
      <Grid item xs>
        <Paper
          elevation={25}
          sx={{
            p: 2,
            display: "flex",
            gap: 2,
            backgroundColor: theme.palette[cardColor].main,
            color: "#fafafa",
          }}
        >
          <Icon fontSize="large" />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              component={"h2"}
              variant={"h5"}
              sx={{
                fontWeight: "500",
                "--num": parseInt(value),
                transition: "--num .5s",
                counterSet: "num var(--num)",
                "::after": {
                  content: "counter(num)",
                },
              }}
              className="count"
            ></Typography>
            <Typography component={"h3"} variant={"caption"}>
              {label}
            </Typography>
          </div>
        </Paper>
      </Grid>
    )
  );
}
