import { PageField, createPageContext } from "../../../components/page";

export interface Data {
  description?: string[];
}

export interface Values {
  description?: PageField;
  observation?: PageField;
  initial_date?: PageField;
  recurrence?: PageField;
  send_email?: PageField;
}

const { PageContextProvider, usePageContext } = createPageContext<Data, Values>(
  {},
  {}
);

export { PageContextProvider, usePageContext };
