import {
  Check as CheckIcon,
  Close as CloseIcon,
  PendingOutlined as PendingIcon,
} from "@mui/icons-material";
import { CircularProgress, Paper, useTheme } from "@mui/material";
import jwtDecode from "jwt-decode";
import { useSearchParams } from "react-router-dom";
import Button from "../../../../components/button";
import Grid from "../../../../components/grid";
import TextField from "../../../../components/text-field";
import Typography from "../../../../components/typography";
import { useSession } from "../../../../hooks/session";
import { useUser } from "../../../../hooks/user";
import { usePageContext } from "../context";

export default function CreateUserStep() {
  const page = usePageContext();
  const theme = useTheme();
  const hooks = { user: useUser(), session: useSession() };
  const [urlParams] = useSearchParams();
  const invitetoken = urlParams.get("invitetoken");
  const tokenInfo = jwtDecode(invitetoken);

  const isStepSkipped = (step) => page.data.stepper.skipped.has(step);

  const handleNext = () => {
    const { activeStep, skipped } = page.data.stepper;

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    page.setData({
      stepper: {
        activeStep: activeStep + 1,
        skipped: newSkipped,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // return handleNext();
    let isNotValidated = page.data.rules.find((rule) => rule.status !== "ok");
    let hasError = false;

    if (isNotValidated) {
      return page.notify(
        "error",
        "Senha não atende aos requisitos, tente novamente"
      );
    }
    page.setData({
      isLoading: true,
    });
    try {
      await createUser();
      page.notify("success", "Usuário criado com sucesso");
    } catch (error) {
      hasError = true;
      page.notify("error", "Algo deu errado!");
    } finally {
      page.setData({
        isLoading: false,
      });
      if (!hasError) handleNext();
    }
  };

  const createUser = async () => {
    await hooks.user
      .linkNewUser(
        {
          name: page.values.name.value,
          password: page.values.password.value,
        },
        {
          token: invitetoken,
        }
      )
      .then((res) => {
        if (res.errors) return page.notify("error", res.errors[0].message);
        handleNext();
        page.notify("success", "Usuário criado com sucesso");
      })
      .catch((e) => page.notify("error", "Usuário criado com sucesso"));
  };

  const handleChange = (e) => {
    page.setValues({
      name: {
        value: e.target.value,
      },
    });
  };

  return (
    <Paper sx={{ p: 3 }}>
      <Grid container columns={2} spacing={2}>
        <Grid item xs={2}>
          <Typography component="h1" variant="h4" sx={{ width: "100%" }}>
            Bem vindo {tokenInfo.UserEmail}!
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <form
            id="newCompanyForm"
            style={{
              width: "100%",
              marginTop: theme.spacing(1),
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 16,
            }}
            onSubmit={handleSubmit}
          >
            <Typography
              component={"h2"}
              variant="subtitle1"
              sx={{ width: "100%" }}
            >
              Antes de preencher as informações de sua empresa, vamos finalizar
              seu cadastro e definir a senha que será necessária para acessar
              sua conta, junto ao e-mail.
            </Typography>

            <TextField
              id="name"
              name="name"
              label="Nome"
              fullWidth
              margin="normal"
              field={page.values.name}
              onChange={handleChange}
            />
            <TextField
              id="password"
              name="password"
              type="password"
              fullWidth
              margin="none"
              label="Senha"
              field={page.values.password}
              onChange={(e) => {
                page.setValues({
                  password: {
                    value: e.target.value,
                  },
                });

                page.setData({
                  rules: page.data.rules.map((rule) => {
                    if (rule.name === "number") {
                      const regexNumber = /[0-9]/;
                      if (regexNumber.test(e.target.value)) {
                        rule.status = "ok";
                      } else if (e.target.value.length === 0) {
                        rule.status = "none";
                      } else {
                        rule.status = "error";
                      }
                    }

                    if (rule.name === "characters") {
                      if (e.target.value.length >= 8) {
                        rule.status = "ok";
                      } else if (e.target.value.length === 0) {
                        rule.status = "none";
                      } else {
                        rule.status = "error";
                      }
                    }

                    if (rule.name === "upperLetter") {
                      const regexUpperCase = /[A-Z]/;
                      if (regexUpperCase.test(e.target.value)) {
                        rule.status = "ok";
                      } else if (e.target.value.length === 0) {
                        rule.status = "none";
                      } else {
                        rule.status = "error";
                      }
                    }

                    if (rule.name === "confirmPassword") {
                      if (
                        page.values.confirmPassword.value === e.target.value &&
                        e.target.value !== ""
                      ) {
                        rule.status = "ok";
                      } else if (e.target.value.length === 0) {
                        rule.status = "none";
                      } else {
                        rule.status = "error";
                      }
                    }

                    return rule;
                  }),
                });
              }}
            />
            <TextField
              id="confirm-password"
              name="confirm-password"
              type="password"
              fullWidth
              margin="none"
              label="Confirmar senha"
              field={page.values.confirmPassword}
              onChange={(e) => {
                page.setValues({
                  confirmPassword: {
                    value: e.target.value,
                  },
                });

                page.setData({
                  rules: page.data.rules.map((rule) => {
                    if (rule.name === "confirmPassword") {
                      if (
                        page.values.password.value === e.target.value &&
                        e.target.value !== ""
                      ) {
                        rule.status = "ok";
                      } else if (e.target.value.length === 0) {
                        rule.status = "none";
                      } else {
                        rule.status = "error";
                      }
                    }

                    return rule;
                  }),
                });
              }}
            />
            <Grid item xs={2} sx={{ width: "100%" }}>
              <Typography component="h1" variant="h6">
                Sua senha deve conter:
              </Typography>
              {page.data.rules.some((rule) => rule) &&
                page.data.rules.map((rule) => (
                  <div
                    key={rule.name}
                    style={{
                      display: "flex",
                      gap: 8,
                    }}
                  >
                    <Typography component="p" variant="caption">
                      {rule.description}
                    </Typography>
                    {rule.status === "none" ? (
                      <PendingIcon fontSize="small" />
                    ) : rule.status === "ok" ? (
                      <CheckIcon fontSize="small" color="success" />
                    ) : (
                      <CloseIcon fontSize="small" color="error" />
                    )}
                  </div>
                ))}
            </Grid>
            <Grid
              item
              container
              xs={12}
              sx={{ justifyContent: "space-between" }}
            >
              <Grid item>
                <Button
                  type="button"
                  disabled
                  fullWidth
                  variant="outlined"
                  color="primary"
                >
                  Voltar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={page.data.isLoading}
                >
                  {page.data.isLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Salvar e continuar"
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Paper>
  );
}
