import { useCallback } from "react";
import config from "../../config";
import { Access } from "../../pages/settings/access/context";
import { Session } from "../session/session-hook.types";

const useAccess = () => {
  const fetchAccess = useCallback(async (session?: Session, role?: string) => {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", `Bearer ${session?.token || ""}`);
    const res = await fetch(`${config.api.url}/v2/vinc-access/${role}`, {
      method: "GET",
      headers: requestHeaders,
    });
    const data = await res.json();
    return data;
  }, []);

  const addAccessVinc = useCallback(
    async (access: Access, roleId: string, session?: Session) => {
      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.set("Content-Type", "application/json");
      requestHeaders.set("Authorization", `Bearer ${session?.token || ""}`);

      const res = await fetch(`${config.api.url}/v2/role-access`, {
        method: "POST",
        headers: requestHeaders,
        body: JSON.stringify({
          accessId: access.id,
          roleId,
        }),
      });
      return res;
    },
    []
  );

  const deleteAccessVinc = useCallback(
    async (vincId: string, session?: Session) => {
      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.set("Content-Type", "application/json");
      requestHeaders.set("Authorization", `Bearer ${session?.token || ""}`);

      const res = await fetch(`${config.api.url}/v2/role-access/${vincId}`, {
        method: "DELETE",
        headers: requestHeaders,
      });

      return res;
    },
    []
  );

  // const saveAccess = useCallback(async (access: Access, session?: Session) => {
  //   const requestHeaders: HeadersInit = new Headers();
  //   requestHeaders.set("Content-Type", "application/json");
  //   requestHeaders.set("Authorization", `Bearer ${session?.token || ""}`);

  //   const res = await fetch(`${config.api.url}/v2/access/${access.id}`, {
  //     method: "PUT",
  //     headers: requestHeaders,
  //     body: JSON.stringify({
  //       name: access.name,
  //     }),
  //   });
  //   return res;
  // }, []);

  return { fetchAccess, addAccessVinc, deleteAccessVinc };
};

export { useAccess };
