import Button from "../../../components/button";
import { useSession } from "../../../hooks/session";
import { useUser } from "../../../hooks/user";
import { useNavigate, useSearchParams } from "react-router-dom";
import Page from "../../../components/page";
import Grid from "../../../components/grid";
import { Paper, useTheme } from "@mui/material";
import jwt from "jwt-decode";
import Typography from "../../../components/typography";
import { WebAssetOffOutlined as WebAssetOffOutlinedIcon } from "@mui/icons-material";
import moment from "moment";
import { usePageContext } from "./context";
import { useEffect } from "react";

const AlreadyLoggedInWithAnotherUserView = () => {
  const session = useSession();
  const navigate = useNavigate();
  return (
    <Paper sx={{ p: 3, maxWidth: 500 }}>
      <Grid container columns={2} spacing={3} textAlign="center">
        <Grid item sm={2}>
          <Typography component={"h1"} variant={"h5"}>
            Sessão já iniciada
          </Typography>
        </Grid>
        <Grid item sm={2}>
          <Typography component={"p"} variant={"body1"}>
            Parece que você já possui uma sessão iniciada com o e-mail{" "}
            <b>{session.account.user.email}</b>. Deseja alterar ou permanecer na
            mesma conta?
          </Typography>
        </Grid>
        <Grid item sm={2} container columns={2} spacing={1}>
          <Grid item xs={1}>
            <Button
              fullWidth
              variant="text"
              color="warning"
              onClick={async () => {
                await session.signOut();
              }}
            >
              Alterar
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Button
              fullWidth
              onClick={async () => {
                navigate("/");
              }}
            >
              Permanecer
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const ExpiredView = () => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const token = queryParams.get("invitetoken");
  const tokenData = jwt(token);
  const theme = useTheme();
  return (
    <Paper sx={{ p: 3, maxWidth: 500 }}>
      <Grid container columns={2} spacing={3} textAlign="center">
        <Grid item sm={2}>
          <Typography
            sx={{ color: theme.palette.warning.light }}
            component={"h1"}
            variant={"h5"}
          >
            Convite Expirado!
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <WebAssetOffOutlinedIcon sx={{ fontSize: 150 }} />
        </Grid>

        <Grid item sm={2}>
          <Typography component={"p"} variant={"body1"}>
            O convite para entrar na empresa <b>{tokenData.CompanyName}</b>{" "}
            expirou, solicite ao administrador que envie novamente
          </Typography>
        </Grid>
        <Grid item sm={2}>
          <Button
            fullWidth
            onClick={async () => {
              navigate("/");
            }}
          >
            Ok
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default function UpdateUserInvite() {
  const session = useSession();
  const page = usePageContext();
  const hooks = { user: useUser() };
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const token = queryParams.get("invitetoken");
  const tokenData = jwt(token);
  const theme = useTheme();
  const isExpired = tokenData.ExpiresAt < Date.now() / 1000;
  const isAlreadyLoggedInWithAnotherUser =
    tokenData.UserEmail !== session.account.user.email;

  useEffect(() => {
    if (isExpired) page.setData({ view: "Expired" });
    if (isAlreadyLoggedInWithAnotherUser && session.isAuthenticated)
      page.setData({ view: "AlreadyLoggedInWithAnotherUser" });
  }, []);

  const InviteView = () => {
    return (
      <Paper sx={{ p: 3, maxWidth: 500 }}>
        <Grid container columns={2} spacing={3}>
          <Grid item sm={2}>
            <Typography component={"h1"} variant={"h5"}>
              Bem vindo à {tokenData.CompanyName}
            </Typography>
          </Grid>
          <Grid item sm={2}>
            <Typography component={"p"} variant={"body1"}>
              Clique no botão abaixo para ter acesso aos conteúdos de sua nova
              empresa. Att Assets Admin.
            </Typography>
          </Grid>
          <Grid item sm={2}>
            <Button
              fullWidth
              onClick={async () => {
                await hooks.user.updateUserCompanies(token);
              }}
            >
              Juntar-se a nova empresa
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const mapViewComponents = {
    Invite: <InviteView />,
    Expired: <ExpiredView />,
    AlreadyLoggedInWithAnotherUser: <AlreadyLoggedInWithAnotherUserView />,
  };
  const currentView = mapViewComponents[page.data.view];

  return (
    <Page>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {currentView}
      </div>
    </Page>
  );
}
