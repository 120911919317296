import { createPageContext } from "../../../components/page";
import { Pagination } from "../../../hooks/user/user-hook.types";
import { Reminder } from "../../recurring-reminder/recurring-reminder-form/context";
import { Genre } from "../../settings/genres/context";
import { Location } from "../../settings/location/list/context";

export interface Asset {
  id?: string;
  description?: string;
  invoiceDate?: { min: Date; max: Date };
  acquireAmount?: {
    min?: number;
    max?: number;
    hasAcquireAmount: boolean;
  };
  number?: string;
  rfid?: string;
  invoiceNumber?: string;
  dateEndCalculation?: Date;
  location?: string;
  genre?: string;
  calculation?: boolean;
}

export interface Data {
  assets?: Asset[];
  locationFilter?: Location[];
  genreFilter?: Genre[];
  count?: number;
  pagination?: Pagination;
  searchQueryParams?: Asset;
  isModalOpen: boolean;
  isGalleryOpen: boolean;
  picturesUrls?: string[];
  isReminderOpen: boolean;
  reminder?: Reminder[];
}

export interface Values {}

const { PageContextProvider, usePageContext } = createPageContext<Data, Values>(
  {
    isModalOpen: false,
    isGalleryOpen: false,
    picturesUrls: [],
    isReminderOpen: false,
    reminder: [],
    pagination: {
      page: 0,
      rowsPerPage: 10,
      count: 1,
    },
    searchQueryParams: {
      acquireAmount: {
        hasAcquireAmount: false,
        max: undefined,
        min: undefined,
      },
    },
  },
  {}
);

export { PageContextProvider, usePageContext };
