import { Check, CheckCircle } from "@mui/icons-material";
import { Box, Paper, Step, StepLabel, Stepper } from "@mui/material";
import jwtDecode from "jwt-decode";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../../components/button";
import Grid from "../../../components/grid";
import Page from "../../../components/page";
import Typography from "../../../components/typography";
import { useSession } from "../../../hooks/session";
import AlreadyLoggedInWithAnotherUserView from "../views/already-loggedin";
import ExpiredView from "../views/expired";
import { usePageContext } from "./context";

function NewCompanyView() {
  const page = usePageContext();
  const [urlParams] = useSearchParams();
  const invitetoken = urlParams.get("invitetoken");
  const tokenInfo = jwtDecode(invitetoken);
  const navigate = useNavigate();

  const isStepOptional = (step) => step > 1;

  const isStepSkipped = (step) => page.data.stepper.skipped.has(step);

  useEffect(() => {
    if (tokenInfo.UserExist) {
      page.setData({
        stepper: {
          skipped: page.data.stepper.skipped,
          activeStep: page.data.stepper.activeStep + 1,
        },
      });
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stepper activeStep={page.data.stepper.activeStep}>
          {page.data.steps.map((step, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Opcional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={step.label} {...stepProps}>
                <StepLabel {...labelProps}>{step.label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Grid>
      <Grid item xs={12}>
        {page.data.stepper.activeStep === page.data.steps.length ? (
          <Grid
            item
            container
            direction={"column"}
            textAlign="center"
            spacing={3}
          >
            <Grid item>
              <CheckCircle sx={{ color: "green", fontSize: 170 }} />
            </Grid>
            <Grid item>
              <Typography>
                Cadastro da empresa {tokenInfo.CompanyName} concluído com
                sucesso!
              </Typography>
            </Grid>
            <Grid item>
              <Button
                type="button"
                onClick={() => navigate("/")}
                color="success"
              >
                Concluir
              </Button>
            </Grid>
          </Grid>
        ) : (
          page.data.steps[page.data.stepper.activeStep].step
        )}
      </Grid>
    </Grid>
  );
}

export default function NewCompanyPage() {
  const page = usePageContext();
  const [urlParams] = useSearchParams();
  const hooks = { session: useSession() };
  const invitetoken = urlParams.get("invitetoken");
  const tokenInfo = jwtDecode(invitetoken);
  const isExpired = tokenInfo.ExpiresAt < Date.now() / 1000;
  const isAlreadyLoggedInWithAnotherUser =
    tokenInfo.UserEmail !== hooks.session.account.user.email;
  useEffect(() => {
    if (isExpired) page.setData({ view: "Expired" });
    if (isAlreadyLoggedInWithAnotherUser && hooks.session.isAuthenticated)
      page.setData({ view: "AlreadyLoggedInWithAnotherUser" });
  }, []);

  const mapViewComponents = {
    CreateCompany: <NewCompanyView />,
    Expired: <ExpiredView />,
    AlreadyLoggedInWithAnotherUser: <AlreadyLoggedInWithAnotherUserView />,
  };
  const currentView = mapViewComponents[page.data.view];

  return (
    <Page context={page.context} maxWidth="lg" showAppBar>
      <div
        style={{
          margin: "64px auto",
        }}
      >
        {currentView}
      </div>
    </Page>
  );
}
