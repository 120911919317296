import { Paper, useTheme } from "@mui/material";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Paper sx={{ p: 1 }}>
        <p className="label">{`Número de ativos: ${payload[0].value}`}</p>
      </Paper>
    );
  }

  return null;
};

export default function BarChartComponent({ data }) {
  const theme = useTheme();
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 64,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          tickSize={20}
          fontSize={12}
          interval={0}
          attributeName="name"
          angle={-45}
        />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Bar
          dataKey="assetsCount"
          fill={theme.palette.primary.main}
          barSize={35}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
