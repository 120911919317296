import {
  Cancel,
  CheckCircle,
  FileDownload as DownloadIcon,
} from "@mui/icons-material";
import {
  Checkbox,
  CircularProgress,
  Tooltip as MuiTooltip,
} from "@mui/material";
import moment from "moment";
import { useEffect } from "react";
import AutoComplete from "../../components/auto-complete";
import Button from "../../components/button";
import Datagrid from "../../components/datagrid";
import Dialog from "../../components/dialog";
import Page from "../../components/page";
import TextField from "../../components/text-field";
import { useAccountingExport } from "../../hooks/accounting-export";
import { useSession } from "../../hooks/session";
import LayoutExportPage from "./components/layout-export-page";
import { usePageContext } from "./context";

export default function ExportContPage() {
  const page = usePageContext();
  const session = useSession();
  const hooks = { accountingExport: useAccountingExport() };

  useEffect(() => {
    if (!page.data.accountingExport || page.data.pagination)
      (async () => await getAccountingExport())();
  }, [
    page.data.pagination,
    page.values.monthYear.value,
    page.values.status.value,
  ]);

  const getAccountingExport = async () => {
    const { accountingExport, count } =
      await hooks.accountingExport.fetchAccountingExport(
        session,
        page.data.pagination,
        page.values.monthYear.value,
        page.values.status.value
      );
    page.setData({
      count,
      accountingExport,
    });
  };

  const handlePageChange = async (_, newPage) => {
    page.setData({
      pagination: {
        ...page.data.pagination,
        page: newPage,
      },
    });
  };

  const handleChangeRowsPerPage = async (event) => {
    page.setData({
      pagination: {
        page: 0,
        rowsPerPage: event.target.value,
      },
    });
  };

  const getParentCheckBoxStatus = (checkedAssets) => {
    const allAssetsIds = page.data.accountingExport.map((asset) => asset.id);
    if (!allAssetsIds?.length) {
      return;
    }
    if (allAssetsIds.length === checkedAssets.length) return "checked";

    if (
      checkedAssets.length > 0 &&
      checkedAssets.length !== allAssetsIds.length
    )
      return "indeterminate";

    return "unchecked";
  };

  const handleChangeCheckbox = (id, isChecking) => {
    const ids = page.data.selectedAssetsIds;
    if (isChecking) {
      ids.push(id);
    } else {
      const idToRemove = ids.indexOf(id);
      ids.splice(idToRemove, 1);
    }

    const parentCheckBoxStatus = getParentCheckBoxStatus(ids);
    page.setData({
      selectedAssetsIds: ids,
      allCheckedStatus: parentCheckBoxStatus,
    });
  };

  const handleCheckAll = () => {
    const allAssetsIds = page.data.accountingExport.map((asset) => {
      if (
        asset.debitAccount &&
        asset.creditAccount &&
        asset.debitAccount !== asset.creditAccount &&
        // asset.monthlyAccountingDepreciation.length &&
        asset.monthlyAccountingDepreciation[0].resultAccountingDepreciation !==
          0
      ) {
        return asset.id;
      }
      return [];
    });
    const checkedAssets = page.data.selectedAssetsIds;
    const parentCheckBoxStatus = getParentCheckBoxStatus(checkedAssets);
    if (!allAssetsIds?.length) {
      return;
    }
    if (parentCheckBoxStatus === "checked")
      return {
        allCheckedStatus: "unchecked",
        selectedAssetsIds: [],
      };

    return {
      allCheckedStatus: "checked",
      selectedAssetsIds: allAssetsIds,
    };
  };

  const rows = (accountingExport) => {
    return accountingExport.map((asset) => {
      let monthYear = page.values.monthYear.value;

      const isChecked = page.data.selectedAssetsIds.includes(asset.id);

      let status = (
        <div style={{ color: "green" }}>
          <CheckCircle />
        </div>
      );

      if (
        !asset.debitAccount ||
        !asset.creditAccount ||
        asset.debitAccount === asset.creditAccount ||
        !asset.monthlyAccountingDepreciation.length ||
        asset.monthlyAccountingDepreciation[0].resultAccountingDepreciation ===
          0
      ) {
        status = (
          <div style={{ color: "red" }}>
            <Cancel />
          </div>
        );
      }

      return {
        checkbox: (
          <Checkbox
            sx={{
              color: "gray",
            }}
            checked={isChecked}
            onChange={(event) =>
              handleChangeCheckbox(asset.id, event.target.checked)
            }
            disabled={
              !asset.debitAccount ||
              !asset.creditAccount ||
              !asset.monthlyAccountingDepreciation.length ||
              asset.debitAccount === asset.creditAccount ||
              asset.monthlyAccountingDepreciation[0]
                .resultAccountingDepreciation === 0
            }
          />
        ),
        status: status,
        monthYear: moment(new Date(monthYear + "-02")).format("MM/YYYY"),
        date: moment(new Date(monthYear + "-02"))
          .endOf("month")
          .format("DD/MM/YYYY"),
        genre: asset.genre,
        description: asset.description,
        depreciationValue: asset.monthlyAccountingDepreciation.length
          ? Number(
              asset.monthlyAccountingDepreciation[0]
                .resultAccountingDepreciation
            ).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              style: "currency",
              currency: "BRL",
            })
          : (0).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              style: "currency",
              currency: "BRL",
            }),
        debitAccount: asset.debitAccount
          ? asset.debitAccount
          : "Não Configurado",
        creditAccount: asset.creditAccount
          ? asset.creditAccount
          : "Não Configurado",
        complement:
          "Depreciação " +
          moment(new Date(monthYear + "-02")).format("MM/YYYY") +
          " - " +
          asset.description,
      };
    });
  };

  const columns = [
    {
      header: (
        <Checkbox
          sx={{
            color: "white",
            "&.Mui-checked": {
              color: "white",
            },
          }}
          checked={page.data.allCheckedStatus === "checked"}
          indeterminate={page.data.allCheckedStatus === "indeterminate"}
          onChange={(event) => {
            page.setData(handleCheckAll());
          }}
        />
      ),
      align: "center",
    },
    {
      header: "Status",
      align: "center",
    },
    {
      header: "Mês/Ano",
      align: "left",
    },
    {
      header: "Data",
      align: "left",
    },
    {
      header: "Gênero",
      align: "left",
    },
    {
      header: "Descrição",
      align: "left",
    },
    {
      header: "Valor de Depreciação",
      align: "right",
    },
    {
      header: "Conta Débito",
      align: "left",
    },
    {
      header: "Conta Crédito",
      align: "left",
    },
    {
      header: "Complemento",
      align: "left",
    },
  ];

  const status = [
    { name: "Todos", value: "all" },
    { name: "Sem Divergências", value: "complete" },
    { name: "Com Divergências", value: "incomplete" },
  ];

  return (
    <Page
      title="Exportação Contábil"
      showAppBar
      showSideBar
      requiresAuthentication
      breadcrumbs={[
        { description: "Home", link: "/" },
        { description: "Exportação Contábil", link: "/accounting-export" },
      ]}
      toolbar={[
        <MuiTooltip title="Exportar dados">
          <div>
            <Button
              startIcon={<DownloadIcon />}
              sx={{
                backgroundColor: "#66bb6a",
                ":hover": { backgroundColor: "#10783f" },
              }}
              type="button"
              variant="contained"
              onClick={() =>
                page.setValues({
                  openDialog: { value: true },
                })
              }
            >
              Exportar
            </Button>
          </div>
        </MuiTooltip>,
        <Dialog
          open={page.values.openDialog.value}
          title="Exportar"
          fullWidth
          maxWidth={"sm"}
        >
          <div style={{ paddingTop: 8 }}>
            <LayoutExportPage />
          </div>
        </Dialog>,
      ]}
    >
      {!page?.data?.accountingExport ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Datagrid
          tableAppBar={
            <div style={{ padding: 16, display: "flex", gap: 16 }}>
              <TextField
                id="month"
                variant="standard"
                label="Mês/Ano"
                type={"month"}
                field={page.values.monthYear}
                fullWidth
                onChange={(event) =>
                  page.setValues({
                    monthYear: {
                      value: event.target.value,
                    },
                  })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <AutoComplete
                id="status"
                label="Status"
                type="submit"
                fullWidth
                variant="standard"
                field={page.values.status}
                getOptionLabel={(option) => option.name}
                options={status}
                onChange={(_, value) => {
                  page.setValues({
                    status: {
                      value,
                    },
                  });
                }}
              />
            </div>
          }
          dataLenght={page.data.count}
          rows={rows(page.data.accountingExport).map((row) => ({
            cells: Object.values(row),
          }))}
          columns={columns}
          paginationProps={{
            ...page.data.pagination,
            count: page.data.count,
          }}
          handlePageChange={async (event, page) => {
            await handlePageChange(event, page);
          }}
          handleChangeRowsPerPage={async (event) => {
            await handleChangeRowsPerPage(event);
          }}
        />
      )}
    </Page>
  );
}
