import { createPageContext, createPageField } from "../../../components/page";
import { Pagination } from "../../../hooks/user/user-hook.types";

export interface AssetOcurrence {
  id?: string;
  assetId?: string;
  assetName?: string;
  acquireAmountMin?: number;
  acquireAmountMax?: number;
  createdAt?: Date;
  updatedAt?: Date;
  futureSellAmountMin?: number;
  futureSellAmountMax?: number;
  invoiceDate?: Date;
  remainingAmountMin?: number;
  remainingAmountMax?: number;
  yearsToDepreciate?: number;
  endDate?: Date;
  startDate?: Date;
}

export interface OcurrenceInput {
  startDate?: Date;
  futureSellAmount?: number;
  yearsToDepreciate?: number;
}

export interface Data {
  assets?: AssetOcurrence[];
  count?: number;
  pagination?: Pagination;
  searchQueryParams?: AssetOcurrence;
  dialog: {
    isOpen: boolean;
    title: string;
    local?: string;
    selectedAsset?: string;
  };
}

export interface Values {}

const { PageContextProvider, usePageContext } = createPageContext<Data, Values>(
  {
    dialog: { isOpen: false, title: "" },
    pagination: {
      page: 0,
      rowsPerPage: 10,
      count: 1,
    },
    searchQueryParams: {},
  },
  {
    local: createPageField(undefined, { required: true }),
    newLocal: createPageField(undefined, { required: true }),
  }
);

export { PageContextProvider, usePageContext };
