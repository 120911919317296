import {
  createPageContext,
  createPageField,
  PageField,
} from "../../components/page";

export interface Company {
  id: string;
  name: string;
}

export interface Data {
  view: string;
  companies?: Array<Company>;
}

export interface Values {
  email?: PageField;
  password?: PageField;
  message?: PageField;
  isLoading?: PageField;
}

const { PageContextProvider, usePageContext } = createPageContext<Data, Values>(
  {
    view: "SignIn",
  },
  {
    email: createPageField("", { required: true }),
    password: createPageField("", { required: true }),
    message: createPageField(""),
    isLoading: createPageField(false),
  }
);

export { PageContextProvider, usePageContext };
