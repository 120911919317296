import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogProps as MuiDialogProps,
  DialogTitle as MuiDialogTitle,
} from "@mui/material";
import { DialogProps } from "./dialog.types";

function Dialog(props: DialogProps) {
  const muiDialogProps: MuiDialogProps = {
    open: false,
  };

  if (props.open) muiDialogProps.open = props.open;

  if (props.disableEscapeKeyDown)
    muiDialogProps.disableEscapeKeyDown = props.disableEscapeKeyDown;

  if (props.fullScreen) muiDialogProps.fullScreen = props.fullScreen;

  if (props.fullWidth) muiDialogProps.fullWidth = props.fullWidth;

  if (props.maxWidth) muiDialogProps.maxWidth = props.maxWidth;

  if (props.scroll) muiDialogProps.scroll = props.scroll;

  if (props.sx) muiDialogProps.sx = props.sx;

  if (props.onClose) muiDialogProps.onClose = props.onClose;

  return (
    <MuiDialog {...muiDialogProps}>
      <MuiDialogTitle>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>{props.title}</div>
          {props.xCloseButton && props.xCloseButton}
        </div>
      </MuiDialogTitle>
      <MuiDialogContent>{props.children}</MuiDialogContent>
      <MuiDialogActions>{props.actions}</MuiDialogActions>
    </MuiDialog>
  );
}

export default Dialog;
