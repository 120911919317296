import { Grid as MuiGrid, GridProps as MuiGridProps } from "@mui/material";
import { GridProps } from "./grid.types";

function Grid(props: GridProps) {
  const muiGridProps: MuiGridProps = {
    container: false,
    item: false,
  };

  if (props.container) muiGridProps.container = props.container;

  if (props.item) muiGridProps.item = props.item;

  if (props.size) {
    muiGridProps[props.size] = true;
  }

  if (props.lg) muiGridProps.lg = props.lg;

  if (props.md) muiGridProps.md = props.md;

  if (props.sm) muiGridProps.sm = props.sm;

  if (props.xl) muiGridProps.xl = props.xl;

  if (props.xs) muiGridProps.xs = props.xs;

  if (props.spacing) muiGridProps.spacing = props.spacing;

  if (props.zeroMinWidth) muiGridProps.zeroMinWidth = props.zeroMinWidth;

  if (props.textAlign) muiGridProps.textAlign = props.textAlign;

  if (props.sx) muiGridProps.sx = props.sx;

  if (props.direction) muiGridProps.direction = props.direction;

  if (props.columns) muiGridProps.columns = props.columns;

  return <MuiGrid {...muiGridProps}>{props.children}</MuiGrid>;
}

export default Grid;
