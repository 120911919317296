import { useFetch } from "../fetch/fetch-hook";
import { Session } from "../session/session-hook.types";
import { Pagination } from "../user/user-hook.types";

const useAccountingExport = () => {
  const { doFetch } = useFetch();

  const fetchAccountingExport = async (
    session?: Session,
    pagination?: Pagination,
    monthYear?: string,
    status?: any
  ) => {
    return await doFetch({
      session,
      fetchConfig: {
        method: "GET",
        pagination,
        path: `v2/asset-accounting-export`,
        query: `monthYear=${monthYear}&statusDivergences=${status.value}`,
      },
    });
  };

  return {
    fetchAccountingExport,
  };
};

export { useAccountingExport };
