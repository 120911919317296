import { WebAssetOffOutlined } from "@mui/icons-material";
import { Paper, useTheme } from "@mui/material";
import jwtDecode from "jwt-decode";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../../components/button";
import Grid from "../../../components/grid";
import Typography from "../../../components/typography";

export default function ExpiredView() {
  const theme = useTheme();
  const [urlParams, setUrlParams] = useSearchParams();
  const invitetoken = urlParams.get("invitetoken");
  const tokenInfo = jwtDecode(invitetoken);
  const navigate = useNavigate();

  return (
    <Paper sx={{ p: 3, maxWidth: 500 }}>
      <Grid container columns={2} spacing={3} textAlign="center">
        <Grid item sm={2}>
          <Typography
            sx={{ color: theme.palette.warning.light }}
            component={"h1"}
            variant={"h5"}
          >
            Convite Expirado!
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <WebAssetOffOutlined sx={{ fontSize: 150 }} />
        </Grid>

        <Grid item sm={2}>
          <Typography component={"p"} variant={"body1"}>
            O convite para entrar na empresa <b>{tokenInfo.CompanyName}</b>{" "}
            expirou, solicite ao administrador que envie novamente
          </Typography>
        </Grid>
        <Grid item sm={2}>
          <Button
            fullWidth
            onClick={async () => {
              navigate("/");
            }}
          >
            Ok
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
