import { Cancel, CheckCircle } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { useEffect } from "react";
import AutoComplete from "../../components/auto-complete";
import Datagrid from "../../components/datagrid";
import Page from "../../components/page";
import { useInventory } from "../../hooks/inventory";
import { useLocation } from "../../hooks/location";
import { useSession } from "../../hooks/session";
import { usePageContext } from "./context";

export default function InventoryPage() {
  const page = usePageContext();
  const hooks = { location: useLocation(), assetinventory: useInventory() };
  const session = useSession();

  useEffect(() => {
    if (!page.data.location) (async () => await getLocations())();
  }, []);

  useEffect(() => {
    if (
      page.values.locationInventory.value ||
      (page.values.dateInventory.value && !page.data.assetinventory) ||
      page.data.pagination
    )
      (async () => await getInventory(page.values.dateInventory.value))();
  }, [page.data.pagination]);

  const getLocations = async () => {
    const allLocations = await hooks.location.fetchInventoryLocation(session);

    const localChildren = allLocations.filter((location, index, array) =>
      array.every((arrayItem) => arrayItem.parentId !== location.id)
    );

    page.setData({
      location: localChildren.map((child) => ({
        id: child.id,
        description: child.description,
      })),
    });
  };

  const getDates = async (locationId) => {
    const dateInventoryList = await hooks.assetinventory.fetchDatesInventory(
      session,
      locationId
    );

    let allDates = [];

    if (!dateInventoryList.errors) {
      allDates = dateInventoryList.map((date) =>
        moment(date.dateInventory, "YYYY-MM-DD").format("DD/MM/YYYY")
      );
    }
    page.setData({
      dateList: allDates,
    });
  };

  const getInventory = async (dateInventory) => {
    const { count, assetinventory } =
      await hooks.assetinventory.fetchAssetsInventory(
        session,
        page.data.pagination,
        page.values.locationInventory.value.id,
        dateInventory
      );
    page.setData({
      count,
      assetinventory,
    });
  };

  const rows = (assetinventory) => {
    return assetinventory.map((asset) => {
      let status = (
        <div style={{ color: "green" }}>
          <CheckCircle />
        </div>
      );

      if (!asset.isLocated) {
        status = (
          <div style={{ color: "red" }}>
            <Cancel />
          </div>
        );
      }

      return {
        rfid: asset.rfid,
        description: asset.description,
        status: status,
      };
    });
  };

  const columns = [
    {
      header: "RFID",
      align: "left",
    },
    {
      header: "Descrição",
      align: "left",
    },
    {
      header: "Status",
      align: "center",
    },
  ];

  const handlePageChange = async (_, newPage) => {
    page.setData({
      pagination: {
        ...page.data.pagination,
        page: newPage,
      },
    });
  };

  const handleChangeRowsPerPage = async (event) => {
    page.setData({
      pagination: {
        page: 0,
        rowsPerPage: event.target.value,
      },
    });
  };

  const getNoRowsMessage = () => {
    if (!page.values.locationInventory.value) return "Selecione um local";
    if (!page.data.dateList.length) return "Não há inventários para esse local";
    if (!page.values.dateInventory.value) return "Selecione uma data";
    return null;
  };

  return (
    <Page
      title="Inventário"
      requiresAuthentication
      showAppBar
      showSideBar
      breadcrumbs={[
        { description: "Home", link: "/" },
        { description: "Inventário", link: "/inventory" },
      ]}
    >
      {!page.data.location ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Datagrid
          tableAppBar={
            <div style={{ padding: 16, display: "flex", gap: 16 }}>
              <AutoComplete
                id="location"
                label="Local"
                fullWidth
                variant="standard"
                field={page.values.locationInventory}
                options={page.data.location ?? []}
                getOptionLabel={(option) => {
                  return option.description || "";
                }}
                onChange={(_, val) => {
                  page.setData({
                    assetinventory: [],
                  });
                  page.setValues({
                    locationInventory: {
                      value: val,
                    },
                    dateInventory: {
                      value: null,
                    },
                  });
                  getDates(val.id);
                }}
              />

              <AutoComplete
                id="dateList"
                label="Data"
                fullWidth
                variant="standard"
                field={page.values.dateInventory}
                options={page.data.dateList ?? []}
                getOptionLabel={(option) => {
                  return option || "";
                }}
                onChange={(_, val) => {
                  page.setValues({
                    dateInventory: {
                      value: val,
                    },
                  });
                  getInventory(val);
                }}
              />
            </div>
          }
          rows={rows(page.data?.assetinventory || []).map((row) => ({
            cells: Object.values(row),
          }))}
          noRowsMessage={getNoRowsMessage()}
          columns={columns}
          paginationProps={{
            ...page.data.pagination,
            count: page.data.count ? page.data.count : 0,
          }}
          handlePageChange={async (event, page) => {
            await handlePageChange(event, page);
          }}
          handleChangeRowsPerPage={async (event) => {
            await handleChangeRowsPerPage(event);
          }}
        />
      )}
    </Page>
  );
}
