import { Box, Divider, useTheme } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "../components/button";
import Grid from "../components/grid";
import Typography from "../components/typography";
import { useSession } from "../hooks/session";

export default function UnauthorizedPage() {
  const session = useSession();
  const navigate = useNavigate();
  const theme = useTheme();
  const [queryParams] = useSearchParams();
  const isTokenExpired = queryParams.get("isTokenExpired");
  const buttonLabel = isTokenExpired
    ? "fazer login novamente"
    : "voltar para home";
  return (
    <Grid
      container
      direction={"column"}
      textAlign={"center"}
      sx={{
        backgroundColor: theme.palette.grey[200],
        height: "100vh",
        gap: 2,
        justifyContent: "center",
      }}
    >
      <Grid item>
        <Typography
          component={"h1"}
          variant={"h1"}
          sx={{ fontWeight: "bolder" }}
        >
          401
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          component={"h2"}
          variant={"h4"}
          sx={{ fontWeight: "bolder" }}
        >
          Não autorizado
        </Typography>
      </Grid>
      {isTokenExpired && (
        <Grid item>
          <Typography component={"h2"} variant={"subtitle2"}>
            Parece que suas permissões foram alteradas. Faça o login novamente
            para atualizar.
          </Typography>
        </Grid>
      )}
      <Grid item>
        <Button onClick={() => navigate(isTokenExpired ? "/signin" : "/")}>
          {buttonLabel}
        </Button>
      </Grid>

      {!isTokenExpired && (
        <Grid item>
          <Button
            onClick={() => session.signOut()}
            variant="text"
            color="warning"
          >
            Entrar com outra conta
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
