import {
  AdminPanelSettings as AdminPanelSettingsIcon,
  Category as CategoryIcon,
  DomainRounded as DomainRoundedIcon,
  People as PeopleIcon,
  PinDrop as PinDropIcon,
} from "@mui/icons-material";
import { Grid as MuiGrid } from "@mui/material";
import Page from "../../components/page";
import { useSession } from "../../hooks/session";
import SettingsMenuItem from "./components/menu-item";

function SettingsPage() {
  const session = useSession();
  return (
    <Page
      title="Configurações"
      showAppBar
      showSideBar
      keyAccess="menuConfig"
      requiresAuthentication
      breadcrumbs={[
        { description: "Home", link: "/" },
        { description: "Configurações", link: "/settings" },
      ]}
    >
      <MuiGrid container spacing={2}>
        <SettingsMenuItem
          icon={<DomainRoundedIcon />}
          label={"Empresas"}
          subtitle={"Gestão das empresas cadastradas"}
          url={"/settings/companies"}
          hidden={
            !session.hasAccess({
              key: "getCompanies",
              accessIds: session.access,
            }) || session.account.user.scope === "Empresa"
          }
        />
        <SettingsMenuItem
          icon={<PeopleIcon />}
          label={"Usuários"}
          subtitle={"Gestão dos usuários cadastrados"}
          url={"/settings/users"}
          hidden={
            !session.hasAccess({ key: "getUsers", accessIds: session.access })
          }
        />
        <SettingsMenuItem
          icon={<AdminPanelSettingsIcon />}
          label={"Perfis"}
          subtitle={"Gestão dos perfis de acesso ao sistema"}
          url={"/settings/roles"}
          hidden={
            !session.hasAccess({ key: "getRoles", accessIds: session.access })
          }
        />
        <SettingsMenuItem
          icon={<PinDropIcon />}
          label={"Locais"}
          subtitle={"  Locais onde serão cadastrados os ativos da empresa"}
          url={"/settings/location"}
          hidden={
            !session.hasAccess({
              key: "getLocations",
              accessIds: session.access,
            })
          }
        />
        {session.account.selectedCompany.accountingExport === "genre" && (
          <>
            <SettingsMenuItem
              icon={<CategoryIcon />}
              label={"Gêneros"}
              subtitle={
                "Gestão das contas débito e crédito de cada gênero contábil"
              }
              url={"/settings/genres"}
            />
          </>
        )}
      </MuiGrid>
    </Page>
  );
}

export default SettingsPage;
