import {
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from "@mui/material";
import { SelectProps } from "./select.types";

function Select(props: SelectProps) {
  const muiSelectProps: MuiSelectProps = {
    autoWidth: false,
    fullWidth: false,
    defaultOpen: false,
    displayEmpty: false,
    variant: "outlined",
    multiple: false,
    native: false,
    value: "",
  };

  if (props.autoWidth) muiSelectProps.autoWidth = props.autoWidth;

  if (props.fullWidth) muiSelectProps.fullWidth = props.fullWidth;

  if (props.defaultOpen) muiSelectProps.defaultOpen = props.defaultOpen;

  if (props.displayEmpty) muiSelectProps.displayEmpty = props.displayEmpty;

  // if (props.variant) muiSelectProps.variant = props.variant;

  if (props.multiple) muiSelectProps.multiple = props.multiple;

  if (props.native) muiSelectProps.native = props.native;

  if (props.id) muiSelectProps.id = props.id;

  if (props.name) muiSelectProps.name = props.name;

  if (props.label) muiSelectProps.label = props.label;

  if (props.labelId) muiSelectProps.labelId = props.labelId;

  if (props.open) muiSelectProps.open = props.open;

  if (props.sx) muiSelectProps.sx = props.sx;

  if (props.field?.value)
    muiSelectProps.value = props.field.value || "Selecione";

  if (props.field?.required) muiSelectProps.required = props.field.required;

  const hasError =
    props.field?.error === true || props.field?.errorMessage !== "";
  if (hasError === true) muiSelectProps.error = props.field?.error;
  // if (props.field?.errorMessage !== "")
  //   muiSelectProps.helperText = props.field?.errorMessage;

  // if (props.value) muiSelectProps.value = props.value;

  if (props.defaultValue) muiSelectProps.defaultValue = props.defaultValue;

  if (props.onChange) muiSelectProps.onChange = props.onChange;

  return (
    <MuiFormControl fullWidth={muiSelectProps.fullWidth}>
      <MuiInputLabel id={muiSelectProps.labelId}>
        {muiSelectProps.label}
      </MuiInputLabel>
      <MuiSelect {...muiSelectProps}>{props.children}</MuiSelect>
    </MuiFormControl>
  );
}

export default Select;

// Exemplo de uso do componente:
{
  /* {page.data.roles !== undefined ? (
              <Select
                id="role"
                name="role"
                labelId="role-select"
                label="Perfil"
                fullWidth
                field={page.values.selectedRoleId}
                sx={{
                  border: "1px solid #dee2e6",
                  backgroundColor: "white",
                  borderRadius: "5px",
                }}
                // field={page.values.selectedRoleId}
                value={page.values.selectedRoleId.value}
                onChange={async (e) => {
                  page.setValues({
                    selectedRoleId: { value: e.target.value },
                  });
                  accessList();
                }}
              >
                {page.data.roles?.map((i) => {
                  return (
                    <MenuItem key={i.id} value={i.id}>
                      {i.name}
                    </MenuItem>
                  );
                })}
              </Select>
            ) : (
              <p>Carregando...</p>
            )} */
}
