import Page from "../../../components/page";

import { useTheme } from "@mui/material/styles";
import { useSession } from "../../../hooks/session";
import { usePageContext } from "./context";
import { useUser } from "../../../hooks/user";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Grid from "../../../components/grid";
import { Chip, Paper, Switch } from "@mui/material";
import Avatar from "../../../components/avatar";
import Typography from "../../../components/typography";
import {
  AccountCircle as AccountCircleIcon,
  Save as SaveIcon,
  VerifiedUser as VerifiedUserIcon,
  SyncProblem as SyncProblemIcon,
  Home as HomeIcon,
} from "@mui/icons-material";
import Button from "../../../components/button";
import Dialog from "../../../components/dialog";
import TextField from "../../../components/text-field";
import AutoComplete from "../../../components/auto-complete";
import { useRole } from "../../../hooks/role";
import { useLocation } from "../../../hooks/location";

function EditUserPage() {
  const session = useSession();
  const page = usePageContext();
  const hooks = {
    user: useUser(),
    role: useRole(),
    local: useLocation(),
    session: useSession(),
  };
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    if (!page.data.roles) await roleList();
    if (!page.data.locations) await locationList();
    if (!page.data.user) await getUserInfo();
  };

  const roleList = async () => {
    await hooks.role
      .fetchRoles(session)
      .then((roles) =>
        page.setData({
          roles: roles.map((i) => ({
            id: i.id,
            name: i.name,
          })),
        })
      )
      .catch((err) => page.setData({ error: true }));
  };

  const locationList = async () => {
    await hooks.local
      .fetchLocations(session)
      .then((locals) =>
        page.setData({
          locations: locals.map((i) => ({
            id: i.id,
            name: i.description,
          })),
        })
      )
      .catch((err) => page.setData({ error: true }));
  };

  const getUserInfo = async () => {
    await hooks.user
      .fetchSingleUser(session, id)
      .then((res) => {
        page.setData({
          user: res.user,
          oldUserData: {
            locations: res.user.locations,
            roles: res.user.roles,
          },
        });
        page.setValues({
          name: { value: res.user.name },
          email: { value: res.user.email },
          locations: { value: res.user.locations },
          roles: { value: res.user.roles },
        });
        return;
      })
      .catch((err) => page.setData({ error: true }));
  };

  const handleSave = async () => {
    page.setValues({ isLoading: { value: true } });
    let local = {
      insert: [],
      delete: [],
    };
    let role = {
      insert: [],
      delete: [],
    };

    local.insert = page.values.locations.value.filter((location) => {
      let isDifferent = page.data.oldUserData.locations.every((i) => {
        return i !== location;
      });

      if (isDifferent) return location;
    });

    local.delete = page.data.oldUserData.locations.filter((location) => {
      let isDifferent = page.values.locations.value.every((i) => {
        return i !== location;
      });

      if (isDifferent) return location;
    });

    role.insert = page.values.roles.value.filter((selectedRole) => {
      let isDifferent = page.data.oldUserData.roles.every((i) => {
        return i !== selectedRole;
      });

      if (isDifferent) return selectedRole;
    });

    role.delete = page.data.oldUserData.roles.filter((selectedRole) => {
      let isDifferent = page.values.roles.value.every((i) => {
        return i !== selectedRole;
      });

      if (isDifferent) return selectedRole;
    });

    await hooks.user
      .editUser(
        {
          id: page.data.user.id,
          name: page.values.name.value,
          email: page.values.email.value,
          active: page.data.user.active,
          editedRole: role,
          editedLocation: local,
        },
        session
      )
      .then((res) => {
        page.notify("success", "Usuário atualizado");
      })
      .catch((err) => page.notify("error", `Algo deu errado - ${err}`))
      .finally(async () => {
        await loadData().then(() =>
          page.setValues({ isLoading: { value: false } })
        );
      });
  };
  return (
    <Page
      title="Editar Usuário"
      context={page.context}
      showAppBar
      showSideBar
      requiresAuthentication
      keyAccess="putUser"
      breadcrumbs={[
        { description: "Home", link: "/" },
        { description: "Configurações", link: "/settings" },
        { description: "Usuários", link: "/settings/users" },
        {
          description: page.data.user && page.data.user.name,
          link: `/settings/users/edit/${id}`,
        },
      ]}
      toolbar={
        page.data.user && [
          <Dialog
            title={
              page.data.user.active ? "Desativar usuário" : "Ativar usuário"
            }
            open={page.values.openDialog.value}
            actions={
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  variant="text"
                  color="primary"
                  type="button"
                  onClick={() => {
                    page.setValues({ openDialog: { value: false } });
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  color={page.data.user.active ? "warning" : "success"}
                  type="button"
                  onClick={async () => {
                    page.setValues({ openDialog: { value: false } });
                    page.setData({
                      user: {
                        ...page.data.user,
                        active: !page.data.user.active,
                      },
                    });
                    await hooks.user
                      .editUser(
                        {
                          id,
                          active: !page.data.user.active,
                        },
                        session
                      )
                      .then((res) =>
                        page.notify(
                          "success",
                          `Usuário ${
                            page.data.user.active ? "desativado" : "ativado"
                          } com sucesso!`
                        )
                      )
                      .catch((err) =>
                        page.notify("error", `Algo deu errado - ${err}`)
                      )
                      .finally(async () => {
                        await loadData().then(() =>
                          page.setValues({ isLoading: { value: false } })
                        );
                      });
                  }}
                >
                  {page.data.user.active ? "Desativar" : "Ativar"}
                </Button>
              </div>
            }
          >
            <p>
              O usuário{" "}
              <span style={{ fontWeight: "bold" }}>{page.data.user.name}</span>{" "}
              será {page.data.user.active ? "desativado" : "ativado"}, deseja
              prosseguir?
            </p>
          </Dialog>,
        ]
      }
    >
      {page.data.error ? (
        <div
          style={{
            display: "flex",
            height: "60vh",
            alignItems: "center",
            justifyContent: "center",
            gap: 64,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "60vh",
              alignItems: "start",
              justifyContent: "center",
              width: "40vw",
              gap: 16,
            }}
          >
            <Typography component={"h1"} variant={"h4"}>
              Parece que algo deu errado
            </Typography>
            <Typography
              component={"h1"}
              variant={"h5"}
              sx={{
                color: theme.palette.text.secondary,
              }}
            >
              Tivemos algum problema enquanto tentávamos carregar seus dados.
              Tente carregar a página novamente ou volte para a tela inicial
            </Typography>
            <div
              style={{
                display: "flex",
                gap: 16,
              }}
            >
              <Button
                type="button"
                variant="outlined"
                onClick={async () => {
                  page.setData({ user: undefined });
                  await loadData();
                }}
              >
                Recarregar
              </Button>
              <Button
                type="button"
                startIcon={<HomeIcon />}
                onClick={() => navigate("/")}
              >
                Home
              </Button>
            </div>
          </div>
          <SyncProblemIcon sx={{ fontSize: 300 }} color="error" />
        </div>
      ) : !page.data.user || page.values.isLoading.value === true ? (
        <p>Carregando...</p>
      ) : (
        <Grid container columns={3} spacing={1}>
          <Grid item xs={1}>
            <Paper sx={{ p: [0, 5] }}>
              <Grid
                item
                container
                direction={"column"}
                spacing={2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Grid item>
                  <Avatar
                    size={120}
                    sx={{
                      bgcolor: theme.palette.primary.light,
                      fontSize: 64,
                    }}
                  >
                    {/* {page.data.user.name[0].toUpperCase()} */}
                  </Avatar>
                </Grid>
                <Grid item>
                  <Typography component={"h1"} variant={"h5"}>
                    {page.data.user.name}
                  </Typography>
                </Grid>
                <Grid item container direction={"column"} spacing={1}>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      component={"p"}
                      variant={"subtitle1"}
                      sx={{ color: theme.palette.text.secondary }}
                    >
                      Ativo:
                    </Typography>
                    <Switch
                      checked={page.data.user.active}
                      disabled={page.data.user.admin}
                      onChange={() => {
                        page.setValues({
                          openDialog: { value: true },
                        });
                      }}
                    ></Switch>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      component={"p"}
                      variant={"subtitle1"}
                      sx={{ color: theme.palette.text.secondary }}
                    >
                      Permissão:
                    </Typography>
                    <Chip
                      avatar={
                        page.data.user.admin ? (
                          <VerifiedUserIcon />
                        ) : (
                          <AccountCircleIcon />
                        )
                      }
                      label={page.data.user.admin ? "Admin" : "Usuário"}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <Button
                    variant="text"
                    type="button"
                    onClick={async () =>
                      await hooks.session
                        .sendForgotPasswordEmail({
                          email: page.data.user.email,
                        })
                        .then(() =>
                          page.notify(
                            "success",
                            "E-mail de redefinição de senha enviado"
                          )
                        )
                    }
                  >
                    Redefinir senha
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper sx={{ p: [0, 5] }}>
              <Grid
                item
                container
                direction={"column"}
                textAlign="right"
                spacing={4}
              >
                <Grid item size="xl" textAlign="left">
                  <Typography component={"h1"} variant={"h5"}>
                    Informações pessoais
                  </Typography>
                </Grid>
                <Grid item container columns={2} spacing={2}>
                  <Grid item xs={1}>
                    <TextField
                      fullWidth
                      label="Nome completo"
                      field={page.values.name}
                      onChange={(event) =>
                        page.setValues({
                          name: { value: event.target.value },
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <TextField
                      fullWidth
                      label="E-mail"
                      field={page.values.email}
                      onChange={(event) =>
                        page.setValues({
                          email: { value: event.target.value },
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={1}>
                    {page.data.roles && (
                      <AutoComplete
                        multiple
                        key={"role"}
                        id="role"
                        getOptionLabel={(option) => option.name}
                        field={page.values.roles}
                        onChange={(event, val) =>
                          page.setValues({
                            roles: {
                              value: val,
                            },
                          })
                        }
                        options={page.data.roles ?? []}
                        label="Perfil"
                      />
                    )}
                  </Grid>
                  <Grid item xs={1}>
                    {page.data.locations && (
                      <AutoComplete
                        multiple
                        key={"local"}
                        id="location"
                        getOptionLabel={(option) => option.name}
                        field={page.values.locations}
                        onChange={(event, val) =>
                          page.setValues({
                            locations: {
                              value: val,
                            },
                          })
                        }
                        options={page.data.locations ?? []}
                        label="Local"
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid item>
                  <Button startIcon={<SaveIcon />} onClick={handleSave}>
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Page>
  );
}

export default EditUserPage;
