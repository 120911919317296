import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import {
  Close as CloseIcon,
  DescriptionOutlined as DescriptionOutlinedIcon,
  FileDownload as FileDownloadIcon,
} from "@mui/icons-material";
import { parse } from "papaparse";
import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import LayoutFile from "../../../../assets/files/layout_importacao.pdf";
import ModelFile from "../../../../assets/files/modelo_importacao.csv";
import Csv from "../../../../assets/img/CSV.png";
import DropHere from "../../../../assets/img/drop-here.gif";
import Avatar from "../../../../components/avatar";
import Dialog from "../../../../components/dialog";
import { useAsset } from "../../../../hooks/asset";
import { useSession } from "../../../../hooks/session";
import { usePageContext } from "../context";

export function ImportFileModal({ isOpen }) {
  const page = usePageContext();
  const theme = useTheme();
  const [file, setFile] = useState();
  const [assets, setAssets] = useState();
  const [hasImportEnded, setImportEnded] = useState(false);
  const [importMessage, setImportMessage] = useState({
    message: "",
    color: theme.palette.success.light,
  });
  const hooks = { asset: useAsset() };
  const session = useSession();

  const handleImportCSV = (e) => {
    const readedAssets = [];
    setImportEnded(false);
    if (!e) {
      page.notify("warning", "Tipo de arquivo não suportado");
      return;
    }
    const file = e.target ? e.target.files[0] : e;
    setFile(file);

    parse(file, {
      header: true,
      // worker: true,
      skipEmptyLines: true,
      beforeFirstChunk: (result) => {
        return result
          .replace("Descrição", "description")
          .replace("Valor de aquisição", "acquireAmount")
          .replace("Data de aquisição", "invoiceDate")
          .replace("Documento Fiscal", "invoiceNumber")
          .replace("Patrimônio", "number")
          .replace("TAG RFID", "rfid")
          .replace("Observação", "observation")
          .replace("Gênero", "genre")
          .replace("Fornecedor", "provider")
          .replace("Conta Débito", "debitAccount")
          .replace("Conta Crédito", "creditAccount")
          .replace("Local atual", "location")
          .replace("Valor futuro de venda", "futureSellAmount")
          .replace("Valor justo de mercado", "remainingAmount")
          .replace("Vida útil/Anos", "yearsToDepreciate");
      },
      step: (result) => {
        readedAssets.push(result.data);
      },
      complete: async (result) => {
        if (readedAssets.length === 0)
          return page.notify("warning", "Arquivo vazio");

        try {
          const result = await hooks.asset.createManyAssets(
            session,
            readedAssets
          );

          if (result.errors) throw new Error(result.errors[0]?.message);

          setImportMessage({
            message: result?.message || "Ativos importados com sucesso!",
            color: theme.palette.success.main,
          });
        } catch (error) {
          setImportMessage({
            message: error.message,
            color: theme.palette.error.main,
          });
        } finally {
          setImportEnded(true);
          setAssets(readedAssets);
        }
      },
    });
  };

  const removeFile = () => setFile(undefined);

  return (
    <Dialog
      title="Importar ativos"
      open={isOpen}
      maxWidth={"md"}
      fullWidth
      xCloseButton={
        <Button
          variant="text"
          color="error"
          onClick={() => page.setData({ isModalOpen: false })}
        >
          <CloseIcon fontSize="small" />
        </Button>
      }
    >
      <Dropzone
        noClick
        maxFiles={1}
        accept={{
          file: [".csv"],
        }}
        onDrop={(acceptedFiles, rejectedFiles) => {
          if (rejectedFiles.length > 0)
            return page.notify("warning", "Tipo de arquivo não suportado");
          handleImportCSV(acceptedFiles[0]);
        }}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <Box
            border={`${isDragActive ? "3px dotted" : "1px solid"} ${
              theme.palette.primary.light
            }`}
            {...getRootProps()}
            padding={10}
            borderRadius={2}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gap={2}
          >
            <img
              src={isDragActive ? DropHere : Csv}
              style={{
                width: `${file ? 100 : 150}px`,
                padding: `${file ? 10 : 0}px`,
                // border: "1px solid green",
                // borderRadius: "999px",
                transition: ".5s",
              }}
            />

            {!file || isDragActive ? (
              <AwaitingFile
                isDragActive={isDragActive}
                handleImportCSV={handleImportCSV}
                getInputProps={getInputProps}
              />
            ) : (
              <FileImported
                removeFile={removeFile}
                file={file}
                assetsCount={assets ? assets.length : 0}
                isLoading={!hasImportEnded}
                importEndend={importMessage}
              />
            )}
          </Box>
        )}
      </Dropzone>
    </Dialog>
  );
}
const FileImported = ({
  file,
  assetsCount,
  removeFile,
  isLoading,
  importEndend,
}) => {
  const theme = useTheme();
  const page = usePageContext();

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      removeFile();
      window.location.reload();
      page.setData({ isModalOpen: false });
    }, 5000);
    return () => clearTimeout(timeOutId);
  }, [isLoading]);

  const fileSize = (file) => {
    let _size = file.size;
    const fSExt = new Array("Bytes", "KB", "MB", "GB");
    let i = 0;
    while (_size > 900) {
      _size /= 1024;
      i++;
    }
    const exactSize = Math.round(_size * 100) / 100 + " " + fSExt[i];
    return exactSize;
  };
  return (
    <>
      {!isLoading && (
        <Typography
          sx={{ marginX: "auto" }}
          variant={"h6"}
          color={importEndend.color}
        >
          {importEndend.message}
        </Typography>
      )}
      <Paper>
        <ListItem>
          <ListItemAvatar>
            <Avatar
              sx={{
                backgroundColor: theme.palette.primary.dark,
              }}
            >
              <DescriptionOutlinedIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={file.name}
            secondary={`${fileSize(file)} • ${assetsCount} Ativos`}
          />
          <Tooltip title="Remover arquivo">
            <IconButton onClick={removeFile} sx={{ ml: 2 }}>
              <CloseIcon fontSize="small" color="error" />
            </IconButton>
          </Tooltip>
        </ListItem>
        {isLoading && <LinearProgress />}
      </Paper>
    </>
  );
};

const AwaitingFile = ({ isDragActive, handleImportCSV, getInputProps }) => {
  return (
    <>
      {!isDragActive ? (
        <Typography variant="subtitle1" width={200} textAlign="center">
          Arraste e solte o arquivo de importação ou{" "}
          <Button variant={"text"} component={"label"}>
            Buscar
            <input
              {...getInputProps()}
              type="file"
              accept=".csv"
              hidden
              onChange={handleImportCSV}
            />
          </Button>
        </Typography>
      ) : (
        <Typography variant={"subtitle2"}>
          Solte o arquivo aqui para importar os ativos!
        </Typography>
      )}
      {!isDragActive && (
        <>
          <Button
            variant="outlined"
            LinkComponent={"a"}
            href={ModelFile}
            download={"modelo_importacao.csv"}
            startIcon={<FileDownloadIcon />}
          >
            Baixar modelo
          </Button>
          <Button
            variant="outlined"
            LinkComponent={"a"}
            href={LayoutFile}
            download={"layout_importacao.pdf"}
            startIcon={<FileDownloadIcon />}
          >
            Baixar layout
          </Button>
        </>
      )}
    </>
  );
};
