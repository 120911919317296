import {
  Add as AddIcon,
  Check as CheckIcon,
  Clear as ClearIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import Button from "../../../../components/button/button";
import Dialog from "../../../../components/dialog";
import Grid from "../../../../components/grid/grid";
import List, { ListItem, ListItemText } from "../../../../components/list";
import Page from "../../../../components/page";
import TextField from "../../../../components/text-field/text-field";
import Typography from "../../../../components/typography";
import { useLocation } from "../../../../hooks/location";
import { useSession } from "../../../../hooks/session";
import { usePageContext } from "./context";

function Location({ location, level }) {
  const session = useSession();
  const theme = useTheme();
  const page = usePageContext();
  const hooks = {
    location: useLocation(),
  };
  const childrens = page.data.locations
    ?.filter((c) => c.parentId === location.id)
    .map((c) => <Location key={c.id} location={c} level={level + 1} />);

  const marginLeft = level * 4;

  const [temp, setTemp] = useState("");

  const editItem = async (item) => {
    const location = page.data.locations.find((i) => i.id === item.id);
    location.status = "edited";
    location.view = "editing";
    setTemp(location.description);
    page.setData({
      locations: [...page.data.locations],
    });
  };

  const saveEdit = async (item) => {
    const location = page.data.locations.find((i) => i.id === item.id);

    const locationExist = page.data.locations.find(
      (i) => i.description === temp
    );
    if (locationExist) {
      cancelEdit(location);
      return page.notify("error", "Local já existe");
    }

    location.description = temp;
    location.view = "default";

    page.setData({
      locations: [...page.data.locations],
    });

    if (location.status === "new") {
      await hooks.location.addItem(location, session);
    } else {
      await hooks.location.saveItem(location, session);
    }
    page.notify("success", "Salvo com sucesso");
  };

  const cancelEdit = async (item) => {
    const location = page.data.locations.find((i) => i.id === item.id);
    if (location.status === "new") {
      const index = page.data.locations.findIndex((i) => i.id === item.id);
      page.data.locations.splice(index, 1);
    }
    location.view = "default";
    setTemp(location.description);
    page.setData({
      locations: [...page.data.locations],
    });
  };

  const addSubitem = async (location) => {
    page.data.locations.push({
      id: uuid(),
      parentId: location.id,
      description: "",
      status: "new",
      view: "editing",
    });
    setTemp("");
    page.setData({
      locations: [...page.data.locations],
    });
  };

  return (
    <>
      <ListItem
        id="item"
        key={location.id}
        sx={{
          padding: "0 15px",
          margin: "10px 0",
          border: "1px solid #dee2e6",
          backgroundColor: "white",
          borderRadius: "5px",
          maxWidth: theme.spacing(100 - marginLeft),
          width: "100vw",
          marginLeft: theme.spacing(marginLeft),
        }}
      >
        {location.view === "editing" && (
          <>
            <TextField
              size="small"
              id="location"
              name="location"
              field={{ value: temp }}
              onChange={(e) => {
                setTemp(e.target.value);
              }}
            />
            <Grid container direction={"row-reverse"} spacing={2} xs={2}>
              <Grid item>
                <IconButton
                  edge="end"
                  onClick={() => {
                    cancelEdit(location);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  edge="end"
                  onClick={() => {
                    saveEdit(location);
                  }}
                >
                  <CheckIcon />
                </IconButton>
              </Grid>
            </Grid>
          </>
        )}
        {location.view === "default" && (
          <>
            <ListItemText>{location.description}</ListItemText>
            <Grid container direction={"row-reverse"} spacing={1} xs={2}>
              <Grid item>
                <ListActionsButton
                  accessIds={"deleteLocation"}
                  Icon={ClearIcon}
                  onClick={() =>
                    page.setData({ dialog: { isOpen: true, location } })
                  }
                />
              </Grid>
              <Grid item>
                <ListActionsButton
                  accessIds={"putLocation"}
                  Icon={EditIcon}
                  onClick={() => editItem(location)}
                />
              </Grid>
              <Grid item>
                <ListActionsButton
                  accessIds={"putLocation"}
                  Icon={AddIcon}
                  onClick={async () => {
                    await addSubitem(location);
                  }}
                />
              </Grid>
            </Grid>
          </>
        )}
      </ListItem>
      {childrens}
    </>
  );
}

function ListActionsButton({ Icon, accessIds, onClick }) {
  const session = useSession();
  return (
    <IconButton
      sx={{ justifyContent: "space-between", px: 1, pb: 1 }}
      edge="end"
      disabled={
        !session.hasAccess({ key: accessIds, accessIds: session.access })
      }
      onClick={onClick}
    >
      <Icon />
    </IconButton>
  );
}

function LocationListPage() {
  const session = useSession();
  const page = usePageContext();
  const hooks = {
    location: useLocation(),
  };

  useEffect(() => {
    if (!page.data.locations) locationList();
  }, []);

  const locationList = async () => {
    const locations = await hooks.location.fetchLocations(session);
    page.setData({
      locations: locations.map((i) => ({
        id: i.id,
        parentId: i.parentId,
        description: i.description,
        status: i.status,
        view: i.view,
      })),
    });
  };

  const newItem = async () => {
    page.data.locations.push({
      id: uuid(),
      parentId: null,
      description: "",
      status: "new",
      view: "editing",
    });
    page.setData({
      locations: [...page.data.locations],
    });
  };

  const deleteItem = async (item) => {
    const location = page.data.locations.find((i) => i.id === item.id);
    location.status = "deleted";
    const res = await hooks.location.deleteItem(location, session);
    if (res.success) {
      const index = page.data.locations.findIndex((i) => i.id === item.id);
      page.data.locations.splice(index, 1);
      page.notify("success", "Local excluído com sucesso");
    } else {
      page.notify("error", "Não foi possível excluir o local");
    }

    page.setData({
      locations: [...page.data.locations],
    });
  };

  return (
    <Page
      context={page.context}
      title="Locais"
      breadcrumbs={[
        { description: "Home", link: "/" },
        { description: "Configurações", link: "/settings" },
        { description: "Locais", link: "/settings/location" },
      ]}
      toolbar={[
        <Button
          type="button"
          variant="contained"
          disabled={
            !session.hasAccess({
              key: "postLocation",
              accessIds: session.access,
            })
          }
          onClick={async () => {
            await newItem();
          }}
        >
          Novo
        </Button>,
        <Dialog
          disableEscapeKeyDown={false}
          open={page.data.dialog.isOpen}
          title="Confirmar exclusão?"
          maxWidth="sm"
          fullWidth={true}
          actions={
            <Grid
              container
              sx={{ justifyContent: "space-between", px: 1, pb: 1 }}
            >
              <Grid item>
                <Button
                  type="button"
                  variant="text"
                  color="warning"
                  onClick={() => {
                    page.setData({
                      dialog: { isOpen: false },
                    });
                  }}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="button"
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={async () => {
                    await deleteItem(page.data.dialog.location);
                    page.setData({
                      dialog: { isOpen: false, location: null },
                    });
                  }}
                >
                  Excluir
                </Button>
              </Grid>
            </Grid>
          }
        >
          <Grid container direction={"column"} spacing={3}>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12}>
                <Typography>Não é possível desfazer essa ação.</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Dialog>,
      ]}
      showAppBar
      showSideBar
      requiresAuthentication
      keyAccess="getLocations"
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography component={"p"} variant={"body1"}>
            Gerencie os locais e subníveis onde seus ativos estão armazenados.
          </Typography>
        </Grid>
        <Grid item>
          <List
            sx={{
              marginTop: "10px",
            }}
          >
            {page.data.locations
              ?.filter((c) => {
                return !c.parentId;
              })
              .sort((a, b) => {
                return a.description.toUpperCase() > b.description.toUpperCase()
                  ? 1
                  : b.description.toUpperCase() > a.description.toUpperCase()
                  ? -1
                  : 0;
              })
              .map((l) => {
                return <Location key={l.id} location={l} level={0} />;
              })}
          </List>
        </Grid>
      </Grid>
    </Page>
  );
}

export default LocationListPage;
