import { PageContextProvider } from "./context";
import Page from "./genres-page";

const Component = () => {
  return (
    <PageContextProvider>
      <Page />
    </PageContextProvider>
  );
};

export default Component;
