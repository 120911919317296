import { createPageContext } from "../../../../components/page";

export interface Location {
  id: string;
  parentId?: string;
  status: string;
  view: string;
  description: string;
}

export interface Data {
  locations?: Array<Location>;
  dialog?: {
    isOpen: boolean;
    location?: Location;
  };
}

export interface Values {}

const { PageContextProvider, usePageContext } = createPageContext<Data, Values>(
  { dialog: { isOpen: false } },
  {}
);

export { PageContextProvider, usePageContext };
