import { List as MuiList, ListProps as MuiListProps } from "@mui/material";
import { SxProps } from "@mui/material/styles";

export interface ListProps {
  id?: string;
  sx?: SxProps;
  children: React.ReactNode;
}

function List(props: ListProps): JSX.Element {
  const muiListProps: MuiListProps = {};

  if (props.sx)
    muiListProps.sx = {
      ...props.sx,
    };

  return <MuiList {...muiListProps}>{props.children}</MuiList>;
}

export default List;
